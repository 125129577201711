// base elements
.tabs {

  a {
    background-color: $black;
    color: $yellow;
    font-weight: 300;

    &.active,
    &:hover,
    &:focus {
      background-color: $yellow;
      color: $black;
    }
  }
}

textarea,
input,
button,
a {
  outline: none;
}

.l-main,
.l-content-2 {
  .node,
  .block {

    ul {
      list-style: disc;
      padding-left: 1em;
      margin-left: 0;
      margin-bottom: 2em;
      //overflow: hidden;

      li {
        margin-bottom: 0.1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    ol {
      list-style: decimal;
      padding-left: 1em;
      margin-left: 0;
      margin-bottom: 2em;

      li {
        margin-bottom: 0.1em;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.menu {
  list-style: none;
  list-style-image: none;
  .collapsed,
  .leaf {
    list-style: none;
    list-style-image: none;
  }
}

strong {
  font-weight: 700;
}

u {
  text-decoration: line-through;
}

em {
  font-style: italic;
}

sub {
  vertical-align: sub;
  font-size: smaller;
}

sup {
  vertical-align: super;
  font-size: smaller;
}

@mixin links($color,$hover,$decoration, $weight) {

  a {
    &:link, &:visited, &:active {
      text-decoration: none;
      color: $color;
      font-weight: $weight;
    }

    &:hover, &:focus {
      color: $hover;
      text-decoration: $decoration;
    }
  }
}