// SCSS variables are information about icon's compiled state, stored under its original file name
//
// .icon-home {
//   width: $icon-home-width;
// }
//
// The large array-like variables contain all information about a single icon
// $icon-home: x y offset_x offset_y width height total_width total_height image_path;
//
// At the bottom of this section, we provide information about the spritesheet itself
// $spritesheet: width height image $spritesheet-sprites;
$sprite-arrow-name: 'sprite_arrow';
$sprite-arrow-x: 1606px;
$sprite-arrow-y: 1082px;
$sprite-arrow-offset-x: -1606px;
$sprite-arrow-offset-y: -1082px;
$sprite-arrow-width: 18px;
$sprite-arrow-height: 12px;
$sprite-arrow-total-width: 1920px;
$sprite-arrow-total-height: 1411px;
$sprite-arrow-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-arrow: (1606px, 1082px, -1606px, -1082px, 18px, 12px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_arrow', );
$sprite-big-shape-name: 'sprite_big-shape';
$sprite-big-shape-x: 0px;
$sprite-big-shape-y: 0px;
$sprite-big-shape-offset-x: 0px;
$sprite-big-shape-offset-y: 0px;
$sprite-big-shape-width: 1920px;
$sprite-big-shape-height: 1080px;
$sprite-big-shape-total-width: 1920px;
$sprite-big-shape-total-height: 1411px;
$sprite-big-shape-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-big-shape: (0px, 0px, 0px, 0px, 1920px, 1080px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_big-shape', );
$sprite-box-rectangles-white-name: 'sprite_box-rectangles-white';
$sprite-box-rectangles-white-x: 310px;
$sprite-box-rectangles-white-y: 1244px;
$sprite-box-rectangles-white-offset-x: -310px;
$sprite-box-rectangles-white-offset-y: -1244px;
$sprite-box-rectangles-white-width: 36px;
$sprite-box-rectangles-white-height: 36px;
$sprite-box-rectangles-white-total-width: 1920px;
$sprite-box-rectangles-white-total-height: 1411px;
$sprite-box-rectangles-white-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-box-rectangles-white: (310px, 1244px, -310px, -1244px, 36px, 36px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_box-rectangles-white', );
$sprite-box-rectangles-yellow-name: 'sprite_box-rectangles-yellow';
$sprite-box-rectangles-yellow-x: 270px;
$sprite-box-rectangles-yellow-y: 1244px;
$sprite-box-rectangles-yellow-offset-x: -270px;
$sprite-box-rectangles-yellow-offset-y: -1244px;
$sprite-box-rectangles-yellow-width: 38px;
$sprite-box-rectangles-yellow-height: 38px;
$sprite-box-rectangles-yellow-total-width: 1920px;
$sprite-box-rectangles-yellow-total-height: 1411px;
$sprite-box-rectangles-yellow-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-box-rectangles-yellow: (270px, 1244px, -270px, -1244px, 38px, 38px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_box-rectangles-yellow', );
$sprite-check-icon-name: 'sprite_check-icon';
$sprite-check-icon-x: 1296px;
$sprite-check-icon-y: 1106px;
$sprite-check-icon-offset-x: -1296px;
$sprite-check-icon-offset-y: -1106px;
$sprite-check-icon-width: 16px;
$sprite-check-icon-height: 14px;
$sprite-check-icon-total-width: 1920px;
$sprite-check-icon-total-height: 1411px;
$sprite-check-icon-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-check-icon: (1296px, 1106px, -1296px, -1106px, 16px, 14px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_check-icon', );
$sprite-close-modal-name: 'sprite_close-modal';
$sprite-close-modal-x: 226px;
$sprite-close-modal-y: 1244px;
$sprite-close-modal-offset-x: -226px;
$sprite-close-modal-offset-y: -1244px;
$sprite-close-modal-width: 42px;
$sprite-close-modal-height: 40px;
$sprite-close-modal-total-width: 1920px;
$sprite-close-modal-total-height: 1411px;
$sprite-close-modal-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-close-modal: (226px, 1244px, -226px, -1244px, 42px, 40px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_close-modal', );
$sprite-dribble-name: 'sprite_dribble';
$sprite-dribble-x: 1234px;
$sprite-dribble-y: 1122px;
$sprite-dribble-offset-x: -1234px;
$sprite-dribble-offset-y: -1122px;
$sprite-dribble-width: 32px;
$sprite-dribble-height: 32px;
$sprite-dribble-total-width: 1920px;
$sprite-dribble-total-height: 1411px;
$sprite-dribble-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-dribble: (1234px, 1122px, -1234px, -1122px, 32px, 32px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_dribble', );
$sprite-e-sepia-cp-32-name: 'sprite_e-sepia-cp-32';
$sprite-e-sepia-cp-32-x: 1106px;
$sprite-e-sepia-cp-32-y: 1122px;
$sprite-e-sepia-cp-32-offset-x: -1106px;
$sprite-e-sepia-cp-32-offset-y: -1122px;
$sprite-e-sepia-cp-32-width: 92px;
$sprite-e-sepia-cp-32-height: 33px;
$sprite-e-sepia-cp-32-total-width: 1920px;
$sprite-e-sepia-cp-32-total-height: 1411px;
$sprite-e-sepia-cp-32-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-e-sepia-cp-32: (1106px, 1122px, -1106px, -1122px, 92px, 33px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_e-sepia-cp-32', );
$sprite-facebook-name: 'sprite_facebook';
$sprite-facebook-x: 1268px;
$sprite-facebook-y: 1122px;
$sprite-facebook-offset-x: -1268px;
$sprite-facebook-offset-y: -1122px;
$sprite-facebook-width: 32px;
$sprite-facebook-height: 32px;
$sprite-facebook-total-width: 1920px;
$sprite-facebook-total-height: 1411px;
$sprite-facebook-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-facebook: (1268px, 1122px, -1268px, -1122px, 32px, 32px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_facebook', );
$sprite-hov-name: 'sprite_hov';
$sprite-hov-x: 1106px;
$sprite-hov-y: 1082px;
$sprite-hov-offset-x: -1106px;
$sprite-hov-offset-y: -1082px;
$sprite-hov-width: 128px;
$sprite-hov-height: 38px;
$sprite-hov-total-width: 1920px;
$sprite-hov-total-height: 1411px;
$sprite-hov-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-hov: (1106px, 1082px, -1106px, -1082px, 128px, 38px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_hov', );
$sprite-linkedin-icon-name: 'sprite_linkedin-icon';
$sprite-linkedin-icon-x: 1335px;
$sprite-linkedin-icon-y: 1122px;
$sprite-linkedin-icon-offset-x: -1335px;
$sprite-linkedin-icon-offset-y: -1122px;
$sprite-linkedin-icon-width: 19px;
$sprite-linkedin-icon-height: 19px;
$sprite-linkedin-icon-total-width: 1920px;
$sprite-linkedin-icon-total-height: 1411px;
$sprite-linkedin-icon-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-linkedin-icon: (1335px, 1122px, -1335px, -1122px, 19px, 19px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_linkedin-icon', );
$sprite-linkedin-name: 'sprite_linkedin';
$sprite-linkedin-x: 1200px;
$sprite-linkedin-y: 1122px;
$sprite-linkedin-offset-x: -1200px;
$sprite-linkedin-offset-y: -1122px;
$sprite-linkedin-width: 32px;
$sprite-linkedin-height: 32px;
$sprite-linkedin-total-width: 1920px;
$sprite-linkedin-total-height: 1411px;
$sprite-linkedin-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-linkedin: (1200px, 1122px, -1200px, -1122px, 32px, 32px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_linkedin', );
$sprite-next-arrow-small-name: 'sprite_next-arrow-small';
$sprite-next-arrow-small-x: 1266px;
$sprite-next-arrow-small-y: 1106px;
$sprite-next-arrow-small-offset-x: -1266px;
$sprite-next-arrow-small-offset-y: -1106px;
$sprite-next-arrow-small-width: 28px;
$sprite-next-arrow-small-height: 14px;
$sprite-next-arrow-small-total-width: 1920px;
$sprite-next-arrow-small-total-height: 1411px;
$sprite-next-arrow-small-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-next-arrow-small: (1266px, 1106px, -1266px, -1106px, 28px, 14px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_next-arrow-small', );
$sprite-next-arrow-name: 'sprite_next-arrow';
$sprite-next-arrow-x: 1378px;
$sprite-next-arrow-y: 1082px;
$sprite-next-arrow-offset-x: -1378px;
$sprite-next-arrow-offset-y: -1082px;
$sprite-next-arrow-width: 40px;
$sprite-next-arrow-height: 20px;
$sprite-next-arrow-total-width: 1920px;
$sprite-next-arrow-total-height: 1411px;
$sprite-next-arrow-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-next-arrow: (1378px, 1082px, -1378px, -1082px, 40px, 20px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_next-arrow', );
$sprite-next-name: 'sprite_next';
$sprite-next-x: 1626px;
$sprite-next-y: 1082px;
$sprite-next-offset-x: -1626px;
$sprite-next-offset-y: -1082px;
$sprite-next-width: 19px;
$sprite-next-height: 6px;
$sprite-next-total-width: 1920px;
$sprite-next-total-height: 1411px;
$sprite-next-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-next: (1626px, 1082px, -1626px, -1082px, 19px, 6px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_next', );
$sprite-prev-arrow-small-name: 'sprite_prev-arrow-small';
$sprite-prev-arrow-small-x: 1236px;
$sprite-prev-arrow-small-y: 1106px;
$sprite-prev-arrow-small-offset-x: -1236px;
$sprite-prev-arrow-small-offset-y: -1106px;
$sprite-prev-arrow-small-width: 28px;
$sprite-prev-arrow-small-height: 14px;
$sprite-prev-arrow-small-total-width: 1920px;
$sprite-prev-arrow-small-total-height: 1411px;
$sprite-prev-arrow-small-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-prev-arrow-small: (1236px, 1106px, -1236px, -1106px, 28px, 14px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_prev-arrow-small', );
$sprite-prev-arrow-name: 'sprite_prev-arrow';
$sprite-prev-arrow-x: 1420px;
$sprite-prev-arrow-y: 1082px;
$sprite-prev-arrow-offset-x: -1420px;
$sprite-prev-arrow-offset-y: -1082px;
$sprite-prev-arrow-width: 40px;
$sprite-prev-arrow-height: 20px;
$sprite-prev-arrow-total-width: 1920px;
$sprite-prev-arrow-total-height: 1411px;
$sprite-prev-arrow-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-prev-arrow: (1420px, 1082px, -1420px, -1082px, 40px, 20px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_prev-arrow', );
$sprite-quote-small-name: 'sprite_quote-small';
$sprite-quote-small-x: 1314px;
$sprite-quote-small-y: 1106px;
$sprite-quote-small-offset-x: -1314px;
$sprite-quote-small-offset-y: -1106px;
$sprite-quote-small-width: 17px;
$sprite-quote-small-height: 13px;
$sprite-quote-small-total-width: 1920px;
$sprite-quote-small-total-height: 1411px;
$sprite-quote-small-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-quote-small: (1314px, 1106px, -1314px, -1106px, 17px, 13px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_quote-small', );
$sprite-quote-name: 'sprite_quote';
$sprite-quote-x: 1462px;
$sprite-quote-y: 1082px;
$sprite-quote-offset-x: -1462px;
$sprite-quote-offset-y: -1082px;
$sprite-quote-width: 25px;
$sprite-quote-height: 20px;
$sprite-quote-total-width: 1920px;
$sprite-quote-total-height: 1411px;
$sprite-quote-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-quote: (1462px, 1082px, -1462px, -1082px, 25px, 20px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_quote', );
$sprite-read-more-black-name: 'sprite_read-more-black';
$sprite-read-more-black-x: 1567px;
$sprite-read-more-black-y: 1082px;
$sprite-read-more-black-offset-x: -1567px;
$sprite-read-more-black-offset-y: -1082px;
$sprite-read-more-black-width: 37px;
$sprite-read-more-black-height: 12px;
$sprite-read-more-black-total-width: 1920px;
$sprite-read-more-black-total-height: 1411px;
$sprite-read-more-black-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-read-more-black: (1567px, 1082px, -1567px, -1082px, 37px, 12px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_read-more-black', );
$sprite-read-more-white-name: 'sprite_read-more-white';
$sprite-read-more-white-x: 1528px;
$sprite-read-more-white-y: 1082px;
$sprite-read-more-white-offset-x: -1528px;
$sprite-read-more-white-offset-y: -1082px;
$sprite-read-more-white-width: 37px;
$sprite-read-more-white-height: 12px;
$sprite-read-more-white-total-width: 1920px;
$sprite-read-more-white-total-height: 1411px;
$sprite-read-more-white-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-read-more-white: (1528px, 1082px, -1528px, -1082px, 37px, 12px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_read-more-white', );
$sprite-read-more-yellow-name: 'sprite_read-more-yellow';
$sprite-read-more-yellow-x: 1489px;
$sprite-read-more-yellow-y: 1082px;
$sprite-read-more-yellow-offset-x: -1489px;
$sprite-read-more-yellow-offset-y: -1082px;
$sprite-read-more-yellow-width: 37px;
$sprite-read-more-yellow-height: 12px;
$sprite-read-more-yellow-total-width: 1920px;
$sprite-read-more-yellow-total-height: 1411px;
$sprite-read-more-yellow-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-read-more-yellow: (1489px, 1082px, -1489px, -1082px, 37px, 12px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_read-more-yellow', );
$sprite-theuxprodigy-name: 'sprite_theuxprodigy';
$sprite-theuxprodigy-x: 1236px;
$sprite-theuxprodigy-y: 1082px;
$sprite-theuxprodigy-offset-x: -1236px;
$sprite-theuxprodigy-offset-y: -1082px;
$sprite-theuxprodigy-width: 140px;
$sprite-theuxprodigy-height: 22px;
$sprite-theuxprodigy-total-width: 1920px;
$sprite-theuxprodigy-total-height: 1411px;
$sprite-theuxprodigy-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-theuxprodigy: (1236px, 1082px, -1236px, -1082px, 140px, 22px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_theuxprodigy', );
$sprite-tickbox-mobile-name: 'sprite_tickbox-mobile';
$sprite-tickbox-mobile-x: 226px;
$sprite-tickbox-mobile-y: 1082px;
$sprite-tickbox-mobile-offset-x: -226px;
$sprite-tickbox-mobile-offset-y: -1082px;
$sprite-tickbox-mobile-width: 160px;
$sprite-tickbox-mobile-height: 160px;
$sprite-tickbox-mobile-total-width: 1920px;
$sprite-tickbox-mobile-total-height: 1411px;
$sprite-tickbox-mobile-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-tickbox-mobile: (226px, 1082px, -226px, -1082px, 160px, 160px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_tickbox-mobile', );
$sprite-tickbox-name: 'sprite_tickbox';
$sprite-tickbox-x: 0px;
$sprite-tickbox-y: 1082px;
$sprite-tickbox-offset-x: 0px;
$sprite-tickbox-offset-y: -1082px;
$sprite-tickbox-width: 224px;
$sprite-tickbox-height: 224px;
$sprite-tickbox-total-width: 1920px;
$sprite-tickbox-total-height: 1411px;
$sprite-tickbox-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-tickbox: (0px, 1082px, 0px, -1082px, 224px, 224px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_tickbox', );
$sprite-twitter-name: 'sprite_twitter';
$sprite-twitter-x: 1302px;
$sprite-twitter-y: 1122px;
$sprite-twitter-offset-x: -1302px;
$sprite-twitter-offset-y: -1122px;
$sprite-twitter-width: 31px;
$sprite-twitter-height: 32px;
$sprite-twitter-total-width: 1920px;
$sprite-twitter-total-height: 1411px;
$sprite-twitter-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-twitter: (1302px, 1122px, -1302px, -1122px, 31px, 32px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_twitter', );
$sprite-value-1-mobile-name: 'sprite_value-1-mobile';
$sprite-value-1-mobile-x: 692px;
$sprite-value-1-mobile-y: 1082px;
$sprite-value-1-mobile-offset-x: -692px;
$sprite-value-1-mobile-offset-y: -1082px;
$sprite-value-1-mobile-width: 109px;
$sprite-value-1-mobile-height: 108px;
$sprite-value-1-mobile-total-width: 1920px;
$sprite-value-1-mobile-total-height: 1411px;
$sprite-value-1-mobile-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-value-1-mobile: (692px, 1082px, -692px, -1082px, 109px, 108px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_value-1-mobile', );
$sprite-value-2-mobile-name: 'sprite_value-2-mobile';
$sprite-value-2-mobile-x: 1019px;
$sprite-value-2-mobile-y: 1082px;
$sprite-value-2-mobile-offset-x: -1019px;
$sprite-value-2-mobile-offset-y: -1082px;
$sprite-value-2-mobile-width: 85px;
$sprite-value-2-mobile-height: 82px;
$sprite-value-2-mobile-total-width: 1920px;
$sprite-value-2-mobile-total-height: 1411px;
$sprite-value-2-mobile-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-value-2-mobile: (1019px, 1082px, -1019px, -1082px, 85px, 82px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_value-2-mobile', );
$sprite-value-3-mobile-name: 'sprite_value-3-mobile';
$sprite-value-3-mobile-x: 910px;
$sprite-value-3-mobile-y: 1082px;
$sprite-value-3-mobile-offset-x: -910px;
$sprite-value-3-mobile-offset-y: -1082px;
$sprite-value-3-mobile-width: 107px;
$sprite-value-3-mobile-height: 95px;
$sprite-value-3-mobile-total-width: 1920px;
$sprite-value-3-mobile-total-height: 1411px;
$sprite-value-3-mobile-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-value-3-mobile: (910px, 1082px, -910px, -1082px, 107px, 95px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_value-3-mobile', );
$sprite-value-4-mobile-name: 'sprite_value-4-mobile';
$sprite-value-4-mobile-x: 0px;
$sprite-value-4-mobile-y: 1308px;
$sprite-value-4-mobile-offset-x: 0px;
$sprite-value-4-mobile-offset-y: -1308px;
$sprite-value-4-mobile-width: 103px;
$sprite-value-4-mobile-height: 103px;
$sprite-value-4-mobile-total-width: 1920px;
$sprite-value-4-mobile-total-height: 1411px;
$sprite-value-4-mobile-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-value-4-mobile: (0px, 1308px, 0px, -1308px, 103px, 103px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_value-4-mobile', );
$sprite-value-icon-1-name: 'sprite_value-icon-1';
$sprite-value-icon-1-x: 803px;
$sprite-value-icon-1-y: 1082px;
$sprite-value-icon-1-offset-x: -803px;
$sprite-value-icon-1-offset-y: -1082px;
$sprite-value-icon-1-width: 105px;
$sprite-value-icon-1-height: 102px;
$sprite-value-icon-1-total-width: 1920px;
$sprite-value-icon-1-total-height: 1411px;
$sprite-value-icon-1-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-value-icon-1: (803px, 1082px, -803px, -1082px, 105px, 102px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_value-icon-1', );
$sprite-value-icon-2-name: 'sprite_value-icon-2';
$sprite-value-icon-2-x: 540px;
$sprite-value-icon-2-y: 1082px;
$sprite-value-icon-2-offset-x: -540px;
$sprite-value-icon-2-offset-y: -1082px;
$sprite-value-icon-2-width: 150px;
$sprite-value-icon-2-height: 133px;
$sprite-value-icon-2-total-width: 1920px;
$sprite-value-icon-2-total-height: 1411px;
$sprite-value-icon-2-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-value-icon-2: (540px, 1082px, -540px, -1082px, 150px, 133px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_value-icon-2', );
$sprite-value-icon-3-name: 'sprite_value-icon-3';
$sprite-value-icon-3-x: 388px;
$sprite-value-icon-3-y: 1082px;
$sprite-value-icon-3-offset-x: -388px;
$sprite-value-icon-3-offset-y: -1082px;
$sprite-value-icon-3-width: 150px;
$sprite-value-icon-3-height: 150px;
$sprite-value-icon-3-total-width: 1920px;
$sprite-value-icon-3-total-height: 1411px;
$sprite-value-icon-3-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-value-icon-3: (388px, 1082px, -388px, -1082px, 150px, 150px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_value-icon-3', );
$sprite-visit-website-name: 'sprite_visit-website';
$sprite-visit-website-x: 1356px;
$sprite-visit-website-y: 1122px;
$sprite-visit-website-offset-x: -1356px;
$sprite-visit-website-offset-y: -1122px;
$sprite-visit-website-width: 18px;
$sprite-visit-website-height: 15px;
$sprite-visit-website-total-width: 1920px;
$sprite-visit-website-total-height: 1411px;
$sprite-visit-website-image: '../images/sprites/build/sprites.png?v=1679647820512';
$sprite-visit-website: (1356px, 1122px, -1356px, -1122px, 18px, 15px, 1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', 'sprite_visit-website', );
$spritesheet-width: 1920px;
$spritesheet-height: 1411px;
$spritesheet-image: '../images/sprites/build/sprites.png?v=1679647820512';
$spritesheet-sprites: ($sprite-arrow, $sprite-big-shape, $sprite-box-rectangles-white, $sprite-box-rectangles-yellow, $sprite-check-icon, $sprite-close-modal, $sprite-dribble, $sprite-e-sepia-cp-32, $sprite-facebook, $sprite-hov, $sprite-linkedin-icon, $sprite-linkedin, $sprite-next-arrow-small, $sprite-next-arrow, $sprite-next, $sprite-prev-arrow-small, $sprite-prev-arrow, $sprite-quote-small, $sprite-quote, $sprite-read-more-black, $sprite-read-more-white, $sprite-read-more-yellow, $sprite-theuxprodigy, $sprite-tickbox-mobile, $sprite-tickbox, $sprite-twitter, $sprite-value-1-mobile, $sprite-value-2-mobile, $sprite-value-3-mobile, $sprite-value-4-mobile, $sprite-value-icon-1, $sprite-value-icon-2, $sprite-value-icon-3, $sprite-visit-website, );
$spritesheet: (1920px, 1411px, '../images/sprites/build/sprites.png?v=1679647820512', $spritesheet-sprites, );

// The provided mixins are intended to be used with the array-like variables
//
// .icon-home {
//   @include sprite-width($icon-home);
// }
//
// .icon-email {
//   @include sprite($icon-email);
// }
//
// Example usage in HTML:
//
// `display: block` sprite:
// <div class="icon-home"></div>
//
// To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:
//
// // CSS
// .icon {
//   display: inline-block;
// }
//
// // HTML
// <i class="icon icon-home"></i>
@mixin sprite-width($sprite) {
  width: nth($sprite, 5);
}

@mixin sprite-height($sprite) {
  height: nth($sprite, 6);
}

@mixin sprite-position($sprite) {
  $sprite-offset-x: nth($sprite, 3);
  $sprite-offset-y: nth($sprite, 4);
  background-position: $sprite-offset-x  $sprite-offset-y;
}

@mixin sprite-image($sprite) {
  $sprite-image: nth($sprite, 9);
  background-image: url(#{$sprite-image});
}

@mixin sprite($sprite) {
  @include sprite-image($sprite);
  @include sprite-position($sprite);
  @include sprite-width($sprite);
  @include sprite-height($sprite);
}

// The `sprites` mixin generates identical output to the CSS template
//   but can be overridden inside of SCSS
//
// @include sprites($spritesheet-sprites);
@mixin sprites($sprites) {
  @each $sprite in $sprites {
    $sprite-name: nth($sprite, 10);
    .#{$sprite-name} {
      @include sprite($sprite);
    }
  }
}
