.selectboxit-container {
 // width: 100%;

  .selectboxit-option-anchor {
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
    display: block;
  }

  * {
    font-family: $mont;
    font-size: $smaller;
    font-weight: 300;
  }

  .selectboxit-list {
    background-color: $mgray;
    border: none;
  }

  .selectboxit-options {
    border-radius: 0;

    /* Set's the drop down options width to the same width as the drop down button */
     max-width: 440px;

    /* Set's the max-height property to only show a subset of the drop down items.
       If you do not set a max-height property, SelectBoxIt will dynamically
       position the dropdown (when opened) to make sure the drop down items are not
       displayed outside of the current window viewport.
    */
    max-height: 500px;

    .selectboxit-option-anchor {
      border-radius: 0;
    }
  }


  .selectboxit {
  //  width: 100%;
    border-radius: 0;
    background: $mgray;
    border: none;
    float: none;
    height: 42px;
    //  margin-top: -3px;

    &:hover, &:focus {
      color: $black;
      background: $mgray;
    }

   span, .selectboxit-options a {
      border-radius: 0;
      text-align: left;
      height: 42px;
      border: none;
      line-height: 42px;
      display: block;
      color: $black;
      font-family: $mont;
      font-weight: 300;
      font-size: $small;

    }
  }
}

.selectboxit .selectboxit-arrow-container {
  .selectboxit-arrow {
    margin-top: -4px;
  }

  .uxprodigy-select {
    border: solid $dgray;
    border-width: 0 2px 2px 0;
    display: inline-block;
    padding: 3px;
    transform: rotate(45deg);
    width: 8px;
  }
}

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: $black;
  background: $yellow;
}

.selectboxit {
  &.selectboxit-focus {
    border: 1px solid #fbc31e;
  }
}