@import url("https://fonts.googleapis.com/css?family=Libre+Baskerville:400,400i,700|Montserrat:300,300i,400,400i,700,700i");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Roboto:wght@300;400;500;700;900&display=swap");
.tabs a {
  background-color: #100a0f;
  color: #fbc31e;
  font-weight: 300; }
  .tabs a.active, .tabs a:hover, .tabs a:focus {
    background-color: #fbc31e;
    color: #100a0f; }

textarea,
input,
button,
a {
  outline: none; }

.l-main .node ul,
.l-main .block ul,
.l-content-2 .node ul,
.l-content-2 .block ul {
  list-style: disc;
  padding-left: 1em;
  margin-left: 0;
  margin-bottom: 2em; }
  .l-main .node ul li,
  .l-main .block ul li,
  .l-content-2 .node ul li,
  .l-content-2 .block ul li {
    margin-bottom: 0.1em; }
    .l-main .node ul li:last-child,
    .l-main .block ul li:last-child,
    .l-content-2 .node ul li:last-child,
    .l-content-2 .block ul li:last-child {
      margin-bottom: 0; }

.l-main .node ol,
.l-main .block ol,
.l-content-2 .node ol,
.l-content-2 .block ol {
  list-style: decimal;
  padding-left: 1em;
  margin-left: 0;
  margin-bottom: 2em; }
  .l-main .node ol li,
  .l-main .block ol li,
  .l-content-2 .node ol li,
  .l-content-2 .block ol li {
    margin-bottom: 0.1em; }
    .l-main .node ol li:last-child,
    .l-main .block ol li:last-child,
    .l-content-2 .node ol li:last-child,
    .l-content-2 .block ol li:last-child {
      margin-bottom: 0; }

.menu {
  list-style: none;
  list-style-image: none; }
  .menu .collapsed,
  .menu .leaf {
    list-style: none;
    list-style-image: none; }

strong {
  font-weight: 700; }

u {
  text-decoration: line-through; }

em {
  font-style: italic; }

sub {
  vertical-align: sub;
  font-size: smaller; }

sup {
  vertical-align: super;
  font-size: smaller; }

.odometer.odometer-auto-theme, .odometer.odometer-theme-minimal {
  display: -moz-inline-box;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  position: relative; }

.odometer.odometer-auto-theme, .odometer.odometer-theme-minimal {
  *display: inline; }

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-minimal .odometer-digit {
  display: -moz-inline-box;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  position: relative; }

.odometer.odometer-auto-theme .odometer-digit, .odometer.odometer-theme-minimal .odometer-digit {
  *display: inline; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
  display: -moz-inline-box;
  -moz-box-orient: vertical;
  display: inline-block;
  vertical-align: middle;
  *vertical-align: auto;
  visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-spacer, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-spacer {
  *display: inline; }

.odometer.odometer-auto-theme .odometer-digit .odometer-digit-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-digit-inner {
  text-align: left;
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon {
  display: block; }

.odometer.odometer-auto-theme .odometer-digit .odometer-ribbon-inner, .odometer.odometer-theme-minimal .odometer-digit .odometer-ribbon-inner {
  display: block;
  -webkit-backface-visibility: hidden; }

.odometer.odometer-auto-theme .odometer-digit .odometer-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value {
  display: block;
  -webkit-transform: translateZ(0); }

.odometer.odometer-auto-theme .odometer-digit .odometer-value.odometer-last-value, .odometer.odometer-theme-minimal .odometer-digit .odometer-value.odometer-last-value {
  position: absolute; }

.odometer.odometer-auto-theme.odometer-animating-up .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s; }

.odometer.odometer-auto-theme.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-up.odometer-animating .odometer-ribbon-inner, .odometer.odometer-auto-theme.odometer-animating-down .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down .odometer-ribbon-inner {
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -ms-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transform: translateY(-100%); }

.odometer.odometer-auto-theme.odometer-animating-down.odometer-animating .odometer-ribbon-inner, .odometer.odometer-theme-minimal.odometer-animating-down.odometer-animating .odometer-ribbon-inner {
  -webkit-transition: -webkit-transform 2s;
  -moz-transition: -moz-transform 2s;
  -ms-transition: -ms-transform 2s;
  -o-transition: -o-transform 2s;
  transition: transform 2s;
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0); }

/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent; }

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0; }
  .slick-list:focus {
    outline: none; }
  .slick-list.dragging {
    cursor: pointer;
    cursor: hand; }

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0); }

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block; }
  .slick-track:before, .slick-track:after {
    content: "";
    display: table; }
  .slick-track:after {
    clear: both; }
  .slick-loading .slick-track {
    visibility: hidden; }

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none; }
  [dir="rtl"] .slick-slide {
    float: right; }
  .slick-slide img {
    display: block; }
  .slick-slide.slick-loading img {
    display: none; }
  .slick-slide.dragging img {
    pointer-events: none; }
  .slick-initialized .slick-slide {
    display: block; }
  .slick-loading .slick-slide {
    visibility: hidden; }
  .slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent; }

.slick-arrow.slick-hidden {
  display: none; }

.slicknav_btn {
  position: relative;
  display: block;
  vertical-align: middle;
  float: right;
  padding: 0.438em 0.625em 0.438em 0.625em;
  line-height: 1.125em;
  cursor: pointer; }
  .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
    margin-top: 0.188em; }

.slicknav_menu {
  *zoom: 1; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    line-height: 1.188em;
    float: left; }
  .slicknav_menu .slicknav_icon {
    float: left;
    width: 1.125em;
    height: 0.875em;
    margin: 0.188em 0 0 0.438em; }
    .slicknav_menu .slicknav_icon:before {
      background: transparent;
      width: 1.125em;
      height: 0.875em;
      display: block;
      content: "";
      position: absolute; }
  .slicknav_menu .slicknav_no-text {
    margin: 0; }
  .slicknav_menu .slicknav_icon-bar {
    display: block;
    width: 1.125em;
    height: 0.125em;
    -webkit-border-radius: 1px;
    -moz-border-radius: 1px;
    border-radius: 1px;
    -webkit-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25);
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.25); }
  .slicknav_menu:before {
    content: " ";
    display: table; }
  .slicknav_menu:after {
    content: " ";
    display: table;
    clear: both; }

.slicknav_nav {
  clear: both; }
  .slicknav_nav ul {
    display: block; }
  .slicknav_nav li {
    display: block; }
  .slicknav_nav .slicknav_arrow {
    font-size: 0.8em;
    margin: 0 0 0 0.4em; }
  .slicknav_nav .slicknav_item {
    cursor: pointer; }
    .slicknav_nav .slicknav_item a {
      display: inline; }
  .slicknav_nav .slicknav_row {
    display: block; }
  .slicknav_nav a {
    display: block; }
  .slicknav_nav .slicknav_parent-link a {
    display: inline; }

.slicknav_brand {
  float: left; }

.slicknav_menu .slicknav_nav {
  padding-top: 40px; }

.slicknav_menu {
  clear: both;
  background: #ffffff; }
  .slicknav_menu a {
    color: #100a0f;
    text-decoration: none; }
  .slicknav_menu .slicknav_btn {
    position: static;
    display: block;
    vertical-align: middle;
    float: none;
    padding: 0;
    line-height: 0;
    cursor: pointer; }
    .slicknav_menu .slicknav_btn .slicknav_icon-bar + .slicknav_icon-bar {
      margin-top: 0; }
    .slicknav_menu .slicknav_btn .slicknav_icon {
      float: none;
      display: none; }
  .slicknav_menu .slicknav_nav {
    /* margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    right: 0;*/
    height: 100vh;
    overflow-y: auto; }
    .slicknav_menu .slicknav_nav .activew-circle {
      right: 50%;
      top: 18px;
      transform: translate(58px); }
      @media (max-width: 1024px) {
        .slicknav_menu .slicknav_nav .activew-circle {
          right: 50% !important;
          top: 18px;
          transform: translateX(60px); } }
      @media (max-width: 570px) {
        .slicknav_menu .slicknav_nav .activew-circle {
          right: 50% !important;
          top: 18px;
          transform: translateX(60px); } }
    .slicknav_menu .slicknav_nav .slicknav_arrow {
      color: #100a0f;
      margin-left: 0.1em;
      font-size: 16px; }
    .slicknav_menu .slicknav_nav li {
      text-align: center; }
      .slicknav_menu .slicknav_nav li a, .slicknav_menu .slicknav_nav li span.nolink {
        display: block;
        color: #100a0f;
        position: relative;
        padding: 15px 0;
        font-size: 1.38888rem;
        text-align: center;
        font-weight: 300; }
        .slicknav_menu .slicknav_nav li a:last-child a, .slicknav_menu .slicknav_nav li span.nolink:last-child a {
          border-bottom: none; }
      .slicknav_menu .slicknav_nav li.slicknav_parent > a {
        padding-left: 0; }
        @media (max-width: 1100px) {
          .slicknav_menu .slicknav_nav li.slicknav_parent > a {
            padding-bottom: 0; } }
      .slicknav_menu .slicknav_nav li .slicknav_arrow {
        position: absolute;
        top: 0;
        right: 0;
        height: 36px;
        width: 56px;
        display: block;
        line-height: 36px;
        vertical-align: middle;
        text-align: center; }
  .slicknav_menu .slicknav_menutxt {
    display: block;
    float: none;
    font-size: 0.88888rem;
    /*     margin-right: 0.8em;
        margin-bottom: -0.2em; */
    vertical-align: text-bottom;
    padding: 15px 18px;
    width: 88px;
    line-height: 1.5;
    background: none;
    color: #100a0f;
    cursor: pointer;
    position: absolute;
    right: 92px;
    bottom: 0; }

.selectboxit-container .selectboxit-option-anchor {
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  overflow: hidden;
  display: block; }

.selectboxit-container * {
  font-family: "Montserrat", sans-serif;
  font-size: 0.77777rem;
  font-weight: 300; }

.selectboxit-container .selectboxit-list {
  background-color: #efefef;
  border: none; }

.selectboxit-container .selectboxit-options {
  border-radius: 0;
  /* Set's the drop down options width to the same width as the drop down button */
  max-width: 440px;
  /* Set's the max-height property to only show a subset of the drop down items.
       If you do not set a max-height property, SelectBoxIt will dynamically
       position the dropdown (when opened) to make sure the drop down items are not
       displayed outside of the current window viewport.
    */
  max-height: 500px; }
  .selectboxit-container .selectboxit-options .selectboxit-option-anchor {
    border-radius: 0; }

.selectboxit-container .selectboxit {
  border-radius: 0;
  background: #efefef;
  border: none;
  float: none;
  height: 42px; }
  .selectboxit-container .selectboxit:hover, .selectboxit-container .selectboxit:focus {
    color: #100a0f;
    background: #efefef; }
  .selectboxit-container .selectboxit span, .selectboxit-container .selectboxit .selectboxit-options a {
    border-radius: 0;
    text-align: left;
    height: 42px;
    border: none;
    line-height: 42px;
    display: block;
    color: #100a0f;
    font-family: "Montserrat", sans-serif;
    font-weight: 300;
    font-size: 0.88888rem; }

.selectboxit .selectboxit-arrow-container .selectboxit-arrow {
  margin-top: -4px; }

.selectboxit .selectboxit-arrow-container .uxprodigy-select {
  border: solid #888888;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 3px;
  transform: rotate(45deg);
  width: 8px; }

.selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
  color: #100a0f;
  background: #fbc31e; }

.selectboxit.selectboxit-focus {
  border: 1px solid #fbc31e; }

#sliding-popup .popup-content {
  display: block;
  padding: 20px 0 16px;
  max-width: 87%; }
  #sliding-popup .popup-content:after {
    content: "";
    display: block;
    clear: both; }
  #sliding-popup .popup-content #popup-text {
    margin: 0;
    max-width: 57%;
    margin-right: 3%;
    font-family: "Libre Baskerville", serif; }
    @media (max-width: 400px) {
      #sliding-popup .popup-content #popup-text h2 {
        font-size: 14px; } }
    #sliding-popup .popup-content #popup-text p {
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 0;
      font-family: "Montserrat", sans-serif; }
      @media (max-width: 400px) {
        #sliding-popup .popup-content #popup-text p {
          font-size: 12px; } }
  #sliding-popup .popup-content #popup-buttons {
    margin: 7px 0 0; }
    #sliding-popup .popup-content #popup-buttons button {
      border-radius: 0;
      border: none;
      background: #ffffff;
      color: #000000 !important;
      padding: 10px 30px;
      display: inline-block;
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 400;
      margin-top: 0;
      box-shadow: none;
      text-shadow: none;
      transition: all 0.7s; }
      #sliding-popup .popup-content #popup-buttons button:hover {
        background: #000000;
        color: #ffffff !important; }
    #sliding-popup .popup-content #popup-buttons .agree-button {
      margin-right: 10px; }

.eu-cookie-compliance-more-button {
  font-size: 16px; }

@media (max-width: 800px) {
  #sliding-popup .popup-content {
    padding: 13px 0; }
    #sliding-popup .popup-content #popup-buttons {
      float: none;
      max-width: 100%;
      margin-top: 15px; }
      #sliding-popup .popup-content #popup-buttons button {
        padding: 7px 20px; }
    #sliding-popup .popup-content #popup-text {
      float: none;
      max-width: 100%; } }

@media screen and (max-width: 600px) {
  .field--name-field-caption {
    display: none; } }

.pgwModal .pm-body .pm-content {
  display: block !important; }

.node-type-accessibility *:focus {
  outline-style: solid !important;
  outline-color: #7D2B6D !important;
  outline-width: 2px !important; }

.node-type-accessibility #edit-submit:disabled {
  background: #8e8e8e; }
  @media (min-width: 1080px) {
    .node-type-accessibility #edit-submit:disabled:hover {
      cursor: url("../images/hov.png"), auto !important; } }
  @media (max-width: 1024px) {
    .node-type-accessibility #edit-submit:disabled {
      cursor: default; } }

.node-type-accessibility .error {
  border: 1px solid red !important; }

.node-type-accessibility .group-our-team {
  position: relative;
  z-index: 0; }

.node-type-accessibility .l-header-wrapper {
  background: transparent;
  box-shadow: 0px 3px 20px #00000019;
  background: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px); }

.node-type-accessibility .l-header {
  height: 100px;
  display: flex;
  align-items: center;
  max-width: 1640px;
  margin: 0 auto;
  z-index: 999;
  position: relative; }
  @media screen and (max-width: 1640px) {
    .node-type-accessibility .l-header {
      max-width: 98.2%;
      padding: 0 16px;
      margin: 0 auto; } }
  @media screen and (max-width: 600px) {
    .node-type-accessibility .l-header {
      height: 80px; } }
  @media screen and (max-width: 415px) {
    .node-type-accessibility .l-header {
      height: 60px; } }
  @media screen and (max-width: 1080px) {
    .node-type-accessibility .l-header .l-branding {
      min-width: 140px; } }
  @media screen and (max-width: 600px) {
    .node-type-accessibility .l-header .l-branding {
      min-width: 130px; } }
  .node-type-accessibility .l-header .l-region--header {
    position: relative;
    display: flex;
    justify-content: flex-end; }
    .node-type-accessibility .l-header .l-region--header ul {
      display: flex; }
      @media screen and (max-width: 1552px) {
        .node-type-accessibility .l-header .l-region--header ul {
          margin-right: -18px; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .l-header .l-region--header ul {
          margin-right: 0; } }
      .node-type-accessibility .l-header .l-region--header ul > li {
        display: flex;
        align-items: center;
        margin-right: 54px; }
        @media screen and (max-width: 1366px) {
          .node-type-accessibility .l-header .l-region--header ul > li {
            margin-right: 20px; } }
        .node-type-accessibility .l-header .l-region--header ul > li a {
          font-size: 16px; }
          @media screen and (max-width: 1250px) {
            .node-type-accessibility .l-header .l-region--header ul > li a {
              margin-right: 25px; } }
        @media screen and (max-width: 980px) {
          .node-type-accessibility .l-header .l-region--header ul > li {
            visibility: hidden; } }
        .node-type-accessibility .l-header .l-region--header ul > li:last-child {
          margin-right: 0; }
        .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
          color: white;
          background: #0c4f8e;
          width: 344px;
          height: 59px;
          font-size: 16px;
          border-radius: 8px;
          line-height: 59px;
          text-align: center;
          cursor: pointer; }
          @media screen and (max-width: 1366px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              width: 310px;
              height: 50px;
              line-height: 50px; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              visibility: visible;
              margin-right: -80px; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              margin-right: -89px;
              font-size: 12px;
              width: 265px;
              height: 48px;
              line-height: 49px; } }
          @media screen and (max-width: 480px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              margin-right: -98px;
              font-size: 11px;
              line-height: 48px;
              width: 228px;
              height: 45px; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              width: 182px;
              height: 35px;
              font-size: 9px;
              line-height: 36px; } }
          @media screen and (max-width: 359px) {
            .node-type-accessibility .l-header .l-region--header ul > li.contact-nav a {
              width: 120px;
              height: auto;
              font-size: 9px;
              line-height: 14px;
              padding: 8px 10px; } }
        .node-type-accessibility .l-header .l-region--header ul > li a {
          text-decoration: none;
          color: black;
          font-weight: bold;
          margin: 0 auto; }

.node-type-accessibility .l-main {
  max-width: 100%; }
  @media (max-width: 1600px) {
    .node-type-accessibility .l-main {
      max-width: 100%;
      overflow-x: hidden;
      margin: 0; } }

.node-type-accessibility .node--accessibility {
  scroll-behavior: smooth; }
  .node-type-accessibility .node--accessibility .field-collection-view {
    margin: 0; }
  @media screen and (max-width: 1080px) {
    .node-type-accessibility .node--accessibility .node__content {
      width: 100%;
      margin: 0 auto; } }
  .node-type-accessibility .node--accessibility h1,
  .node-type-accessibility .node--accessibility h2,
  .node-type-accessibility .node--accessibility h3,
  .node-type-accessibility .node--accessibility h4,
  .node-type-accessibility .node--accessibility p,
  .node-type-accessibility .node--accessibility a {
    font-family: "Montserrat", "Arial", sans-serif; }
  .node-type-accessibility .node--accessibility p {
    font-size: 18px;
    font-weight: normal; }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility p {
        font-size: 14px; } }
  .node-type-accessibility .node--accessibility h1,
  .node-type-accessibility .node--accessibility h2 {
    font-size: 46px;
    font-weight: bold;
    line-height: 60px; }
    @media screen and (max-width: 1820px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 44px;
        line-height: 58px; } }
    @media screen and (max-width: 1720px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 42px;
        line-height: 56px; } }
    @media screen and (max-width: 1640px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 40px;
        line-height: 52px; } }
    @media screen and (max-width: 1500px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 36px;
        line-height: 50px; } }
    @media screen and (max-width: 1358px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 34px;
        line-height: 48px; } }
    @media screen and (max-width: 1200px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 32px;
        line-height: 46px; } }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 30px;
        line-height: 44px; } }
    @media screen and (max-width: 768px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 28px;
        line-height: 42px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 26px;
        line-height: 40px; } }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 22px;
        line-height: 30px; } }
    @media screen and (max-width: 360px) {
      .node-type-accessibility .node--accessibility h1,
      .node-type-accessibility .node--accessibility h2 {
        font-size: 20px;
        line-height: 28px; } }
  .node-type-accessibility .node--accessibility h3 {
    font-size: 18px;
    font-weight: bold; }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility h3 {
        font-size: 16px; } }
  .node-type-accessibility .node--accessibility h4 {
    font-size: 16px;
    font-weight: bold; }
  .node-type-accessibility .node--accessibility img {
    display: block; }
  .node-type-accessibility .node--accessibility .blue-text {
    color: #0c4f8e; }
  .node-type-accessibility .node--accessibility .field-collection-container.clearfix {
    margin-bottom: 0; }
  .node-type-accessibility .node--accessibility .group-hero-section {
    background-color: #e7eded;
    position: relative; }
    .node-type-accessibility .node--accessibility .group-hero-section::before {
      content: "";
      position: absolute;
      background: url("../images/sprites/big-shape.png") no-repeat;
      top: 24px;
      right: 0;
      background-size: cover;
      width: 100%;
      height: 910px;
      background-position: center; }
      @media screen and (max-width: 2652px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          width: 98%;
          top: 0; } }
      @media screen and (max-width: 1720px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          width: 100%;
          top: -100px; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          right: -79px;
          height: 602px;
          top: -20px; } }
      @media screen and (max-width: 880px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          top: 100px;
          right: 0;
          height: 910px; } }
      @media screen and (max-width: 600px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          top: -33px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          background: url("../images/sprites/small-shape.png") center no-repeat;
          top: -186px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .group-hero-section::before {
          background: url("../images/sprites/small-shape.png") center no-repeat;
          top: -130px;
          background-size: cover; } }
    .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero {
      max-width: 1640px;
      margin: 0 auto;
      position: relative; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero {
          max-width: 98.2%;
          padding: 0 16px;
          margin: 0 auto; } }
      .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero::before {
        content: "";
        position: absolute;
        width: 232px;
        height: 132px;
        background: transparent;
        top: -5px;
        border: 2px solid white;
        left: 364px;
        border-bottom-left-radius: 162px;
        border-bottom-right-radius: 162px;
        border-top: none; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero::before {
            display: none; } }
      .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero::after {
        content: "";
        position: absolute;
        width: 23px;
        height: 23px;
        background: #ffc31e;
        top: 54px;
        left: 364px;
        border-radius: 50%; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero::after {
            display: none; } }
      .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero .field--name-field-main-title {
        width: 40%;
        padding: 15% 0; }
        @media screen and (max-width: 1780px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero .field--name-field-main-title {
            width: 45%; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero .field--name-field-main-title {
            width: 50%; } }
        @media screen and (max-width: 880px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero .field--name-field-main-title {
            width: 85%;
            margin: 0 auto;
            text-align: center;
            padding: 100px 0 45px; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-container-hero .field--name-field-main-title {
            width: 100%;
            padding: 50px 0 40px; } }
    .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group {
      max-width: 1640px;
      margin: 0 auto;
      position: relative;
      z-index: 4;
      padding-bottom: 194px; }
      @media screen and (max-width: 1775px) {
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group {
          margin-left: 20px; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group {
          padding-bottom: 125px; } }
      @media screen and (max-width: 600px) {
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group {
          padding-bottom: 100px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group {
          padding-bottom: 85px; } }
      .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container {
        position: relative;
        height: 400px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background: #ffffff;
        border-top-left-radius: 12px;
        border-bottom-left-radius: 12px;
        padding-left: 8%; }
        @media screen and (max-width: 880px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container {
            height: auto;
            background: transparent;
            padding: 0;
            align-items: center; } }
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container::before {
          content: "";
          position: absolute;
          width: 124px;
          height: 124px;
          border: 2px solid #ffc31e;
          bottom: 0;
          top: -15%;
          left: -4%;
          border-radius: 50%;
          z-index: -1; }
          @media screen and (max-width: 1775px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container::before {
              display: none; } }
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container:after {
          content: "";
          position: absolute;
          top: 0;
          right: calc((-100vw + 1640px) / 2);
          width: calc((100vw - 1640px) / 2);
          height: 400px;
          background: #ffffff; }
          @media screen and (min-width: 1680px) and (max-width: 1775px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container:after {
              width: calc((100vw - 1450px) / 2);
              margin-right: -67px;
              z-index: -1; } }
          @media screen and (max-width: 880px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container:after {
              display: none; } }
        @media screen and (max-width: 880px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container {
            order: 2;
            background: white;
            padding: 20px 40px;
            border-top-left-radius: 16px;
            border-bottom-left-radius: 16px;
            /* position: relative; */
            top: -100px;
            z-index: 5;
            margin-top: -100px;
            height: 280px;
            display: flex;
            flex-direction: column;
            justify-content: center; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container {
            padding: 30px 35px 20px;
            height: 259px; } }
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body {
          width: 45%; }
          @media screen and (max-width: 1270px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body {
              width: 40%; } }
          @media screen and (max-width: 880px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body {
              width: 100%;
              position: relative; } }
          @media screen and (max-width: 1640px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body > .field__items {
              padding-left: 5px; } }
          @media screen and (max-width: 880px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body > .field__items {
              padding-left: 40px; } }
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body p {
            font-size: 32px;
            font-weight: lighter;
            padding-bottom: 30px;
            line-height: 42px; }
            @media screen and (max-width: 1260px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body p {
                font-size: 25px;
                line-height: 32px; } }
            @media screen and (max-width: 768px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body p {
                font-size: 22px; } }
            @media screen and (max-width: 415px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body p {
                font-size: 20px;
                line-height: 28px; } }
            @media screen and (max-width: 359px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body p {
                font-size: 17px; } }
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body::before {
            content: "";
            position: absolute;
            width: 25px;
            height: 20px;
            background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
            background-position: -1462px -1082px;
            width: 25px;
            height: 20px;
            top: 83px;
            left: 5%; }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body::before {
                left: 4%; } }
            @media screen and (max-width: 880px) {
              .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-body::before {
                top: 10px;
                left: 0; } }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-field-caption {
            padding-left: 5px; } }
        @media screen and (max-width: 880px) {
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-field-caption {
            padding-left: 40px;
            margin-top: -15px;
            display: block; } }
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-field-caption p {
          font-size: 16px;
          line-height: 22px; }
          @media screen and (max-width: 880px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-field-caption p {
              font-size: 14px; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .group-text-container .field--name-field-caption p {
              font-size: 12px; } }
        .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
          position: absolute;
          bottom: 0;
          right: 0; }
          @media screen and (max-width: 1640px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 50%; } }
          @media screen and (max-width: 1220px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 55%; } }
          @media screen and (max-width: 940px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              right: 0; } }
          @media screen and (max-width: 880px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              position: static;
              order: 1;
              width: 60%; } }
          @media screen and (max-width: 768px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 65%; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 75%; } }
          @media screen and (max-width: 575px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 75%; } }
          @media screen and (max-width: 470px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 90%; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 95%; } }
          @media screen and (max-width: 360px) {
            .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image {
              width: 100%; } }
          .node-type-accessibility .node--accessibility .group-hero-section .group-text-caption-group > .group-white-container .field--name-field-image img {
            display: block;
            width: 100%; }
  .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
    background-color: #e7eded;
    padding-bottom: 490px; }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
        padding-bottom: 430px; } }
    @media screen and (max-width: 768px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
        padding-bottom: 360px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
        padding-bottom: 300px; } }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
        padding-bottom: 215px; } }
    @media screen and (max-width: 359px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities {
        padding-bottom: 190px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container {
      max-width: 1640px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container {
          max-width: 98.2%;
          padding: 0 16px;
          margin: 0 auto; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container {
          flex-direction: column;
          align-items: center; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
        position: relative;
        left: calc((-100vw + 1640px) / 2);
        margin-right: 4vw;
        margin-left: -9.4%;
        width: 53vw; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
            left: 0;
            margin-left: -11.3%;
            width: 58vw; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
            margin-left: -31.4%;
            width: 95vw; } }
        @media screen and (max-width: 768px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
            margin-left: -28.4%;
            width: 100vw; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
            margin-left: -29.4%;
            width: 100vw; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div {
            margin-left: -30%;
            width: 100vw; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image-2 {
          z-index: 40;
          position: absolute;
          right: 0;
          top: 0;
          width: 21vw; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image-2 {
              width: 32vw; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image-2 {
              width: 40vw; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image {
          z-index: 30;
          position: relative;
          margin-top: 6%; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
            content: "";
            position: absolute;
            background: url(../images/sprites/arrow_01.svg) no-repeat center;
            width: 80px;
            height: 94px;
            right: 70%;
            bottom: -1.2%; }
            @media screen and (max-width: 1820px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
                bottom: -1.5%; } }
            @media screen and (max-width: 1500px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
                bottom: -2.5%; } }
            @media screen and (max-width: 1300px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
                bottom: -3.5%; } }
            @media screen and (max-width: 1200px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
                bottom: -4.5%; } }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-image:before {
                display: none; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-caption {
          width: 260px;
          position: absolute;
          margin-top: 18px;
          margin-left: 29%; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-caption {
              display: none; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-left-div .field--name-field-caption .field__item p {
            font-size: 16px; }
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div {
        width: 43vw;
        margin-top: 15%;
        margin-left: -10%; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div {
            margin: 0 auto;
            width: 100%; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-main-title {
          padding-bottom: 30px; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-main-title {
              padding-top: 20px;
              width: 100%;
              padding-bottom: 20px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-body {
          padding-bottom: 60px; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-body p {
            font-size: 24px;
            line-height: 36px;
            max-width: 85%; }
            @media screen and (max-width: 1358px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-body p {
                max-width: 100%;
                font-size: 20px; } }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-body p {
                max-width: 95%;
                font-size: 18px; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .group-right-div .field--name-field-body p {
                max-width: 100%;
                font-size: 16px;
                line-height: 28px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-cards-group-title {
        padding-bottom: 20px; }
      .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items {
            flex-wrap: wrap; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item {
          width: 49%;
          width: 32%;
          margin-right: 2%;
          margin-bottom: 1.5%; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item {
              width: 48%;
              margin-bottom: 1.5%;
              margin-right: 1.5%; } }
          @media screen and (max-width: 768px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item {
              margin-right: 1.5%; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
            display: flex;
            align-items: center;
            padding: 16px 15px;
            background: white;
            border-radius: 12px;
            height: 102px; }
            @media screen and (max-width: 1792px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
                padding: 18px 15px;
                height: 108px; } }
            @media screen and (max-width: 1640px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
                height: 130px; } }
            @media screen and (max-width: 1250px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
                padding: 10px;
                height: 165px; } }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
                padding: 16px 15px;
                height: 135px; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item .content {
                padding: 16px 15px;
                height: auto; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item:nth-child(3n) {
            margin-right: 0; }
            @media (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item:nth-child(3n) {
                margin-right: 1.5%; } }
          @media (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items > .field__item:nth-child(2n) {
              margin-right: 1.5%; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-icon-title {
          min-width: 63px; }
          @media (max-width: 1600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-icon-title {
              min-width: auto; } }
          @media (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-icon-title {
              min-width: 63px; } }
          @media (max-width: 450px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-icon-title {
              min-width: 20px;
              max-width: 40px; }
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-icon-title img {
                max-width: 60%; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .field--name-field-icon {
          margin-right: 15px;
          flex-shrink: 0; }
          @media (max-width: 1400px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .field--name-field-icon {
              margin-right: 7px; } }
          @media (max-width: 450px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .field--name-field-icon {
              margin-right: 0; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-text-group {
          width: 80%; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-main-title {
            padding-bottom: 0;
            padding-top: 0; }
            @media (max-width: 1600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-main-title h3 {
                font-size: 14px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-disabilities .group-disabilities-container .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-cards-body {
            display: none; }
  .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
    max-width: 1640px;
    margin: 0 auto;
    padding-bottom: 160px; }
    @media screen and (max-width: 1640px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        max-width: 98.2%;
        margin: 0 auto;
        padding: 0 16px;
        padding-bottom: 175px; } }
    @media screen and (max-width: 1358px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        padding-bottom: 145px; } }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        padding-bottom: 200px; } }
    @media screen and (max-width: 768px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        padding-bottom: 180px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        padding-bottom: 160px; } }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container {
        padding-bottom: 120px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container {
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container {
          flex-direction: column-reverse;
          align-items: center;
          padding-top: 190px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div {
        width: 43vw;
        margin-top: 13%; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div {
            margin: 0 auto;
            width: 100%; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-main-title {
          padding-bottom: 30px;
          width: 80%; }
          @media screen and (min-width: 1080px) and (max-width: 1200px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-main-title {
              width: 100%; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-main-title {
              padding-top: 20px;
              width: 100%;
              padding-bottom: 20px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-body p {
          font-size: 24px;
          line-height: 36px;
          max-width: 85%;
          padding-bottom: 60px; }
          @media screen and (max-width: 1358px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-body p {
              max-width: 100%;
              font-size: 20px; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-body p {
              max-width: 95%;
              font-size: 18px;
              padding-bottom: 68px; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-left-div .field--name-field-body p {
              max-width: 100%;
              font-size: 16px;
              line-height: 28px;
              padding-bottom: 40px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
        position: relative;
        right: calc((-100vw + 1640px) / 2);
        width: 68vw;
        margin-left: 0;
        margin-right: -23%;
        margin-top: -320px; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
            right: 0;
            width: 58vw;
            margin-left: 0;
            margin-right: -12%; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
            position: static;
            width: 95vw;
            margin-right: -51%;
            margin-top: -503px; } }
        @media screen and (max-width: 768px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
            width: 100vw;
            margin-right: -37%;
            margin-top: -443px; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
            width: 100vw;
            margin-right: -33%;
            margin-top: -390px; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div {
            width: 100vw;
            margin-right: -37%;
            margin-top: -329px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-caption {
          width: 260px;
          position: absolute;
          left: -230px;
          top: 185px;
          z-index: 6; }
          @media screen and (max-width: 1500px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-caption {
              left: -255px; } }
          @media screen and (max-width: 1200px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-caption {
              left: -265px; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-caption {
              display: none; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-caption .field__items p {
            text-align: right;
            font-size: 16px; }
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
          content: "";
          position: absolute;
          width: 94px;
          height: 49px;
          background: url(../images/sprites/arrow_02.svg) no-repeat center;
          top: 15%;
          left: 2.8%; }
          @media screen and (max-width: 1500px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              left: 1.8%; } }
          @media screen and (max-width: 1358px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              left: -0.2%; } }
          @media screen and (max-width: 1300px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              left: -0.8%; } }
          @media screen and (max-width: 1238px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              left: -2.8%; } }
          @media screen and (max-width: 1174px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              left: -3.8%; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .group-top-container .group-right-div .field--name-field-image:before {
              display: none; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards {
      margin-top: 8%; }
      @media screen and (max-width: 768px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards {
          margin-top: 40px; } }
      @media screen and (max-width: 600px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards {
          margin-top: 0; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items {
        display: flex;
        justify-content: flex-end;
        flex-wrap: wrap; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items {
            justify-content: center; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items {
            flex-wrap: nowrap;
            flex-direction: column; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item {
          width: 24.25%;
          position: relative;
          margin-right: 1%;
          border: 1px solid #e7eded;
          border-radius: 12px;
          padding: 5% 2% 2%; }
          @media screen and (max-width: 1500px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item {
              width: 31.33%;
              margin-bottom: 8%;
              margin-left: 1%; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item {
              width: 42%;
              margin: 0 15px 115px; } }
          @media screen and (max-width: 768px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item {
              width: 70%;
              padding: 5% 2%; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item {
              width: 100%;
              border: none;
              margin-bottom: 20px;
              padding: 0;
              margin-left: 0; } }
          @media screen and (min-width: 1500px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item:nth-child(3n + 4) {
              margin-right: 0; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item:nth-child(-n + 4) {
              margin-bottom: 7%; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item::before {
            content: "";
            position: absolute;
            height: 100px;
            width: 42%;
            background: white;
            top: -62px;
            z-index: 10;
            left: 0;
            right: 0;
            margin: 0 auto; }
            @media screen and (max-width: 768px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item::before {
                width: 36%; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item::before {
                display: none; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .content {
              display: flex;
              align-items: center; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title {
              display: flex;
              justify-content: flex-start;
              align-items: center; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title .field--name-field-icon {
            width: 100px;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: -50px;
            left: 0;
            right: 0;
            margin: 0 auto;
            background: linear-gradient(219deg, #1f80db, #52ed93);
            border-radius: 50%;
            z-index: 12; }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title .field--name-field-icon {
                margin: 0 12px 0 0;
                width: 69px;
                height: 69px;
                position: static; } }
            .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title .field--name-field-icon .field__items {
              background: #ffffff;
              border-radius: 50%;
              width: 96px;
              height: 96px;
              display: flex;
              align-items: center;
              justify-content: center; }
              @media screen and (max-width: 600px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title .field--name-field-icon .field__items {
                  width: 65px;
                  height: 65px; }
                  .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-icon-title .field--name-field-icon .field__items img {
                    margin: 0 auto;
                    width: 67%; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
            padding-bottom: 4%;
            text-align: center; }
            @media screen and (max-width: 1640px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
                padding: 20px 5px 20px; } }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
                padding: 25px 5px 20px; } }
            @media screen and (max-width: 880px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
                padding: 30px 5px 20px; } }
            @media screen and (max-width: 768px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
                padding: 40px 5px 20px; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title {
                padding: 0;
                margin-left: 5px; }
                .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-main-title h3 {
                  text-align: left; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-cards-body {
            line-height: 27px;
            text-align: center; }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-services .group-services-container .field--name-field-collection-cards > .field__items > .field__item .group-text-group .field--name-field-cards-body {
                display: none; } }
  .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs {
    background-color: #e7eded;
    padding-bottom: 250px; }
    @media screen and (max-width: 1220px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs {
        padding-bottom: 170px; } }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs {
        padding-bottom: 150px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs {
        padding-bottom: 80px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container {
      max-width: 1640px;
      margin: 0 auto;
      display: flex;
      justify-content: space-between; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container {
          max-width: 98.2%;
          padding: 0 16px;
          margin: 0 auto; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container {
          flex-direction: column;
          align-items: center; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
        position: relative;
        left: calc((-100vw + 1640px) / 2);
        margin-right: 4vw;
        margin-left: -5.3%;
        width: 53vw; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
            left: 0;
            margin-left: -8.5%;
            width: 58vw; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
            margin-left: -26%;
            width: 95vw; } }
        @media screen and (max-width: 768px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
            margin-left: -30.5%;
            width: 100vw; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
            margin-left: -31.5%;
            width: 100vw; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div {
            margin-left: -33%;
            width: 100vw; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-image {
          margin-top: -26%;
          position: relative; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-image:before {
            content: "";
            position: absolute;
            background: url(../images/sprites/arrow_01.svg) no-repeat center;
            width: 80px;
            height: 94px;
            right: 64%;
            bottom: -66px; }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-image:before {
                display: none; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-caption {
          width: 260px;
          position: absolute;
          margin-top: 63px;
          margin-left: 35%; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-caption {
              display: none; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-left-div .field--name-field-caption .field__item p {
            font-size: 16px; }
      .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div {
        width: 43vw;
        margin-top: 9.5%;
        margin-left: -10%; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div {
            margin: 0 auto;
            width: 100%; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-main-title {
          padding-bottom: 30px; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-main-title {
              padding-top: 20px;
              width: 100%;
              padding-bottom: 20px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-body p {
          font-size: 24px;
          line-height: 36px;
          max-width: 85%;
          padding-bottom: 60px; }
          @media screen and (max-width: 1358px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-body p {
              max-width: 100%;
              font-size: 20px; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-body p {
              max-width: 95%;
              font-size: 18px; } }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-body p {
              max-width: 100%;
              font-size: 16px;
              line-height: 28px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-cards-group-title {
          padding-bottom: 20px; }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-cards-group-title {
              padding-bottom: 10px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start; }
          @media screen and (max-width: 768px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items {
              flex-wrap: wrap;
              justify-content: flex-start; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item {
            width: 49%;
            margin-right: 2%;
            margin-bottom: 1.5%; }
            @media screen and (max-width: 768px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item {
                width: 49%;
                margin-right: 2%; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item {
                width: 100%;
                margin-bottom: 10px;
                margin-right: 0; } }
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
              display: flex;
              align-items: center;
              padding: 16px 15px;
              background: white;
              border-radius: 12px;
              height: 102px; }
              @media screen and (max-width: 1792px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
                  padding: 18px 15px;
                  height: 108px; } }
              @media screen and (max-width: 1640px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
                  height: 130px; } }
              @media screen and (max-width: 1250px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
                  padding: 10px;
                  height: 165px; } }
              @media screen and (max-width: 1080px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
                  padding: 16px 15px;
                  height: 135px; } }
              @media screen and (max-width: 600px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item .content {
                  padding: 16px 15px;
                  height: auto; } }
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items > .field__item:nth-child(2n) {
              margin-right: 0; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .group-icon-title {
            min-width: 63px; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .field--name-field-icon {
            margin-right: 15px;
            flex-shrink: 0; }
            @media (max-width: 1400px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .field--name-field-icon {
                margin-right: 7px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .group-text-group {
            width: 80%; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-main-title {
              padding-bottom: 0;
              padding-top: 0; }
              @media (max-width: 1600px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-main-title h3 {
                  font-size: 14px; } }
            .node-type-accessibility .node--accessibility .field-collection-item-field-accessibility-certs .group-certs-container .group-right-div .field--name-field-collection-cards > .field__items .group-text-group .field--name-field-cards-body {
              font-size: 18px;
              padding-bottom: 0;
              line-height: 24px; }
  .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
    background-color: #e7eded;
    padding-bottom: 160px;
    padding-bottom: 30vw;
    padding-bottom: 390px;
    position: relative;
    z-index: 1; }
    @media (max-width: 1500px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 350px; } }
    @media (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 60vw;
        padding-bottom: 540px; } }
    @media (max-width: 768px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 380px; } }
    @media (max-width: 680px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 330px; } }
    @media (max-width: 600px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 310px; } }
    @media (max-width: 450px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team {
        padding-bottom: 60vw; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-our-team {
      margin-bottom: 100px;
      margin-top: -100px; }
    .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container {
      max-width: 1640px;
      margin: 0 auto;
      background: #ffffff;
      border-radius: 16px 0 16px 0;
      position: relative; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container {
          max-width: 98.2%;
          margin: 0 20px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container::before {
        content: "";
        position: absolute;
        width: 124px;
        height: 124px;
        border: 2px solid #ffc31e;
        border-radius: 50%;
        top: -59px;
        left: 96%;
        z-index: -1; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container::before {
            width: 100px;
            height: 100px;
            left: 96.5%;
            top: -54px; } }
        @media screen and (max-width: 1780px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container::before {
            display: none; } }
      @media (max-width: 420px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container br {
          display: none; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-main-title {
        width: 70%;
        padding: 5% 0 5% 9.1%; }
        @media screen and (max-width: 936px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-main-title {
            width: 90%;
            padding: 8% 0 5% 9.1%; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-main-title {
            width: 252px;
            margin-right: 0;
            padding-bottom: 42px;
            padding-top: 40px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items {
        display: flex;
        flex-wrap: wrap;
        padding-bottom: 5%; }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items {
            flex-wrap: wrap; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item {
          width: calc(96px + ((100% - 352px) / 12 * 4));
          padding: 30px 20px;
          position: relative; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item {
              margin-bottom: 15px;
              width: 50%;
              border: 0; } }
          @media (max-width: 400px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item {
              width: 50%;
              border: 0;
              margin-bottom: 5px;
              padding: 20px 20px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item:last-child {
            border-right: none; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item {
            position: relative;
            z-index: 1; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
              display: block;
              width: 42px;
              height: 42px;
              text-indent: -9999px;
              color: transparent;
              background: url(../images/sprites/linkedin-icon.png) no-repeat;
              background-position: center;
              border-radius: 50%;
              position: absolute;
              border-radius: 50%;
              margin-left: 60%;
              margin-top: 3%; }
              @media screen and (max-width: 1500px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 62%; } }
              @media screen and (max-width: 1358px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 63%; } }
              @media screen and (max-width: 1250px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 64%; } }
              @media screen and (max-width: 1150px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 65%; } }
              @media screen and (max-width: 1090px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 67%; } }
              @media screen and (max-width: 1080px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 60.5%; } }
              @media screen and (max-width: 880px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 62%; } }
              @media screen and (max-width: 768px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a {
                  margin-left: 64.5%; } }
              .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-linkedin > .field__items > .field__item a:after {
                content: "";
                width: 42px;
                height: 42px;
                position: absolute;
                background: #1f80db;
                border-radius: 50%;
                z-index: -1;
                margin: 0 auto; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-image {
            padding-bottom: 4%; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-image img {
              border-radius: 100%;
              background: linear-gradient(226deg, #1f80db, #52ed93);
              padding: 2px;
              margin: 0 auto; }
              @media screen and (max-width: 415px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-image img {
                  width: 152px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-member-name {
            text-align: center;
            padding: 1% 0;
            word-break: break-word; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-member-name h3 {
              font-size: 20px; }
              @media screen and (max-width: 415px) {
                .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-member-name h3 {
                  font-size: 14px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-body {
            text-align: center; }
            @media screen and (max-width: 415px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members > .field__items > .field__item .field--name-field-body p {
                font-size: 12px;
                line-height: 16px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group {
        width: 160px;
        margin: 0 auto;
        position: relative;
        padding-bottom: 10px; }
        @media (max-width: 400px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group {
            width: 110px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group .field--name-field-linkedin {
          position: absolute;
          right: 40px;
          top: 0; }
          .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group .field--name-field-linkedin a {
            overflow: hidden; }
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group .field--name-field-linkedin a:after {
              left: 0;
              top: 0;
              z-index: -1; }
        .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group .field--name-field-secondary-title {
          background: white;
          border: 1px solid #B2C7D9;
          border-radius: 20px;
          text-align: center;
          font-size: 12px;
          padding: 5px 0;
          font-weight: bold;
          color: #0C4F8E;
          width: 100px;
          position: absolute;
          bottom: 10px;
          left: 50%;
          transform: translateX(-50%);
          background: white; }
          @media (max-width: 400px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-our-team .group-team-container .field--name-field-team-members .group-image-group .field--name-field-secondary-title {
              font-size: 10px; } }
  .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials {
    background-color: #e7eded;
    padding-bottom: 420px; }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials {
        padding-bottom: 562px; } }
    @media screen and (max-width: 768px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials {
        padding-bottom: 440px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials {
        padding-bottom: 390px; } }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials {
        padding-bottom: 380px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container {
      max-width: 1640px;
      margin: 0 auto;
      position: relative; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container {
          max-width: 98.2%;
          padding: 0 16px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container .field--name-field-main-title {
        padding-bottom: 40px;
        width: 700px;
        position: relative; }
        @media screen and (max-width: 1024px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container .field--name-field-main-title {
            width: 65%;
            padding-left: 10px; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container .field--name-field-main-title {
            width: 216px;
            padding-left: 10px; } }
        @media screen and (max-width: 359px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonials-container .field--name-field-main-title {
            width: 166px;
            padding-left: 10px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
      position: absolute;
      top: 20px;
      right: 0;
      display: flex;
      align-items: center;
      overflow: hidden;
      margin: 0 20px; }
      @media screen and (max-width: 1024px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          right: 10px; } }
      @media screen and (max-width: 809px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          top: 12px; } }
      @media screen and (max-width: 600px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          top: 30px; } }
      @media screen and (max-width: 560px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          top: 32px; } }
      @media screen and (max-width: 443px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          top: 23px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows {
          top: 28px;
          margin: 0 10px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slider-nav-circle {
        width: 16px;
        height: 16px;
        background: #ffc31e;
        border-radius: 50%;
        display: block;
        margin: 0 20px; }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slider-nav-circle {
            width: 11px;
            height: 11px;
            margin: 0 15px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-prev {
        display: block;
        width: 40px;
        height: 20px;
        top: -20%;
        right: 12%;
        background-color: transparent;
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1420px -1082px;
        width: 40px;
        height: 20px;
        color: transparent;
        text-indent: -9999px;
        border: 0;
        cursor: pointer; }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-prev:disabled {
          opacity: 0.2;
          cursor: default; }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-prev {
            background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
            background-position: -1236px -1106px;
            width: 28px;
            height: 14px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-next {
        display: block;
        width: 40px;
        height: 20px;
        top: -20%;
        right: 6%;
        background-color: transparent;
        background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
        background-position: -1378px -1082px;
        width: 40px;
        height: 20px;
        color: transparent;
        text-indent: -9999px;
        border: 0;
        cursor: pointer; }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-next:disabled {
          opacity: 0.2;
          cursor: default; }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slider-nav-arrows .slick-next {
            background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
            background-position: -1266px -1106px;
            width: 28px;
            height: 14px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slick-slide {
      padding: 0 8px; }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slick-track {
      height: 380px; }
      @media screen and (max-width: 359px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .slick-track {
          height: 415px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items {
      border: 1px solid #adc0d1;
      border-radius: 12px;
      padding: 4.5% 5.6% 0 11%;
      height: 268px;
      position: relative; }
      @media screen and (max-width: 522px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items {
          height: 325px;
          padding: 7% 5.6% 0 11%; } }
      @media screen and (max-width: 480px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items {
          height: 350px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items {
          height: 345px; } }
      @media screen and (max-width: 359px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items {
          height: 385px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items .field--name-field-body {
        position: relative;
        padding-left: 5px; }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items .field--name-field-body {
            padding-left: 5px;
            font-size: 14px;
            line-height: 24px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items .field--name-field-body:before {
          content: "";
          position: absolute;
          background-image: url(../images/sprites/build/sprites.png?v=1679647820512);
          background-position: -1462px -1082px;
          width: 25px;
          height: 20px;
          left: -7.5%;
          top: 11%; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-testimonial-cards-items .field--name-field-body:before {
              background: url(../images/sprites/quote-small.png) no-repeat center;
              width: 17px;
              height: 13px;
              top: 12px; } }
    .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container {
      display: flex;
      align-items: center;
      position: absolute;
      padding: 0 46px;
      margin-left: 31px;
      background-color: #e7eded;
      z-index: 4;
      bottom: -40px;
      width: 80%;
      left: -70px;
      right: 0px;
      margin: 0 auto; }
      @media screen and (max-width: 550px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container {
          bottom: -30px;
          padding: 0 12px;
          left: 0; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container {
          width: 88%;
          bottom: -27px;
          padding: 0 12px;
          left: 8px; } }
      @media screen and (max-width: 359px) {
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container {
          width: 90%; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image {
        flex-shrink: 0; }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image .field__items .field__item {
          background: linear-gradient(226deg, #1f80db, #52ed93);
          border-radius: 100%;
          padding: 1px; }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image .field__items .field__item {
              padding: 2px; } }
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image .field__items .field__item img {
            border-radius: 100%; }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image .field__items .field__item img {
                width: 65px;
                height: 65px; } }
            @media screen and (max-width: 415px) {
              .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .field--name-field-image .field__items .field__item img {
                width: 50px;
                height: 50px; } }
      .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group {
        flex-direction: column;
        padding-left: 20px;
        max-width: 355px; }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group {
            padding-left: 12px;
            max-width: 195px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-main-title {
          padding: 0; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-main-title {
              width: 100%; }
              .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-main-title h3 {
                font-size: 14px; } }
        .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-secondary-body {
          font-size: 18px;
          padding-bottom: 0;
          line-height: 26px; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-secondary-body {
              width: 95%; }
              .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-secondary-body p {
                font-size: 14px;
                line-height: 18px; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-secondary-body {
              width: 88%; }
              .node-type-accessibility .node--accessibility .field-collection-item-field-testimonials .group-bottom-container .group-test-text-group .field--name-field-secondary-body p {
                font-size: 12px; } }
  .node-type-accessibility .node--accessibility .group-webform-container {
    max-width: 1640px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    border-radius: 12px;
    background: none;
    padding-top: 4%;
    position: relative;
    z-index: 1;
    top: -18vw;
    height: 800px; }
    @media (min-width: 1920px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -340px; } }
    @media (min-width: 2560px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -360px; } }
    @media (min-width: 3000px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -380px; } }
    @media (min-width: 3600px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -410px; } }
    @media (max-width: 1640px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -15vw;
        height: 650px; } }
    @media (max-width: 1500px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        height: 570px; } }
    @media (max-width: 1420px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        height: 620px; } }
    @media (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        height: auto; } }
    @media screen and (max-width: 1640px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        max-width: 98.2%;
        padding: 0 16px;
        margin: 0 auto; } }
    @media screen and (max-width: 1080px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        border-radius: 0;
        max-width: 100%;
        flex-direction: column-reverse;
        margin-bottom: -110px; } }
    @media screen and (max-width: 600px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        margin-bottom: -75px; } }
    @media screen and (max-width: 415px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        margin-bottom: -50px; } }
    @media (max-width: 1400px) {
      .node-type-accessibility .node--accessibility .group-webform-container {
        top: -17vw; } }
    .node-type-accessibility .node--accessibility .group-webform-container .group-title-form {
      margin-left: 8%; }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form {
          max-width: 98.2%;
          padding: 0 16px;
          margin-left: 0; } }
      .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
        width: 674px;
        padding-bottom: 6%;
        padding-top: 5%;
        padding-bottom: 100px; }
        @media screen and (max-width: 1500px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            width: 80%; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            width: 100%; } }
        @media screen and (max-width: 720px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            width: 95%; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            padding-top: 40px; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            width: 100%;
            margin: 0;
            padding-bottom: 6%; }
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title h2 {
              font-size: 22px;
              line-height: 30px; } }
        @media screen and (max-width: 360px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title {
            width: 85%; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow {
          position: relative; }
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow::after {
            content: "";
            position: absolute;
            height: 55px;
            width: 55px;
            background: url(../images/sprites/arrow_03.svg) no-repeat;
            right: -46%;
            top: 38%;
            z-index: 10; }
            @media screen and (max-width: 1358px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow::after {
                width: 50px;
                height: 50px;
                right: -50%;
                top: 34%; } }
            @media screen and (max-width: 1080px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow::after {
                height: 45px;
                width: 45px;
                right: -53%; } }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow::after {
                height: 40px;
                width: 40px;
                right: -52%; } }
            @media screen and (max-width: 415px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .field--name-field-form-title .circle-arrow::after {
                height: 33px;
                width: 33px;
                right: -56%; } }
      .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
        width: 535px; }
        @media screen and (max-width: 1500px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
            width: 80%; } }
        @media screen and (max-width: 1080px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
            margin: 0 auto;
            width: 70%; } }
        @media screen and (max-width: 768px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
            width: 90%; } }
        @media screen and (max-width: 600px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
            width: 95%; } }
        @media screen and (max-width: 415px) {
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form {
            width: 100%; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form_terms_wrapper {
          display: flex;
          align-items: center;
          flex-direction: row-reverse;
          justify-content: flex-end; }
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form_terms_wrapper > label {
            width: 480px;
            padding-left: 3%;
            font-weight: normal !important;
            padding-top: 3% !important; }
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form_terms_wrapper > label > a {
              color: #0c4f8e; }
            @media screen and (max-width: 1640px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form_terms_wrapper > label {
                padding-left: 2%; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item label {
          font-family: "Montserrat", "Arial", sans-serif;
          text-transform: none;
          font-weight: bold;
          font-size: 14px;
          padding-bottom: 10px; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item label {
              padding-bottom: 7px; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item label {
              margin-top: 10px;
              font-size: 12px; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-text,
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-textarea {
          border: 1px solid #939393;
          border-radius: 12px;
          background-color: #ffffff;
          height: 60px; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-text,
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-textarea {
              height: 48px; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-textarea {
          min-height: 90px; }
          @media screen and (max-width: 600px) {
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-textarea {
              min-height: 96px; } }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-type-checkbox {
          display: inline-block; }
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-type-checkbox input[type="checkbox"] {
            height: 31px;
            width: 31px;
            border-radius: 8px;
            border: 1px solid #0c4f8e;
            margin: 0;
            cursor: pointer;
            opacity: 1;
            background-color: transparent;
            -webkit-appearance: none;
            -moz-appearance: none;
            -o-appearance: none; }
            .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-item .form-type-checkbox input[type="checkbox"]:checked {
              background: url(../images/sprites/check-icon.png) no-repeat center; }
        .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-actions {
          position: relative; }
          .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-actions > input {
            height: 60px;
            background-color: #0c4f8e;
            color: #ffffff;
            border-radius: 8px;
            font-size: 16px; }
            @media screen and (max-width: 600px) {
              .node-type-accessibility .node--accessibility .group-webform-container .group-title-form .webform-client-form .form-actions > input {
                height: 48px;
                font-size: 12px; } }
    .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
      position: relative;
      right: calc((-100vw + 1640px) / 2);
      width: 54vw;
      margin-right: -4vw;
      margin-left: 0; }
      @media screen and (max-width: 1640px) {
        .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
          right: 0; } }
      @media screen and (max-width: 1080px) {
        .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
          position: static;
          width: 95vw;
          margin-left: 26.5%;
          margin-top: -230px; } }
      @media screen and (max-width: 768px) {
        .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
          width: 100vw;
          margin-left: 19.5%;
          margin-top: -132px; } }
      @media screen and (max-width: 600px) {
        .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
          width: 100vw;
          margin-left: 20.5%;
          margin-top: -115px; } }
      @media screen and (max-width: 415px) {
        .node-type-accessibility .node--accessibility .group-webform-container .field--name-field-webform-image {
          margin-left: 13.5%;
          width: 100vw;
          margin-top: -75px;
          margin-bottom: 5px; } }
  .node-type-accessibility .node--accessibility .field--name-field-footer {
    max-width: 1640px;
    margin: 0 auto;
    margin-top: -6%; }
    @media screen and (max-width: 1640px) {
      .node-type-accessibility .node--accessibility .field--name-field-footer {
        max-width: 98.2%;
        padding: 0 16px;
        margin: 0 auto; } }
    @media (max-width: 850px) {
      .node-type-accessibility .node--accessibility .field--name-field-footer {
        margin-top: 50px; } }
    .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content {
      display: flex;
      justify-content: space-between;
      text-decoration: none;
      height: 122px;
      font-size: 14px; }
      @media screen and (max-width: 850px) {
        .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content {
          flex-direction: column; } }
      .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-left: 138px; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left {
            margin-left: 0; } }
        @media screen and (max-width: 850px) {
          .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left {
            flex-direction: column;
            margin: 0 auto; } }
        .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-copyright {
          margin-right: 20px;
          font-weight: bold; }
          @media screen and (max-width: 850px) {
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-copyright {
              margin: 0 0 20px 0; } }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-copyright .field__items .field__item {
              font-size: 14px; } }
        .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items {
          display: flex; }
          @media screen and (max-width: 850px) {
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items {
              flex-direction: column; } }
          .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item {
            margin-right: 20px;
            font-weight: normal; }
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item:last-child {
              margin-right: 0; }
            @media screen and (max-width: 850px) {
              .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item {
                margin: 0 0 20px 0;
                text-align: center; } }
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a {
              text-decoration: none;
              color: #000000; }
              .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a:hover, .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a:visited, .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a:link, .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a:active {
                text-decoration: none; }
              @media screen and (max-width: 1080px) {
                .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-left .field--name-field-footer-links .field__items > .field__item > a {
                  font-size: 14px; } }
      .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin-right: 138px; }
        @media screen and (max-width: 1640px) {
          .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right {
            margin-right: 0; } }
        @media screen and (max-width: 850px) {
          .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right {
            margin: 30px auto;
            padding-bottom: 21px; } }
        .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right .field--name-field-footer-text {
          margin-right: 8px;
          font-weight: bold; }
          @media screen and (max-width: 1080px) {
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right .field--name-field-footer-text .field__item {
              font-size: 14px; } }
          @media screen and (max-width: 415px) {
            .node-type-accessibility .node--accessibility .field--name-field-footer .field-collection-item-field-footer > .content .group-footer-right .field--name-field-footer-text .field__item {
              font-size: 10px; } }

.show-item-custom {
  display: block !important; }

.hide--element {
  display: none !important; }

.status-open {
  border: 0px !important;
  border-radius: 36px 36px 0 0 !important; }
  @media (max-width: 500px) {
    .status-open {
      border-radius: 12px 12px 0 0 !important; } }

.page-node-250 {
  scroll-behavior: smooth; }
  .page-node-250 .l-header {
    padding: 0 20px; }
    @media (max-width: 1600px) {
      .page-node-250 .l-header {
        padding: 0px; } }
  .page-node-250 #set-height {
    display: block; }
  .page-node-250 .selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor {
    background: #7D2B6D;
    color: white !important; }
  .page-node-250 .selectboxit.selectboxit-focus {
    border: 1px solid #7D2B6D; }
  .page-node-250 .selectboxit-option-anchor {
    text-decoration: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #000 !important; }
  .page-node-250 #rq-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 1060px;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    pointer-events: none;
    overflow: hidden; }
    @media (max-width: 500px) {
      .page-node-250 #rq-canvas {
        min-height: 700px; } }
  .page-node-250 p font-family helvetica {
    font-size: 24px; }
  .page-node-250 * {
    font-family: 'Roboto',sans-serif !important; }
  .page-node-250 .hero-section {
    position: relative;
    overflow: hidden;
    height: 980px; }
    @media (max-width: 800px) {
      .page-node-250 .hero-section {
        height: 700px; } }
    .page-node-250 .hero-section--body {
      top: 0;
      left: 0;
      width: 100%;
      position: fixed;
      height: 1060px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center; }
      @media (max-width: 800px) {
        .page-node-250 .hero-section--body {
          height: 760px; } }
      @media (max-width: 500px) {
        .page-node-250 .hero-section--body {
          height: 700px; } }
    .page-node-250 .hero-section .large-text {
      margin-bottom: 20px; }
      @media (max-width: 500px) {
        .page-node-250 .hero-section .large-text {
          margin-bottom: 0px; } }
    .page-node-250 .hero-section .grey-layer {
      background: #000;
      opacity: .4;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0; }
    .page-node-250 .hero-section #scroll-down {
      z-index: 2;
      position: absolute;
      bottom: 60px;
      right: 0;
      left: 0;
      margin: 0 auto; }
      @media (max-width: 500px) {
        .page-node-250 .hero-section #scroll-down {
          bottom: 40px; } }
    .page-node-250 .hero-section--text {
      z-index: 2;
      margin-bottom: -75px; }
  .page-node-250 .promo-page .group-promo-hero {
    display: none; }
  @media (max-width: 920px) {
    .page-node-250 .promo-page #promo-mailchimp .mergeRow input {
      height: 58px; } }
  @media (max-width: 500px) {
    .page-node-250 .promo-page #promo-mailchimp .mergeRow input {
      border-radius: 12px; } }
  @media (max-width: 500px) {
    .page-node-250 .promo-page #research-newsletter .checkmark {
      margin-right: 0px;
      flex-shrink: 0; } }
  .page-node-250 .promo-page #research-newsletter .terms-text {
    font-size: 14px;
    line-height: 19px; }
    @media (max-width: 425px) {
      .page-node-250 .promo-page #research-newsletter .terms-text {
        width: 85%; } }
  @media (max-width: 500px) {
    .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__label {
      margin-top: 28px;
      margin-bottom: 80px;
      font-size: 24px;
      line-height: 32px; } }
  @media (max-width: 500px) {
    .page-node-250 .promo-page .field--name-field-whats-for-you-floating-img.anim {
      transform: translate(-125%, -33%); } }
  .page-node-250 .promo-page #promo-mailchimp {
    flex-wrap: wrap; }
    .page-node-250 .promo-page #promo-mailchimp .selectboxit-container {
      max-width: 260px;
      width: 100%; }
      @media (max-width: 920px) {
        .page-node-250 .promo-page #promo-mailchimp .selectboxit-container {
          max-width: 400px; } }
      .page-node-250 .promo-page #promo-mailchimp .selectboxit-container .selectboxit-text {
        color: #747474;
        font-weight: 500; }
      .page-node-250 .promo-page #promo-mailchimp .selectboxit-container .selectboxit {
        background: white;
        width: 260px;
        width: 100%;
        height: 58px;
        border-radius: 60px;
        display: flex;
        align-items: center;
        padding: 0 22px; }
        @media (max-width: 500px) {
          .page-node-250 .promo-page #promo-mailchimp .selectboxit-container .selectboxit {
            padding: 0 10px;
            border-radius: 12px; } }
    .page-node-250 .promo-page #promo-mailchimp .selectboxit-options {
      background: white;
      border-radius: 0 0 36px 36px;
      padding: 30px 24px; }
      @media (max-width: 500px) {
        .page-node-250 .promo-page #promo-mailchimp .selectboxit-options {
          border-radius: 0 0 12px 12px; } }
    .page-node-250 .promo-page #promo-mailchimp select {
      display: none !important; }
    .page-node-250 .promo-page #promo-mailchimp #mergeTable {
      flex-shrink: 0; }
      @media (max-width: 1180px) {
        .page-node-250 .promo-page #promo-mailchimp #mergeTable {
          width: 100%;
          padding: 0 20px; } }
      @media (max-width: 920px) {
        .page-node-250 .promo-page #promo-mailchimp #mergeTable {
          padding: 0px; } }
      @media (max-width: 500px) {
        .page-node-250 .promo-page #promo-mailchimp #mergeTable {
          padding: 0 20px; } }
      .page-node-250 .promo-page #promo-mailchimp #mergeTable .field-group {
        margin-top: 12px; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page #promo-mailchimp .mergeRow input {
        padding: 0 16px; } }
  .page-node-250 .promo-page .first-option {
    font-size: 16px;
    color: #747474;
    font-weight: 500; }
  .page-node-250 .promo-page #mergeRow-2 {
    max-width: 260px !important; }
    @media (max-width: 920px) {
      .page-node-250 .promo-page #mergeRow-2 {
        margin-top: 20px;
        max-width: 400px !important; } }
    .page-node-250 .promo-page #mergeRow-2 select {
      padding: 0 16px; }
  .page-node-250 .promo-page #research-newsletter .form-type-checkbox {
    max-width: 437px;
    overflow-wrap: break-word;
    width: 100%; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page #research-newsletter .form-type-checkbox {
        padding: 0 20px; } }
    .page-node-250 .promo-page #research-newsletter .form-type-checkbox label {
      margin-left: 14px; }
  .page-node-250 .promo-page .group-how-it-works-images {
    position: relative;
    max-width: 536px;
    width: 100%; }
  .page-node-250 .promo-page .field--name-field-how-it-works-lottie {
    position: relative;
    display: inline-block;
    position: absolute;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center; }
    .page-node-250 .promo-page .field--name-field-how-it-works-lottie::before {
      content: '';
      display: block;
      position: absolute;
      background: white;
      border-radius: 50%;
      opacity: 0.7;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      width: 108px;
      height: 108px;
      z-index: 3; }
      @media (max-width: 500px) {
        .page-node-250 .promo-page .field--name-field-how-it-works-lottie::before {
          width: 68px;
          height: 68px; } }
  .page-node-250 .promo-page .field--name-field-how-it-works-main-image {
    width: 100%; }
  @media (max-width: 1260px) {
    .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .field-group-html-element:nth-child(2) {
      margin-top: -330px; } }
  @media (max-width: 1020px) {
    .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .field-group-html-element:nth-child(2) {
      margin-top: -375px; } }
  @media (max-width: 850px) {
    .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .field-group-html-element:nth-child(2) {
      margin-top: -435px; } }
  @media (max-width: 700px) {
    .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .field-group-html-element:nth-child(2) {
      margin-top: -105px; } }
  .page-node-250 .promo-page .field--name-field-promo-info-lottie {
    width: 100px;
    position: absolute;
    left: 11px;
    top: 27px; }
  .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group {
    max-width: 537px;
    padding: 70px 0px 0px 20px; }
    @media (max-width: 1360px) {
      .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group {
        max-width: 510px;
        padding-right: 40px; } }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group {
        padding: 0px 0px 0px 20px;
        max-width: 100%; } }
    .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group h2 {
      font-size: 36px;
      font-weight: 900; }
      @media (max-width: 850px) {
        .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group h2 {
          font-size: 24px; } }
    .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group p {
      font-weight: 400;
      font-size: 24px; }
      @media (max-width: 850px) {
        .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group p {
          font-size: 20px;
          line-height: 30px; } }
  .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group p:last-of-type {
    max-width: 465px;
    font-size: 22px; }
    @media (max-width: 850px) {
      .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group p:last-of-type {
        font-size: 18px;
        line-height: 28px; } }
  .page-node-250 .promo-page .lottie-img {
    z-index: 10;
    position: relative; }
  .page-node-250 .promo-page .field-collection-container .field-items .field-item {
    margin: 0px !important; }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(1) .field--name-field-how-it-works-lottie {
    top: 166px;
    right: 152px; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(1) .field--name-field-how-it-works-lottie {
        top: 30%;
        right: 30%; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(1) .lottie-img {
    width: 92px;
    height: 92px; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(1) .lottie-img {
        width: 58px;
        height: 58px; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(2) .field--name-field-how-it-works-lottie {
    top: 136px;
    right: 28px; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(2) .field--name-field-how-it-works-lottie {
        top: 23%;
        right: 15%; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(2) .lottie-img {
    width: 168px;
    height: 126px; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(2) .lottie-img {
        width: 104px;
        height: 78px; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(3) .field--name-field-how-it-works-lottie {
    top: 159px;
    right: 79px; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(3) .field--name-field-how-it-works-lottie {
        top: 32%;
        right: 20%; } }
    .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(3) .field--name-field-how-it-works-lottie::before {
      content: '';
      display: block;
      position: absolute;
      background: white;
      border-radius: 50%;
      opacity: 0.7;
      backdrop-filter: blur(5px);
      -webkit-backdrop-filter: blur(5px);
      width: 120px;
      height: 120px;
      z-index: 3; }
      @media (max-width: 500px) {
        .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(3) .field--name-field-how-it-works-lottie::before {
          width: 68px;
          height: 68px; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(3) .lottie-img {
    width: 82px;
    height: 82px; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__items .field__item:nth-child(3) .lottie-img {
        width: 46px;
        height: 46px; } }
  .page-node-250 .promo-page .mailchimp-label {
    margin-left: 30px;
    font-size: 16px;
    line-height: 21px;
    font-weight: 700; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .mailchimp-label {
        margin-left: 16px; } }
  .page-node-250 .promo-page .submit_container {
    display: flex;
    width: 100%; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .submit_container {
        padding: 0 50px; } }
  .page-node-250 .promo-page #research-newsletter h2 {
    font-size: 52px;
    line-height: 64px;
    font-weight: 900; }
    @media (max-width: 1100px) {
      .page-node-250 .promo-page #research-newsletter h2 {
        font-size: 44px;
        line-height: 52px; } }
    @media (max-width: 900px) {
      .page-node-250 .promo-page #research-newsletter h2 {
        font-size: 34px;
        line-height: 42px; } }
    @media (max-width: 700px) {
      .page-node-250 .promo-page #research-newsletter h2 {
        font-size: 28px;
        line-height: 36px; } }
    @media (max-width: 500px) {
      .page-node-250 .promo-page #research-newsletter h2 {
        font-size: 24px;
        line-height: 32px; } }
  .page-node-250 .promo-page .node--promo-page {
    z-index: 1;
    position: relative; }
  .page-node-250 .promo-page #research-newsletter {
    z-index: 1;
    position: relative;
    padding-bottom: 80px; }
  .page-node-250 .promo-page .selectboxit-default-arrow {
    width: 0;
    height: 0;
    border-top: 6px solid #000000;
    border-right: 6px solid transparent;
    border-left: 6px solid transparent; }
  .page-node-250 .promo-page .selectboxit-arrow-container {
    right: 15px; }
  .page-node-250 .promo-page #promo-mailchimp input[disabled=''][type=submit] {
    color: #6b6b6b; }
    .page-node-250 .promo-page #promo-mailchimp input[disabled=''][type=submit]:hover {
      color: #6b6b6b; }
  .page-node-250 .promo-page footer {
    background: white;
    z-index: 1;
    position: relative;
    padding-right: 40px;
    padding-left: 40px; }
    @media (max-width: 1140px) {
      .page-node-250 .promo-page footer {
        padding: 60px 40px; } }
    @media (max-width: 500px) {
      .page-node-250 .promo-page footer {
        padding: 20px; } }
    @media (max-width: 500px) {
      .page-node-250 .promo-page footer .design-by {
        margin: 0 auto; } }
    .page-node-250 .promo-page footer .policy-links a {
      text-decoration: none; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page footer .illustrations {
        display: flex;
        flex-direction: column; } }
  .page-node-250 .promo-page .promo-menu #join {
    background: #7D2B6D;
    color: white; }
    .page-node-250 .promo-page .promo-menu #join:hover {
      background: #A22E8B; }
  .page-node-250 .promo-page .promo-menu a:hover {
    color: #7D2B6D; }
  .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you {
    margin-top: 261px; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-whats-in-it-for-you {
        margin-top: 186px; } }
  @media (max-width: 500px) {
    .page-node-250 .promo-page .field--name-field-whats-for-you-main-image img {
      position: absolute;
      right: -30px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      height: 300px; } }
  .page-node-250 .promo-page .group-promo-info {
    max-width: 1364px;
    border-radius: 20px;
    border: 1px solid #DEDEDE;
    padding: 37px 166px 30px 138px;
    margin: 80px auto 0px auto;
    position: relative; }
    @media (max-width: 1370px) {
      .page-node-250 .promo-page .group-promo-info {
        margin: 80px 40px 0px 40px; } }
    @media (max-width: 700px) {
      .page-node-250 .promo-page .group-promo-info {
        margin: 80px 20px 0px 20px;
        padding: 124px 22px 40px 22px; } }
    .page-node-250 .promo-page .group-promo-info .field__item::before {
      content: '';
      display: block;
      background: url("../images/icon-info.png");
      position: absolute;
      width: 8px;
      height: 20px;
      top: 52px;
      left: 57px; }
    .page-node-250 .promo-page .group-promo-info p:nth-child(1) {
      margin-bottom: 12px;
      font-size: 24px;
      line-height: 36px;
      font-weight: 400; }
      @media (max-width: 500px) {
        .page-node-250 .promo-page .group-promo-info p:nth-child(1) {
          font-size: 20px;
          line-height: 30px; } }
    .page-node-250 .promo-page .group-promo-info p:nth-child(2) {
      font-size: 28px;
      line-height: 36px;
      font-weight: 700; }
      @media (max-width: 500px) {
        .page-node-250 .promo-page .group-promo-info p:nth-child(2) {
          font-size: 24px;
          line-height: 32px;
          font-weight: 900; } }
  .page-node-250 .promo-page .field--name-field-promo-hero-text h1 {
    font-size: 36px;
    font-weight: 500;
    line-height: 55px;
    color: white; }
    @media (max-width: 900px) {
      .page-node-250 .promo-page .field--name-field-promo-hero-text h1 {
        font-size: 32px;
        line-height: 44px; } }
    @media (max-width: 700px) {
      .page-node-250 .promo-page .field--name-field-promo-hero-text h1 {
        font-size: 28px;
        line-height: 38px; } }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-hero-text h1 {
        font-size: 24px;
        line-height: 32px; } }
    .page-node-250 .promo-page .field--name-field-promo-hero-text h1 span {
      width: 100%;
      display: block;
      font-size: 128px;
      font-weight: 900;
      line-height: 1;
      color: white; }
      @media (max-width: 1120px) {
        .page-node-250 .promo-page .field--name-field-promo-hero-text h1 span {
          font-size: 80px; } }
      @media (max-width: 1020px) {
        .page-node-250 .promo-page .field--name-field-promo-hero-text h1 span {
          font-size: 60px; } }
      @media (max-width: 900px) {
        .page-node-250 .promo-page .field--name-field-promo-hero-text h1 span {
          font-size: 50px; } }
      @media (max-width: 700px) {
        .page-node-250 .promo-page .field--name-field-promo-hero-text h1 span {
          font-size: 40px; } }
      @media (max-width: 500px) {
        .page-node-250 .promo-page .field--name-field-promo-hero-text h1 span {
          font-size: 36px; } }
  .page-node-250 .promo-page .field--name-field--promo-hero-link {
    margin-top: 52px; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field--promo-hero-link {
        margin-top: 36px; } }
    .page-node-250 .promo-page .field--name-field--promo-hero-link .field__item {
      display: flex;
      justify-content: center; }
    .page-node-250 .promo-page .field--name-field--promo-hero-link a {
      border-radius: 60px;
      max-width: 260px;
      font-size: 16px;
      line-height: 1;
      font-weight: 800;
      width: 100%;
      background: white;
      color: #222222;
      padding: 18px 41px;
      text-decoration: none;
      display: flex;
      justify-content: center; }
  .page-node-250 .promo-page .field--name-field-how-it-works-main-title-la {
    max-width: 536px;
    font-size: 24px;
    line-height: 34px;
    font-weight: 400; }
    @media (max-width: 900px) {
      .page-node-250 .promo-page .field--name-field-how-it-works-main-title-la {
        font-size: 22px;
        line-height: 30px; } }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-how-it-works-main-title-la {
        font-size: 18px;
        line-height: 26px; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item::before {
    display: none !important; }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:first-child .group-how-it-works-text {
    max-width: 536px;
    width: 100%;
    margin: 0px 0 0 154px; }
    @media (max-width: 1360px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:first-child .group-how-it-works-text {
        margin: 0px 100px; } }
    @media (max-width: 1024px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:first-child .group-how-it-works-text {
        margin: 20px 0 0 0px; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-main-image .field__item img {
    margin-left: 0px;
    height: auto; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-main-image .field__item img {
        width: 100%; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .group-how-it-works-text {
    max-width: 536px;
    width: 100%;
    margin: 0px auto 0 auto; }
    @media (max-width: 1360px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .group-how-it-works-text {
        margin: 0px 100px; } }
    @media (max-width: 1024px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .group-how-it-works-text {
        margin: 20px auto 0 auto; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-main-image .field__item img {
    margin-left: 0px;
    height: auto; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-main-image .field__item img {
        width: 100%; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-pre-title {
    color: #7D2B6D; }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .group-how-it-works-text {
    max-width: 536px;
    width: 100%;
    margin: 0px 0 0 154px; }
    @media (max-width: 1360px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .group-how-it-works-text {
        margin: 0px 100px; } }
    @media (max-width: 1024px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .group-how-it-works-text {
        margin: 20px 0 0 0px; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-main-image .field__item img {
    margin-left: 0px;
    height: auto; }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-main-image .field__item img {
        width: 100%; } }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-pre-title {
    color: #7D2B6D; }
  .page-node-250 .promo-page .field--name-field-promo-how-it-works .field__label {
    margin-top: 24px;
    margin-bottom: 120px;
    padding: 0 20px; }
  .page-node-250 .promo-page .field--name-body {
    margin: 0px auto 0px auto;
    padding-top: 120px;
    max-width: 1110px;
    font-size: 28px;
    font-weight: 400;
    line-height: 44px;
    font-family: 'Roboto',sans-serif; }
    @media (max-width: 900px) {
      .page-node-250 .promo-page .field--name-body {
        font-size: 24px;
        line-height: 34px; } }
    @media (max-width: 500px) {
      .page-node-250 .promo-page .field--name-body {
        font-size: 20px;
        line-height: 30px; } }

.promo-page .form-label {
  padding-left: 25px;
  padding-bottom: 9px;
  display: inline-block;
  font-weight: bold;
  color: #4F4F4F;
  font-size: 17px; }

.promo-page .validation-msg {
  position: absolute;
  top: -25px;
  left: 20px;
  font-size: 12px;
  color: red; }
  .promo-page .validation-msg.inactive {
    left: -999999px; }

.promo-page h1 {
  font-size: 68px;
  font-weight: bold;
  line-height: 78px;
  letter-spacing: -1.36px;
  font-family: "Montserrat", sans-serif; }
  .promo-page h1 .yellow {
    color: #7D2B6D; }

@media (max-width: 550px) {
  .promo-page .l-header {
    height: 60px; } }

.promo-page .node--full .node__content {
  background: white; }
  @media (max-width: 1024px) {
    .promo-page .node--full .node__content {
      width: 100%;
      margin: 0 auto; } }

.promo-page .success-msg {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 50px; }
  @media (max-width: 450px) {
    .promo-page .success-msg {
      flex-direction: column;
      text-align: center; } }
  .promo-page .success-msg .message {
    font-size: 22px;
    line-height: 30px;
    margin-left: 10px; }

.promo-page .aln-cnt {
  align-items: center; }

.promo-page .text.no-dec {
  text-decoration: none; }

.promo-page .text.black {
  color: black; }

.promo-page .text.ln-26 {
  line-height: 26px; }

.promo-page .text.s16 {
  font-size: 16px; }

.promo-page .text.mont {
  font-family: "Montserrat", san-serif; }

.promo-page .text.bold {
  font-weight: bold; }

.promo-page .link-reset:active, .promo-page .link-reset:visited {
  text-decoration: none;
  color: unset; }

.promo-page .mg-r--10 {
  margin-right: 10px; }

.promo-page .mg-r--60 {
  margin-right: 60px; }
  @media (max-width: 1024px) {
    .promo-page .mg-r--60 {
      margin-right: 30px; } }

.promo-page .mg-l--20 {
  margin-left: 20px; }

.promo-page .mg-l--79 {
  margin-left: 79px; }

.promo-page .cta-yellow {
  background: #FFC132;
  cursor: pointer; }
  @media (min-width: 1024px) {
    .promo-page .cta-yellow:hover {
      background: #000;
      color: white; } }

.promo-page .cta-purple {
  max-width: 398px;
  width: 100%;
  margin: 40px auto 0 auto;
  border: 0px;
  font-size: 16px;
  font-weight: 700;
  background: #7D2B6D;
  color: white;
  cursor: pointer; }
  .promo-page .cta-purple:hover {
    background: #A22E8B; }

.promo-page .cta.rounded {
  border-radius: 36px;
  padding: 20px 32px; }

@media (max-width: 1024px) {
  .promo-page .promo-menu .cta.rounded {
    padding: 10px 16px; } }

@media (max-width: 960px) {
  .promo-page .promo-menu .cta.rounded {
    font-size: 12px; } }

@media (max-width: 360px) {
  .promo-page .promo-menu .cta.rounded {
    padding: 12px 16px;
    font-size: 10px; } }

.promo-page .logo {
  margin-right: auto; }
  @media (max-width: 960px) {
    .promo-page .logo {
      width: 150px; } }
  @media (max-width: 360px) {
    .promo-page .logo {
      width: 130px; } }

.promo-page .promo-menu {
  display: flex; }
  @media (max-width: 960px) {
    .promo-page .promo-menu li {
      display: none; }
      .promo-page .promo-menu li:last-child {
        display: block; } }

.promo-page .group-promo-hero {
  padding-top: 80px;
  border-bottom-left-radius: 60px;
  border-bottom-right-radius: 60px;
  background: #FFE7E7;
  position: relative;
  overflow: hidden; }
  .promo-page .group-promo-hero-inner {
    max-width: 1120px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center; }
  @media (max-width: 700px) {
    .promo-page .group-promo-hero {
      padding-top: 30px; } }
  @media (max-width: 450px) {
    .promo-page .group-promo-hero {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0; } }

.promo-page .yellow {
  color: #FFC132; }

.promo-page .field--name-field-promo-hero-text h1 {
  text-align: center;
  font-size: 68px;
  font-weight: bold;
  line-height: 78px;
  letter-spacing: -1.36px;
  margin-bottom: 22px; }
  @media (max-width: 1024px) {
    .promo-page .field--name-field-promo-hero-text h1 {
      font-size: 50px;
      line-height: 58px;
      padding: 0 10px; } }
  @media (max-width: 850px) {
    .promo-page .field--name-field-promo-hero-text h1 {
      font-size: 40px;
      line-height: 48px;
      letter-spacing: -0.6px;
      padding: 0 10px; } }
  @media (max-width: 500px) {
    .promo-page .field--name-field-promo-hero-text h1 {
      font-size: 30px;
      line-height: 38px; } }

.promo-page .field--name-field-promo-hero-image {
  position: relative; }
  @media (max-width: 450px) {
    .promo-page .field--name-field-promo-hero-image .field__items img {
      height: 435px;
      object-fit: cover; } }
  .promo-page .field--name-field-promo-hero-image img {
    display: block;
    z-index: 2;
    position: relative; }
  .promo-page .field--name-field-promo-hero-image:before {
    content: "";
    width: 730px;
    height: 730px;
    background: #F6D7D7;
    z-index: 1;
    display: block;
    border-radius: 50%;
    position: absolute;
    top: 52%;
    left: 50%;
    transform: translate(-50%, -50%); }
    @media (max-width: 1024px) {
      .promo-page .field--name-field-promo-hero-image:before {
        width: 600px;
        height: 600px; } }
    @media (max-width: 850px) {
      .promo-page .field--name-field-promo-hero-image:before {
        width: 500px;
        height: 500px; } }
    @media (max-width: 700px) {
      .promo-page .field--name-field-promo-hero-image:before {
        width: 400px;
        height: 400px; } }
    @media (max-width: 600px) {
      .promo-page .field--name-field-promo-hero-image:before {
        width: 300px;
        height: 300px; } }
    @media (max-width: 400px) {
      .promo-page .field--name-field-promo-hero-image:before {
        width: 300px;
        height: 300px; } }

.promo-page .field--name-field-promo-floating-image-left {
  position: absolute;
  top: 368px;
  left: 168px;
  opacity: 1; }
  .promo-page .field--name-field-promo-floating-image-left.anim {
    opacity: 1;
    top: 278px; }
  @media (max-width: 1450px) {
    .promo-page .field--name-field-promo-floating-image-left {
      left: 0; } }
  @media (max-width: 1110px) {
    .promo-page .field--name-field-promo-floating-image-left {
      width: 170px; } }
  @media (max-width: 850px) {
    .promo-page .field--name-field-promo-floating-image-left {
      display: none; } }

.promo-page .field--name-field-promo-floating-image-right {
  position: absolute;
  top: 866px;
  right: 213px;
  opacity: 1; }
  .promo-page .field--name-field-promo-floating-image-right.anim {
    top: 866px;
    opacity: 1; }
  @media (max-width: 1450px) {
    .promo-page .field--name-field-promo-floating-image-right {
      right: 0; } }
  @media (max-width: 1024px) {
    .promo-page .field--name-field-promo-floating-image-right {
      top: 666px; } }
  @media (max-width: 850px) {
    .promo-page .field--name-field-promo-floating-image-right {
      display: none; } }

.promo-page .field--name-field-promo-how-it-works {
  margin: 0 auto;
  max-width: 1360px;
  width: 100%; }
  .promo-page .field--name-field-promo-how-it-works .field__label {
    font-size: 36px;
    line-height: 55px;
    text-align: center;
    margin-top: 16px;
    color: #7D2B6D;
    margin-bottom: 80px; }
    @media (max-width: 600px) {
      .promo-page .field--name-field-promo-how-it-works .field__label {
        font-size: 30px;
        margin-bottom: 40px; } }
  .promo-page .field--name-field-promo-how-it-works .content {
    display: flex;
    align-items: center;
    margin-bottom: 20px; }
    @media (max-width: 1024px) {
      .promo-page .field--name-field-promo-how-it-works .content {
        flex-direction: column;
        margin-bottom: 60px; } }
  .promo-page .field--name-field-promo-how-it-works .field__item.odd .content {
    flex-direction: row-reverse; }
    @media (max-width: 1024px) {
      .promo-page .field--name-field-promo-how-it-works .field__item.odd .content {
        flex-direction: column; } }
  .promo-page .field--name-field-promo-how-it-works .field__item .field--name-field-how-it-works-main-image img {
    object-fit: cover;
    object-position: top;
    height: 545px;
    bottom: 12px; }
    @media (max-width: 1200px) {
      .promo-page .field--name-field-promo-how-it-works .field__item .field--name-field-how-it-works-main-image img {
        height: 445px; } }
    @media (max-width: 500px) {
      .promo-page .field--name-field-promo-how-it-works .field__item .field--name-field-how-it-works-main-image img {
        height: 345px;
        width: 300px;
        margin: 0 auto !important; } }
  .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-pre-title {
    color: #7D2B6D; }
  .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-floating-img {
    position: absolute;
    right: -10px;
    top: 235px;
    z-index: 2;
    opacity: 0;
    transition: all 1s; }
    .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-floating-img.anim {
      top: 135px;
      opacity: 1; }
      @media (max-width: 500px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-floating-img.anim {
          top: 65px;
          width: 90px;
          right: 10px; } }
      @media (max-width: 400px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-floating-img.anim {
          width: 75px;
          right: 20px; } }
    @media (max-width: 500px) {
      .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-floating-img {
        top: 235px;
        width: 90px;
        right: 10px; } }
  .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-main-image .field__item {
    position: relative; }
    .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-main-image .field__item img {
      position: relative;
      z-index: 2;
      display: block;
      margin-left: 92px; }
      @media (max-width: 1200px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-main-image .field__item img {
          margin-left: 22px; } }
    .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-main-image .field__item:before {
      content: "";
      z-index: 1;
      position: absolute;
      bottom: 12px;
      left: 0;
      width: 536px;
      height: 480px;
      background: #F5E7FF;
      border-radius: 20px; }
      @media (max-width: 1200px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-main-image .field__item:before {
          width: 445px; } }
      @media (max-width: 1024px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-main-image .field__item:before {
          height: 380px;
          width: 100%; } }
      @media (max-width: 500px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:first-child .field--name-field-how-it-works-main-image .field__item:before {
          height: 285px; } }
  .promo-page .field--name-field-promo-how-it-works .field__item:first-child .group-how-it-works-text {
    margin-left: 94px;
    margin-right: 94px; }
    @media (max-width: 1024px) {
      .promo-page .field--name-field-promo-how-it-works .field__item:first-child .group-how-it-works-text {
        margin-left: 0;
        margin-right: 0;
        margin-top: 25px; } }
  .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-pre-title {
    color: #145085; }
  .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-floating-img {
    position: absolute;
    right: -85px;
    top: 135px;
    z-index: 2; }
  .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-main-image .field__item {
    position: relative; }
    .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-main-image .field__item img {
      position: relative;
      z-index: 2;
      display: block;
      margin-left: -40px; }
      @media (max-width: 1024px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-main-image .field__item img {
          margin-left: 0; } }
    .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-main-image .field__item:before {
      content: "";
      z-index: 1;
      position: absolute;
      bottom: 12px;
      right: 0;
      width: 536px;
      height: 480px;
      background: #E7EEFF;
      border-radius: 20px; }
      @media (max-width: 1200px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-main-image .field__item:before {
          width: 445px; } }
      @media (max-width: 1024px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-main-image .field__item:before {
          height: 380px;
          width: 100%; } }
      @media (max-width: 500px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .field--name-field-how-it-works-main-image .field__item:before {
          height: 285px; } }
  .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .group-how-it-works-text {
    margin-right: 154px;
    margin-left: 154px; }
    @media (max-width: 1024px) {
      .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(2) .group-how-it-works-text {
        margin-left: 0;
        margin-right: 0;
        margin-top: 25px; } }
  .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-pre-title {
    color: #20652A; }
  .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-floating-img {
    position: absolute;
    right: -85px;
    top: 135px;
    z-index: 2; }
  .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-main-image .field__item {
    position: relative; }
    .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-main-image .field__item img {
      position: relative;
      z-index: 2;
      display: block;
      margin-left: 92px; }
      @media (max-width: 1200px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-main-image .field__item img {
          margin-left: 22px; } }
    .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-main-image .field__item:before {
      content: "";
      z-index: 1;
      position: absolute;
      bottom: 12px;
      left: 0;
      width: 536px;
      height: 480px;
      background: #E1F4DB;
      border-radius: 20px; }
      @media (max-width: 1200px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-main-image .field__item:before {
          width: 445px; } }
      @media (max-width: 1024px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-main-image .field__item:before {
          height: 380px;
          width: 100%; } }
      @media (max-width: 500px) {
        .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .field--name-field-how-it-works-main-image .field__item:before {
          height: 285px; } }
  .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .group-how-it-works-text {
    margin-left: 94px;
    margin-right: 94px; }
    @media (max-width: 1024px) {
      .promo-page .field--name-field-promo-how-it-works .field__item:nth-child(3) .group-how-it-works-text {
        margin-left: 0;
        margin-right: 0;
        margin-top: 25px; } }

.promo-page .field--name-field-how-it-works-pre-title {
  font-size: 32px;
  line-height: 30px;
  letter-spacing: -0.64px;
  margin-bottom: 20px;
  font-weight: bold; }
  @media (max-width: 1200px) {
    .promo-page .field--name-field-how-it-works-pre-title {
      font-size: 30px;
      line-height: 30px; } }
  @media (max-width: 500px) {
    .promo-page .field--name-field-how-it-works-pre-title {
      font-size: 24px;
      line-height: 24px; } }

.promo-page .field--name-field-how-it-works-main-title {
  font-size: 22px;
  line-height: 34px; }
  @media (max-width: 1200px) {
    .promo-page .field--name-field-how-it-works-main-title {
      font-size: 20px;
      line-height: 32px; } }
  @media (max-width: 500px) {
    .promo-page .field--name-field-how-it-works-main-title {
      font-size: 16px;
      line-height: 28px; } }

.promo-page .field--name-field-promo-whats-in-it-for-you {
  background: #EDF5F8;
  margin-top: 370px; }
  @media (max-width: 1400px) {
    .promo-page .field--name-field-promo-whats-in-it-for-you {
      margin-top: 190px; } }
  @media (max-width: 850px) {
    .promo-page .field--name-field-promo-whats-in-it-for-you {
      margin-top: 75px; } }
  .promo-page .field--name-field-promo-whats-in-it-for-you .content {
    display: flex;
    align-items: center;
    max-width: 1360px;
    margin: 0 auto; }
    @media (max-width: 700px) {
      .promo-page .field--name-field-promo-whats-in-it-for-you .content {
        flex-direction: column-reverse;
        margin-top: 0; } }
  .promo-page .field--name-field-promo-whats-in-it-for-you .field-group-html-element {
    width: 50%;
    position: relative;
    margin-top: -180px; }
    @media (max-width: 700px) {
      .promo-page .field--name-field-promo-whats-in-it-for-you .field-group-html-element {
        margin-top: -70px;
        width: 100%; } }
  .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group {
    max-width: 535px;
    width: 100%;
    margin-right: auto;
    margin-top: 30px; }
    @media (max-width: 1580px) {
      .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group {
        padding-left: 20px; } }
    @media (max-width: 700px) {
      .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group {
        max-width: 100%;
        padding-right: 20px; } }
    .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group h2 {
      font-size: 36px;
      line-height: 55px;
      font-weight: bold;
      margin-bottom: 20px;
      font-family: "Montserrat", san-serif; }
      @media (max-width: 960px) {
        .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group h2 {
          font-size: 32px;
          line-height: 51px; } }
      @media (max-width: 500px) {
        .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group h2 {
          font-size: 30px;
          line-height: 35px; } }
    .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group p {
      font-size: 22px;
      line-height: 34px; }
      @media (max-width: 960px) {
        .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group p {
          font-size: 20px;
          line-height: 32px; } }
      @media (max-width: 500px) {
        .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group p {
          font-size: 16px;
          line-height: 28px; } }
      .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group p:last-of-type {
        max-width: 400px; }
        .promo-page .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group p:last-of-type img {
          display: inline-block;
          vertical-align: middle; }

.promo-page .field--name-field-whats-for-you-floating-img {
  position: absolute;
  top: 140px;
  left: 0px;
  opacity: 0;
  transition: all 3s; }
  .promo-page .field--name-field-whats-for-you-floating-img.anim {
    top: 40px;
    left: -40px;
    opacity: 1; }
    @media (max-width: 1100px) {
      .promo-page .field--name-field-whats-for-you-floating-img.anim {
        left: -80px; } }
    @media (max-width: 1024px) {
      .promo-page .field--name-field-whats-for-you-floating-img.anim {
        width: 200px; } }
    @media (max-width: 850px) {
      .promo-page .field--name-field-whats-for-you-floating-img.anim {
        top: 10px;
        width: 160px; } }
    @media (max-width: 700px) {
      .promo-page .field--name-field-whats-for-you-floating-img.anim {
        left: 50%;
        width: 160px;
        transform: translateX(-160%); } }
    @media (max-width: 370px) {
      .promo-page .field--name-field-whats-for-you-floating-img.anim {
        width: 130px; } }
  @media (max-width: 700px) {
    .promo-page .field--name-field-whats-for-you-floating-img {
      left: 50%;
      width: 160px;
      transform: translateX(-140%); } }
  @media (max-width: 370px) {
    .promo-page .field--name-field-whats-for-you-floating-img {
      width: 130px; } }

.promo-page .field--name-field-whats-for-you-main-image {
  width: 812px;
  height: 812px;
  background: #E4ECF0;
  border-radius: 50%;
  position: relative;
  overflow: hidden;
  position: relative; }
  @media (max-width: 1650px) {
    .promo-page .field--name-field-whats-for-you-main-image {
      width: 612px;
      height: 612px;
      margin: 0 auto; } }
  @media (max-width: 1260px) {
    .promo-page .field--name-field-whats-for-you-main-image {
      width: 512px;
      height: 512px; } }
  @media (max-width: 1024px) {
    .promo-page .field--name-field-whats-for-you-main-image {
      width: 412px;
      height: 412px; } }
  @media (max-width: 850px) {
    .promo-page .field--name-field-whats-for-you-main-image {
      width: 320px;
      height: 320px; } }
  .promo-page .field--name-field-whats-for-you-main-image img {
    position: absolute;
    right: -30px;
    top: 45%;
    transform: translateY(-50%); }

.promo-page .field--name-body {
  max-width: 1090px;
  margin: 150px auto 1px auto;
  font-size: 30px;
  line-height: 46px;
  font-family: "Montserrat", san-serif;
  text-align: center; }
  @media (max-width: 1260px) {
    .promo-page .field--name-body {
      padding: 0 20px; } }
  @media (max-width: 1024px) {
    .promo-page .field--name-body {
      margin-top: 100px;
      font-size: 25px;
      line-height: 41px; } }
  @media (max-width: 480px) {
    .promo-page .field--name-body {
      margin-top: 60px;
      font-size: 20px;
      line-height: 34px; } }

.promo-page .group-how-it-works-text,
.promo-page .group-how-it-works-images {
  width: 50%;
  position: relative; }
  @media (max-width: 1024px) {
    .promo-page .group-how-it-works-text,
    .promo-page .group-how-it-works-images {
      width: 450px; } }
  @media (max-width: 450px) {
    .promo-page .group-how-it-works-text,
    .promo-page .group-how-it-works-images {
      width: 100%;
      padding: 0 20px; } }

.promo-page .node--full .field-collection-view {
  margin-bottom: 0; }

.promo-page .research-wrapper {
  max-width: 1080px;
  width: 100%;
  margin: 0 auto; }

.promo-page #research-newsletter {
  background: #EDF5F8;
  padding-top: 115px;
  padding-bottom: 115px; }
  .promo-page #research-newsletter .yellow {
    color: #7D2B6D; }
  @media (max-width: 1200px) {
    .promo-page #research-newsletter {
      padding-top: 90px;
      padding-bottom: 90px; } }
  .promo-page #research-newsletter h2 {
    font-size: 68px;
    text-align: center;
    letter-spacing: -1.36px;
    line-height: 78px;
    font-weight: bold; }
    @media (max-width: 1100px) {
      .promo-page #research-newsletter h2 {
        font-size: 52px;
        line-height: 62px;
        padding: 0 20px; } }
    @media (max-width: 800px) {
      .promo-page #research-newsletter h2 {
        font-size: 48px;
        line-height: 58px; } }
    @media (max-width: 650px) {
      .promo-page #research-newsletter h2 {
        font-size: 40px;
        line-height: 50px; } }
    @media (max-width: 550px) {
      .promo-page #research-newsletter h2 {
        font-size: 35px;
        line-height: 45px; } }
    @media (max-width: 450px) {
      .promo-page #research-newsletter h2 {
        font-size: 30px;
        line-height: 40px; } }
  .promo-page #research-newsletter .form-type-checkbox {
    max-width: 385px;
    margin: 0 auto;
    margin-top: 25px;
    padding-left: 0;
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
    justify-content: center;
    overflow: hidden; }
  .promo-page #research-newsletter .terms-text {
    color: #4F4F4F;
    font-weight: bold; }
    @media (max-width: 425px) {
      .promo-page #research-newsletter .terms-text {
        margin-left: 0; } }
  .promo-page #research-newsletter a {
    color: #145085; }
  .promo-page #research-newsletter .checkmark {
    border-radius: 8px;
    width: 32px;
    height: 32px;
    position: relative;
    margin-right: auto; }
    @media (max-width: 700px) {
      .promo-page #research-newsletter .checkmark {
        margin-right: 20px; } }
    .promo-page #research-newsletter .checkmark:after {
      left: 13px;
      top: 10px; }

.promo-page #promo-mailchimp {
  display: flex;
  align-items: center;
  margin-top: 68px;
  justify-content: center; }
  @media (max-width: 920px) {
    .promo-page #promo-mailchimp {
      flex-direction: column;
      margin-top: 40px; } }
  .promo-page #promo-mailchimp #mergeTable {
    display: flex;
    align-items: center;
    width: 100%; }
    .promo-page #promo-mailchimp #mergeTable .field-group {
      position: relative; }
    @media (max-width: 1180px) {
      .promo-page #promo-mailchimp #mergeTable {
        width: auto; } }
    @media (max-width: 920px) {
      .promo-page #promo-mailchimp #mergeTable {
        flex-direction: column;
        width: 320px; }
        .promo-page #promo-mailchimp #mergeTable .field-group {
          margin-bottom: 10px; } }
  .promo-page #promo-mailchimp .mergeRow {
    max-width: 400px;
    width: 100%; }
    @media (max-width: 920px) {
      .promo-page #promo-mailchimp .mergeRow-email {
        margin-top: 20px; } }
    .promo-page #promo-mailchimp .mergeRow select,
    .promo-page #promo-mailchimp .mergeRow input {
      height: 58px;
      width: 100%;
      border-radius: 36px;
      border: none;
      padding: 0 28px;
      font-size: 16px;
      font-family: "Montserrat", san-serif; }
      @media (max-width: 920px) {
        .promo-page #promo-mailchimp .mergeRow select,
        .promo-page #promo-mailchimp .mergeRow input {
          height: 50px; } }
    .promo-page #promo-mailchimp .mergeRow-email, .promo-page #promo-mailchimp .mergeRow-text {
      margin-right: 15px; }
      @media (max-width: 920px) {
        .promo-page #promo-mailchimp .mergeRow-email, .promo-page #promo-mailchimp .mergeRow-text {
          margin-right: 0; } }
  .promo-page #promo-mailchimp .formEmailButton {
    margin-top: 33px;
    border: none; }
    @media (max-width: 920px) {
      .promo-page #promo-mailchimp .formEmailButton {
        width: 320px;
        height: 50px;
        line-height: 1px; } }
  .promo-page #promo-mailchimp input[disabled=''][type=submit] {
    background: #ddd; }
    .promo-page #promo-mailchimp input[disabled=''][type=submit]:hover {
      cursor: default;
      color: #100a0f; }

.promo-page footer {
  display: flex;
  align-items: center;
  max-width: 1360px;
  width: 100%;
  margin: 0 auto;
  padding: 60px 0; }
  @media (max-width: 1140px) {
    .promo-page footer {
      padding: 60px 20px; } }
  @media (max-width: 1200px) {
    .promo-page footer {
      flex-direction: column;
      padding: 20px; } }
  @media (max-width: 1200px) {
    .promo-page footer .illustrations,
    .promo-page footer .copyright {
      margin-bottom: 20px;
      margin-left: 0;
      text-align: center; } }
  @media (max-width: 1200px) {
    .promo-page footer .policy-links {
      margin-left: 0; } }
  @media (max-width: 400px) {
    .promo-page footer .policy-links {
      display: flex;
      flex-direction: column;
      text-align: center; }
      .promo-page footer .policy-links span {
        margin-bottom: 20px; } }
  .promo-page footer .bold {
    font-weight: bold; }
  .promo-page footer a {
    color: #000; }
  .promo-page footer * {
    font-size: 14px;
    font-family: "Montserrat", san-serif; }
  .promo-page footer .design-by {
    display: flex;
    align-items: center;
    margin-left: auto; }
    @media (max-width: 1200px) {
      .promo-page footer .design-by {
        margin: 20px auto; } }
    .promo-page footer .design-by span {
      line-height: 1;
      display: inline-block; }
    .promo-page footer .design-by img {
      height: 23px;
      margin-left: 3px; }

.promo-page .slider-wrapper {
  border-radius: 50px;
  width: 470px;
  height: 100px;
  display: flex;
  align-items: center;
  z-index: 4;
  justify-content: center;
  box-sizing: border-box;
  margin: 0 auto;
  background: white;
  position: absolute;
  bottom: 100px;
  padding: 15px 30px;
  left: 50%;
  transform: translateX(-50%); }
  .promo-page .slider-wrapper .wrapper {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 266px; }
  .promo-page .slider-wrapper .rate {
    font-size: 16px;
    position: absolute;
    top: -22px; }
  @media (max-width: 850px) {
    .promo-page .slider-wrapper {
      height: 80px; }
      .promo-page .slider-wrapper .rate {
        font-size: 14px; } }
  @media (max-width: 700px) {
    .promo-page .slider-wrapper {
      bottom: 50px; }
      .promo-page .slider-wrapper .wrapper {
        top: 0;
        padding: 0 5px; } }
  @media (max-width: 360px) {
    .promo-page .slider-wrapper .wrapper {
      max-width: 200px; } }
  @media (max-width: 550px) {
    .promo-page .slider-wrapper {
      width: 400px;
      bottom: 20px; } }
  @media (max-width: 450px) {
    .promo-page .slider-wrapper {
      width: calc(100% - 40px);
      padding: 14px 20px 10px 20px; } }
  @media (max-width: 360px) {
    .promo-page .slider-wrapper {
      width: calc(100% - 20px); } }
  .promo-page .slider-wrapper .image-wrapper {
    width: 56px;
    height: 56px;
    display: flex; }
    @media (max-width: 700px) {
      .promo-page .slider-wrapper .image-wrapper {
        width: 46px;
        height: 46px; } }
    @media (max-width: 550px) {
      .promo-page .slider-wrapper .image-wrapper {
        width: 36px;
        height: 36px; } }
    @media (max-width: 400px) {
      .promo-page .slider-wrapper .image-wrapper {
        width: 31px;
        height: 31px; } }
    .promo-page .slider-wrapper .image-wrapper.first {
      margin-right: auto; }
    .promo-page .slider-wrapper .image-wrapper.last {
      margin-left: auto; }

.promo-page input[type=range] {
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  max-width: 265px; }
  @media (max-width: 550px) {
    .promo-page input[type=range] {
      width: 100%; } }
  @media (max-width: 360px) {
    .promo-page input[type=range] {
      width: 196px; } }

.promo-page input[type=range]:focus {
  outline: none; }

.promo-page input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  background: transparent linear-gradient(270deg, #00ADFA 0%, #00ADFA 50%, #ED3EAA 100%);
  border-radius: 25px; }
  @media (max-width: 550px) {
    .promo-page input[type=range]::-webkit-slider-runnable-track {
      height: 4px; } }

.promo-page input[type=range]::-webkit-slider-thumb {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: white;
  border: 1px solid #707070;
  background: white;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -5.6px; }
  @media (max-width: 550px) {
    .promo-page input[type=range]::-webkit-slider-thumb {
      margin-top: -7.6px; } }

.promo-page input[type=range]:focus::-webkit-slider-runnable-track {
  background: transparent linear-gradient(270deg, #00ADFA 0%, #00ADFA 50%, #ED3EAA 100%); }

.promo-page input[type=range]::-moz-range-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent linear-gradient(270deg, #00ADFA 0%, #00ADFA 50%, #ED3EAA 100%);
  border-radius: 25px; }
  @media (max-width: 550px) {
    .promo-page input[type=range]::-moz-range-track {
      height: 4px; } }

.promo-page input[type=range]::-moz-range-thumb {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: white;
  border: 1px solid #707070;
  cursor: pointer; }

.promo-page input[type=range]::-ms-track {
  width: 100%;
  height: 8px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 39px 0;
  color: transparent; }
  @media (max-width: 550px) {
    .promo-page input[type=range]::-ms-track {
      height: 4px; } }

.promo-page input[type=range]::-ms-fill-lower {
  background: transparent linear-gradient(270deg, #00ADFA 0%, #00ADFA 50%, #ED3EAA 100%);
  border-radius: 50px; }

.promo-page input[type=range]::-ms-fill-upper {
  background: transparent linear-gradient(270deg, #00ADFA 0%, #00ADFA 50%, #ED3EAA 100%);
  border-radius: 50px; }

.promo-page input[type=range]::-ms-thumb {
  height: 22px;
  width: 22px;
  border-radius: 50%;
  background: white;
  border: 1px solid #707070;
  border-radius: 8px;
  background: white;
  cursor: pointer; }

.promo-page input[type=range]:focus::-ms-fill-lower {
  background: transparent linear-gradient(270deg, #00ADFA 0%, #00ADFA 50%, #ED3EAA 100%); }

.promo-page input[type=range]:focus::-ms-fill-upper {
  background: transparent linear-gradient(270deg, #00ADFA 0%, #00ADFA 50%, #ED3EAA 100%); }

@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg); }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg); } }

.field--name-field-promo-floating-image-left {
  -webkit-animation: rotating 8s linear infinite;
  -moz-animation: rotating 8s linear infinite;
  -ms-animation: rotating 8s linear infinite;
  -o-animation: rotating 8s linear infinite;
  animation: rotating 8s linear infinite; }
