@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700;800&family=Roboto:wght@300;400;500;700;900&display=swap');

$mont: 'Montserrat', san-serif;

// colors
$yellow: #FFC132;
$bg: #FFE7E7;
$purpleDark: #7D2B6D; // #C8AADE;
$blue: #E7EEFF;
$blueDark: #145085;
$green: #E1F4DB;
$greenDark: #20652A;
$lightBlue: #EDF5F8;
$light-purple:#A22E8B;

.show-item-custom{
  display: block !important;
}
.hide--element{
  display: none !important;
}
.status-open{
  border: 0px !important;
  border-radius: 36px 36px 0 0 !important;
  @include breakpoint(max-width 500px) {
    border-radius: 12px 12px 0 0 !important;
  }
}

.page-node-250 {
  scroll-behavior: smooth;
  .l-header{
    padding: 0 20px;
    @include breakpoint(1600px) {
      padding: 0px;
    }
  }
  #set-height {
    display: block;
  }
  .selectboxit-list > .selectboxit-focus > .selectboxit-option-anchor{
    background: $purpleDark;
    color: white !important;
  }
  .selectboxit.selectboxit-focus{
    border: 1px solid $purpleDark;
  }
  .selectboxit-option-anchor{
    text-decoration: none !important;
    font-size: 16px !important;
    font-weight: 500 !important;
    color: #000 !important;
  }
  #rq-canvas {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 1060px;
    object-fit: cover;
    object-position: center;
    z-index: -1;
    pointer-events: none;
    overflow: hidden;
    @include breakpoint(500px) {
      min-height: 700px;
    }
  }
  p font-family helvetica {
    font-size: 24px;
  }
  *{
    font-family: 'Roboto',sans-serif !important;
  }
  .hero-section{
    position: relative;
    overflow: hidden;
    height: 980px;
    @include breakpoint(800px) {
      height: 700px;
    }
    &--body{
      top: 0;
      left: 0;
      width: 100%;
      position: fixed;
      height: 1060px;
      z-index: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      @include breakpoint(800px){
        height: 760px;
      }
      @include breakpoint(500px){
        height: 700px;
      }
    }
    
    .large-text{
      margin-bottom: 20px;
       @include breakpoint(500px){
        margin-bottom: 0px;
      }
    }
    .grey-layer{
      background: #000;
      opacity: .4;
      z-index: 1;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
    }
    #scroll-down{
      z-index: 2;
      position: absolute;
      bottom: 60px;
      right: 0;
      left: 0;
      margin: 0 auto;
      @include breakpoint(500px) {
        bottom: 40px;
      }
    }
    &--text{
      z-index: 2;
      margin-bottom: -75px;
    }
  }
  .promo-page{
    .group-promo-hero{
      display: none;
    }
    #promo-mailchimp .mergeRow input{
      @include breakpoint(920px) {
        height: 58px;
      }
      @include breakpoint(500px) {
        border-radius: 12px;
      }
    }
    #research-newsletter .checkmark{
      @include breakpoint(500px) {
        margin-right: 0px;
        flex-shrink: 0;
      }
    }
    #research-newsletter .terms-text{
      font-size: 14px;
      line-height: 19px;
      @include breakpoint(425px) {
        width: 85%;
      }
    }
    .field--name-field-promo-how-it-works .field__label{
      @include breakpoint(500px) {
        margin-top: 28px;
        margin-bottom: 80px;
        font-size: 24px;
        line-height: 32px;
      }
    }
    .field--name-field-whats-for-you-floating-img.anim{
      @include breakpoint(500px){
        transform: translate(-125%,-33%);
      }
    }
    #promo-mailchimp{
      flex-wrap: wrap;
      .selectboxit-container {
        max-width: 260px;
        width: 100%;
        @include breakpoint(920px) {
          max-width: 400px ;
        }
        .selectboxit-text {
          color: #747474;
          font-weight: 500;
        }
        .selectboxit{
          background: white;
          width: 260px;
          width: 100%;
          height: 58px;
          border-radius: 60px;
          display: flex;
          align-items: center;
          padding: 0 22px;
          @include breakpoint(500px) {
            padding: 0 10px;
            border-radius: 12px;
          }
        }
      }
      
      .selectboxit-options{
        background: white;
        border-radius: 0 0 36px 36px;
        padding: 30px 24px;
        @include breakpoint(max-width 500px) {
          border-radius: 0 0 12px 12px;
        }
      }
      select{
        display: none !important;
      }
      #mergeTable{
        flex-shrink: 0;
        @include breakpoint(max-width 1180px) {
          width: 100%;
          padding: 0 20px;
        }
        @include breakpoint(max-width 920px) {
          padding: 0px;
        }
        @include breakpoint(max-width 500px) {
          padding: 0 20px;
        }
        .field-group{
          margin-top: 12px;
        }
      }
      .mergeRow{
        input{
          @include breakpoint(500px) {
            padding: 0 16px;
          }
        }
      }
    }
    .first-option{
      font-size: 16px;
      color: #747474;
      font-weight: 500;
    }
    #mergeRow-2{
      max-width: 260px !important;
      @include breakpoint(920px) {
        margin-top: 20px;
        max-width: 400px !important;
      }
      select{
        padding:0 16px;
      }
    }
    #research-newsletter .form-type-checkbox{
      max-width: 437px;
      overflow-wrap: break-word;
      width: 100%;
      @include breakpoint(500px) {
        padding: 0 20px;
      }
      label{
        margin-left: 14px;
      }
    }
    .group-how-it-works-images{
      position: relative;
      max-width: 536px;
      width: 100%;
    }
    .field--name-field-how-it-works-lottie{
      position: relative;
      display: inline-block;
      position: absolute;
      z-index: 2;
      display: flex;
      align-items: center;
      justify-content: center;
      &::before{
        content: '';
        display: block;
        position: absolute;
        background: white;
        border-radius: 50%;
        opacity: 0.7;
        backdrop-filter: blur(5px);
        -webkit-backdrop-filter: blur(5px);
        width: 108px;
        height: 108px;
        z-index: 3;
        @include breakpoint(500px) {
          width: 68px;
          height: 68px;
        }
      }
    }
    .field--name-field-how-it-works-main-image{
      width: 100%;
    }
    .field--name-field-promo-whats-in-it-for-you .field-group-html-element:nth-child(2){
      @include breakpoint(1260px) {
        margin-top: -330px;
      }
      @include breakpoint(1020px) {
        margin-top: -375px;
      }
      @include breakpoint(850px) {
        margin-top: -435px;
      }
      @include breakpoint(700px) {
        margin-top: -105px;
      }
    }
    .field--name-field-promo-info-lottie{
      width: 100px;
      position: absolute;
      left: 11px;
      top: 27px;
    }
    .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group{
      max-width: 537px;
      padding: 70px 0px 0px 20px;
      @include breakpoint(1360px) {
        max-width: 510px;
        padding-right: 40px;
      }
      @include breakpoint(500px) {
        padding: 0px 0px 0px 20px;
        max-width: 100%;
      }
      h2{
        font-size: 36px;
        font-weight: 900;
        @include breakpoint(850px) {
          font-size: 24px;
        }
      }
      p{
        font-weight: 400;
        font-size: 24px;
        @include breakpoint(850px) {
          font-size: 20px;
          line-height: 30px;
        }
      }
    }
    .field--name-field-promo-whats-in-it-for-you .group-whats-for-you-text-group p:last-of-type{
      max-width: 465px;
      font-size: 22px;
      @include breakpoint(850px) {
        font-size: 18px;
        line-height: 28px;
      }
    }
    .lottie-img{
      z-index: 10;
      position: relative;
    }
    .field-collection-container .field-items .field-item{
      margin: 0px !important;
    }
    .field--name-field-promo-how-it-works{
      .field__items{
        .field__item{
          &:nth-child(1){
            .field--name-field-how-it-works-lottie{
              top: 166px;
              right: 152px;
              @include breakpoint(500px) {
                top: 30%;
                right: 30%;  
              }
            }
            .lottie-img{
              width: 92px;
              height: 92px;
              @include breakpoint(500px) {
                width: 58px;
                height: 58px;
              }
            }
          }
          &:nth-child(2){
            .field--name-field-how-it-works-lottie{
              top: 136px;
              right: 28px;
              @include breakpoint(500px) {
                top: 23%;
                right: 15%;  
              }
            }
            .lottie-img{
              width: 168px;
              height: 126px;
              @include breakpoint(500px) {
                width: 104px;
                height: 78px;
              }
            }
          }
          &:nth-child(3){
            .field--name-field-how-it-works-lottie{
              top: 159px;
              right: 79px;
              @include breakpoint(500px) {
                top: 32%;
                right: 20%;  
              }
              &::before{
                content: '';
                display: block;
                position: absolute;
                background: white;
                border-radius: 50%;
                opacity: 0.7;
                backdrop-filter: blur(5px);
                -webkit-backdrop-filter: blur(5px);
                width: 120px;
                height: 120px;
                z-index: 3;
                @include breakpoint(500px) {
                  width: 68px;
                  height: 68px;
                }
              }
            }
            .lottie-img{
              width: 82px;
              height: 82px;
              @include breakpoint(500px) {
                width: 46px;
                height: 46px;
              }
            }
          }
        }
      }
    }
    .mailchimp-label{
      margin-left: 30px;
      font-size: 16px;
      line-height: 21px;
      font-weight: 700;
      @include breakpoint(500px) {
        margin-left: 16px;
      }
    }
    .submit_container{
      display: flex;
      width: 100%;
      @include breakpoint(500px) {
        padding: 0 50px;
      }
    }
    #research-newsletter h2 {
      font-size: 52px;
      line-height: 64px;
      font-weight: 900;
      @include breakpoint(1100px) {
        font-size: 44px;
        line-height: 52px;
      }
      @include breakpoint(900px) {
        font-size: 34px;
        line-height: 42px;
      }
      @include breakpoint(700px) {
        font-size: 28px;
        line-height: 36px;
      }
      @include breakpoint(500px) {
        font-size: 24px;
        line-height: 32px;
      }
    }
    .node--promo-page{
      z-index: 1;
      position: relative;
    }
    #research-newsletter{
      z-index: 1;
      position: relative;
      padding-bottom: 80px;
    }
    .selectboxit-default-arrow{
      width: 0;
      height: 0;
      border-top: 6px solid #000000;
      border-right: 6px solid transparent;
      border-left: 6px solid transparent;
    }
    .selectboxit-arrow-container{
      right: 15px;
    }
    #promo-mailchimp input[disabled=''][type=submit]{
      color: #6b6b6b;
      &:hover{
        color:#6b6b6b
      }
    }
    footer {
      background: white;
      z-index: 1;
      position: relative;
      padding-right: 40px;
      padding-left: 40px;
      @include breakpoint(max-width 1140px) {
        padding: 60px 40px;
      }
      @include breakpoint(500px) {
        padding: 20px;
      }
      .design-by{
        @include breakpoint(500px) {
          margin:0 auto;
        }
      }
      .policy-links{
        a{
          text-decoration: none;
        }
      }
      .illustrations{
        @include breakpoint(500px) {
          display: flex;
          flex-direction: column;
        }
      }
    }
    .promo-menu {
      #join {
        background: $purpleDark ;
        color: white;
        &:hover {
          background: $light-purple;
        }
      }
      a{
        &:hover{
          color: $purpleDark;
        }
      }
    }
    .field--name-field-promo-whats-in-it-for-you{
      margin-top: 261px;
      @include breakpoint(500px) {
        margin-top: 186px;
      }
    }
    .field--name-field-whats-for-you-main-image img{
      @include breakpoint(500px) {
        position: absolute;
        right: -30px;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        height: 300px;
      }
    }
    .group-promo-info {
      max-width: 1364px;
      border-radius: 20px;
      border: 1px solid #DEDEDE;
      padding: 37px 166px 30px 138px;
      margin: 80px auto 0px auto;
      position: relative;
      @include breakpoint(1370px) {
        margin: 80px 40px 0px 40px;
      }
      @include breakpoint(700px) {
        margin: 80px 20px 0px 20px;
        padding: 124px 22px 40px 22px;
      }
      .field__item{
        &::before{
          content: '';
          display: block;
          background: url('../images/icon-info.png');
          position: absolute;
          width: 8px;
          height: 20px;
          top: 52px;
          left: 57px;
        }
      }
      p {
        &:nth-child(1){
          margin-bottom: 12px;
          font-size: 24px;
          line-height: 36px;
          font-weight: 400;
          @include breakpoint(500px) {
            font-size: 20px;
            line-height: 30px;
          }
        }
        &:nth-child(2){
          font-size: 28px;
          line-height: 36px;
          font-weight: 700;
          @include breakpoint(500px) {
            font-size: 24px;
            line-height: 32px;
            font-weight: 900;
          }
        }
      }
    }
    .field--name-field-promo-hero-text h1 {
      font-size: 36px;
      font-weight: 500;
      line-height: 55px;
      color: white;
      @include breakpoint(900px){
        font-size: 32px;
        line-height: 44px;
      }
      @include breakpoint(700px){
        font-size: 28px;
        line-height: 38px;
      }
      @include breakpoint(500px){
        font-size: 24px;
        line-height: 32px;
      }
      span {
        width: 100%;
        display: block;
        font-size: 128px;
        font-weight: 900;
        line-height:1;
        color: white;
        @include breakpoint(1120px){
          font-size: 80px;
        }
        @include breakpoint(1020px){
          font-size: 60px;
        }
        @include breakpoint(900px){
          font-size: 50px;
        }
        @include breakpoint(700px){
          font-size: 40px;
        }
        @include breakpoint(500px){
          font-size: 36px;
        }
      }
     
    }
    .field--name-field--promo-hero-link {
      margin-top: 52px;
      @include breakpoint(500px){
        margin-top: 36px;
      }
      .field__item {
        display: flex;
        justify-content: center;
      }
      a{
        border-radius: 60px;
        max-width: 260px;
        font-size: 16px;
        line-height: 1;
        font-weight: 800;
        width: 100%;
        background: white;
        color: #222222;
        padding: 18px 41px;
        text-decoration: none;
        display: flex;
        justify-content: center;
      }
    }
    .field--name-field-how-it-works-main-title-la {
      max-width: 536px;
      font-size: 24px;
      line-height: 34px;
      font-weight: 400;
      @include breakpoint(900px){
        font-size: 22px;
        line-height: 30px;
      }
      @include breakpoint(500px){
        font-size: 18px;
        line-height: 26px;
      }
    }
    .field--name-field-promo-how-it-works .field__item {
      &::before {
        display: none !important;
      }
      &:first-child {
        .group-how-it-works-text {
          max-width: 536px;
          width: 100%;
          margin: 0px 0 0 154px;
          @include breakpoint(1360px){
            margin: 0px 100px;
          }
          @include breakpoint(1024px){
            margin: 20px 0 0 0px;
          }
        }
        .field--name-field-how-it-works-main-image {
          .field__item {
            img {
              margin-left: 0px;
              height: auto;
              @include breakpoint(500px) {
                width: 100%;
              }
            }
          }
        }
      }
      &:nth-child(2) {
        .group-how-it-works-text {
          max-width: 536px;
          width: 100%;
          margin: 0px auto 0 auto;
          @include breakpoint(1360px){
            margin: 0px 100px;
          }
          @include breakpoint(1024px){
            margin: 20px auto 0 auto;
          }
        }
        
        .field--name-field-how-it-works-main-image {
          .field__item {
            img {
              margin-left: 0px;
              height: auto;
              @include breakpoint(500px) {
                width: 100%;
              }
            }
          }
        }
        .field--name-field-how-it-works-pre-title {
          color: #7D2B6D;
        }
      }
      &:nth-child(3) {
        .group-how-it-works-text {
          max-width: 536px;
          width: 100%;
          margin: 0px 0 0 154px;
          @include breakpoint(1360px){
            margin: 0px 100px;
          }
          @include breakpoint(1024px){
            margin: 20px 0 0 0px;
          }
        }
        .field--name-field-how-it-works-main-image {
          .field__item {
            img{
              margin-left: 0px;
              height: auto;
              @include breakpoint(500px) {
                width: 100%;
              }
            }
          }
        }
        .field--name-field-how-it-works-pre-title {
          color: #7D2B6D;
        }
      }
    }
    .field--name-field-promo-how-it-works .field__label {
      margin-top: 24px;
      margin-bottom: 120px;
      padding: 0 20px;
    }
    .field--name-body{
      margin: 0px auto 0px auto;
      padding-top: 120px;
      max-width: 1110px;
      font-size: 28px;
      font-weight: 400;
      line-height: 44px;
      font-family: 'Roboto',sans-serif;
      @include breakpoint(900px) {
        font-size: 24px;
        line-height: 34px;
      }
      @include breakpoint(500px) {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}


.promo-page {
  .form-label {
    padding-left: 25px;
    padding-bottom: 9px;    
    display: inline-block;
    font-weight: bold;
    color: #4F4F4F;
    font-size: 17px;
  }

  .validation-msg {
    position: absolute;
    top: -25px;
    left: 20px;
    font-size: 12px;
    color: red;
    &.inactive {
      left: -999999px;
    }
  }

  h1 {
    font-size: 68px;
    font-weight: bold;
    line-height: 78px;
    letter-spacing: -1.36px;
    font-family: "Montserrat", sans-serif;

    .yellow {
      color: #7D2B6D;
    }
  }

  .l-header {
    @include breakpoint(max-width 550px) {
      height: 60px;
    }
  }

  .node--full {
    .node__content {
      background: white;
      @include breakpoint(max-width 1024px) {
        width: 100%;
        margin: 0 auto;
      }
    }
  }

  .success-msg {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 50px;

    @include breakpoint(max-width 450px) {
      flex-direction: column;
      text-align: center;
    }
    .message {
      font-size: 22px;
      line-height: 30px;
      margin-left: 10px;      
    }
  }

  // flex align prop
  .aln {
    &-cnt {
      align-items: center;
    }
  }

  // fonts
  .text {
    &.no-dec {
      text-decoration: none;
    }
    &.black {
      color: black;
    }
    &.ln-26 {
      line-height: 26px;
    }
    &.s16 {
      font-size: 16px;
    } 
    &.mont {
      font-family: $mont
    }
    &.bold {
      font-weight: bold;
    }
  }

  //links 
  .link-reset {
    &:active,
    &:visited {
      text-decoration: none;
      color: unset;
    }
  }

  // margins
  .mg {
    &-r {
      &--10 {
        margin-right: 10px;
      }
      &--60 {
        margin-right: 60px;
        @include breakpoint(max-width 1024px) {
          margin-right: 30px;
        }
      }
    }
    &-l {
      &--20 {
        margin-left: 20px;
      }
      &--79{
        margin-left: 79px;
      }
    }
  }

  .cta {
    &-yellow {
      background: $yellow;
      cursor: pointer;
      @include breakpoint(min-width 1024px) {
        &:hover {
          background: #000;
          color: white;
        }
      }      
    }
    &-purple {
      max-width: 398px;
      width: 100%;
      margin: 40px auto 0 auto;
      border: 0px;
      font-size: 16px;
      font-weight: 700;
      background: $purpleDark;
      color: white;
      cursor: pointer;
      &:hover{
        background: $light-purple;
      }
    }
    &.rounded {
      border-radius: 36px;
      padding: 20px 32px;    
    }    
  }

  .promo-menu {
    .cta {
      &.rounded {
        @include breakpoint(max-width 1024px) {
          padding: 10px 16px;
        }
        @include breakpoint(max-width 960px) {
          // padding: 12px 16px;
          font-size: 12px;
        }
        @include breakpoint(max-width 360px) {
          padding: 12px 16px;
          font-size: 10px;
        }
      }
    }
  }

  .logo {
    margin-right: auto;
    @include breakpoint(max-width 960px) {
      width: 150px;
    }
    @include breakpoint(max-width 360px) {
      width: 130px;
    }
  }
  .promo-menu {
    display: flex;
    li {
      @include breakpoint(max-width 960px) {
        display: none;
        &:last-child {
          display: block;
        }
      }
    }
  }

  // hero banner
  .group-promo-hero {    
    padding-top: 80px;
    border-bottom-left-radius: 60px;
    border-bottom-right-radius: 60px;
    background: $bg;
    position: relative;
    overflow: hidden;
    &-inner {
      max-width: 1120px;
      margin: 0 auto;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    @include breakpoint(max-width 700px) {
      padding-top: 30px;
    }
    @include breakpoint(max-width 450px) {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  
  .yellow {
    color: $yellow;
  }

  // fields 
  .field--name-field {
    &-promo-hero-text {
      h1 {
        text-align: center;
        font-size: 68px;
        font-weight: bold;
        line-height: 78px;
        letter-spacing: -1.36px;
        margin-bottom: 22px;      
        @include breakpoint(max-width 1024px) {
          font-size: 50px;
          line-height: 58px;        
          padding: 0 10px;
        }
        @include breakpoint(max-width 850px) {
          font-size: 40px;
          line-height: 48px;
          letter-spacing: -0.6px;
          padding: 0 10px;
        }
        @include breakpoint(max-width 500px) {
          font-size: 30px;
          line-height: 38px;
        }
      }
      
    }
    &-promo-hero-image {      
      position: relative;
      
      .field__items {
        @include breakpoint(max-width 450px) {
          img {
            height: 435px;
            object-fit: cover;
          }          
        }   
      }

      img {
        display: block;
        z-index: 2;
        position: relative;                     
      }

      &:before {
        content: "";
        width: 730px;
        height: 730px;
        background: #F6D7D7;        
        z-index: 1;
        display: block;
        border-radius: 50%;
        position: absolute;
        top: 52%;
        left: 50%;
        transform: translate(-50%,-50%);
        @include breakpoint(max-width 1024px) {
          width: 600px;
          height: 600px;
        }
        @include breakpoint(max-width 850px) {
          width: 500px;
          height: 500px;
        }
        @include breakpoint(max-width 700px) {
          width: 400px;
          height: 400px;
        }
        @include breakpoint(max-width 600px) {
          width: 300px;
          height: 300px;
        }
        @include breakpoint(max-width 400px) {
          width: 300px;
          height: 300px;
        }
      }
    }
    &-promo-floating-image {      
      &-left {
        position: absolute;
        top: 368px;
        left: 168px;
        opacity: 1;
        // transition: all 3s;
        &.anim {
          opacity: 1;
          top: 278px;          
        }
        @include breakpoint(max-width 1450px) {
          left: 0;
        }
        @include breakpoint(max-width 1110px) {
          width: 170px;
        }
        @include breakpoint(max-width 850px) {
          display: none;
        }
      }
      &-right {
        position: absolute;
        // top: 966px;
        top: 866px;
        right: 213px;
        opacity: 1;
        // transition: all 3s;
        &.anim {
          top: 866px;
          opacity: 1;
        }
        
        @include breakpoint(max-width 1450px) {
          right: 0;
        }
        @include breakpoint(max-width 1024px) {
          top: 666px;
          // top: unset !important;
          // bottom: 30px;
        }
        @include breakpoint(max-width 850px) {
          display: none;
        }
      }
    }
    &-promo-how-it-works {
      
      margin: 0 auto;
      max-width: 1360px;
      width: 100%;
      .field__label {
        font-size: 36px;
        line-height: 55px;
        text-align: center;
        margin-top: 16px;
        color: #7D2B6D;
        margin-bottom: 80px;
        // 'Montserrat', sans-serif;
        @include breakpoint(max-width 600px) {
          font-size: 30px;
          margin-bottom: 40px;
        }
      }
      .content {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
        @include breakpoint(max-width 1024px) {
          flex-direction: column;
          margin-bottom: 60px;
        }
      }
      .field__item {
        &.odd {
          .content {
            flex-direction: row-reverse;
            @include breakpoint(max-width 1024px) {
              flex-direction: column;
            }
          }
        }

        // General styling for all main images across the field items
        .field--name-field {
          &-how-it-works-main-image {
            img {
              object-fit: cover;
              object-position: top;
              height: 545px;
              bottom: 12px;
              @include breakpoint(max-width 1200px) {
                height: 445px;
              }
              @include breakpoint(max-width 500px) {
                height: 345px;
                width: 300px;
                margin: 0 auto !important;
              }
            }
          }
        }


        // Unique styling for each of the field_items's main image        
        &:first-child {
          .field--name-field {
            &-how-it-works-pre-title {
              color: $purpleDark;
            }
          }
          .field--name-field-how-it-works-floating-img {
            position: absolute;
            right: -10px;
            top: 235px;
            z-index: 2;
            opacity: 0;
            transition: all 1s;
            &.anim {
              top: 135px;
             
              opacity: 1;
              @include breakpoint(max-width 500px) {
                top: 65px;
                width: 90px;
                right: 10px;
              }
              @include breakpoint(max-width 400px) {
                // top: 65px;
                width: 75px;
                right: 20px;
              }
            }
            
            @include breakpoint(max-width 500px) {
              top: 235px;
              width: 90px;
              right: 10px;
            }           
          }
          .field--name-field-how-it-works-main-image {
            .field__item {
              position: relative;
              img {
                position: relative;
                z-index: 2;
                display: block;
                margin-left: 92px;
                @include breakpoint(max-width 1200px) {
                  margin-left: 22px;
                }
              }
              &:before {
                content: "";
                z-index: 1;
                position: absolute;
                bottom: 12px;
                left: 0;
                width: 536px;
                height: 480px;
                background: #F5E7FF;
                border-radius: 20px;
                @include breakpoint(max-width 1200px) {
                  width: 445px;
                }
                @include breakpoint(max-width 1024px) {
                  height: 380px;
                  width: 100%;
                }
                @include breakpoint(max-width 500px) {
                  height: 285px;
                }
              }
            }
          }
          .group-how-it-works-text {
            margin-left: 94px;
            margin-right: 94px;
            @include breakpoint(max-width 1024px) {
              margin-left: 0;
              margin-right: 0;
              margin-top: 25px;
            }
          }        
        }
        &:nth-child(2) {
          .field--name-field {
            &-how-it-works-pre-title {
              color: $blueDark;
            }
          }
          .field--name-field-how-it-works-floating-img {
            position: absolute;
            right: -85px;
            top: 135px;
            z-index: 2;
          }
          .field--name-field-how-it-works-main-image {
            .field__item {
              position: relative;
              img {
                position: relative;
                z-index: 2;
                display: block;
                // margin-right: 82px;
                margin-left: -40px;
                @include breakpoint(max-width 1024px) {
                  margin-left: 0;
                }
              }
              &:before {
                content: "";
                z-index: 1;
                position: absolute;
                bottom: 12px;
                right: 0;
                width: 536px;
                height: 480px;
                background: $blue;
                border-radius: 20px;
                @include breakpoint(max-width 1200px) {
                  width: 445px;
                }
                @include breakpoint(max-width 1024px) {
                  height: 380px;
                  width: 100%;
                }
                @include breakpoint(max-width 500px) {
                  height: 285px;
                }
              }
            }
          }
          .group-how-it-works-text {
            margin-right: 154px;
            margin-left: 154px;
            @include breakpoint(max-width 1024px) {
              margin-left: 0;
              margin-right: 0;
              margin-top: 25px;
            }
          }
        }
        &:nth-child(3) {
          .field--name-field {
            &-how-it-works-pre-title {
              color: $greenDark;
            }
          }
          .field--name-field-how-it-works-floating-img {
            position: absolute;
            right: -85px;
            top: 135px;
            z-index: 2;
          }
          .field--name-field-how-it-works-main-image {
            .field__item {
              position: relative;
              img {
                position: relative;
                z-index: 2;
                display: block;
                margin-left: 92px;                
                @include breakpoint(max-width 1200px) {
                  margin-left: 22px;
                }
              }
              &:before {
                content: "";
                z-index: 1;
                position: absolute;
                bottom: 12px;
                left: 0;
                width: 536px;
                height: 480px;
                background: $green;
                border-radius: 20px;
                @include breakpoint(max-width 1200px) {
                  width: 445px;
                }
                @include breakpoint(max-width 1024px) {
                  height: 380px;
                  width: 100%;
                }
                @include breakpoint(max-width 500px) {
                  height: 285px;
                }
              }
            }
          }
          .group-how-it-works-text {
            margin-left: 94px;
            margin-right: 94px;
            @include breakpoint(max-width 1024px) {
              margin-left: 0;
              margin-right: 0;
              margin-top: 25px;
            }
          }
        }
      }
    }
    &-how-it-works-pre-title {
      font-size: 32px;
      line-height: 30px;
      letter-spacing: -0.64px;      
      margin-bottom: 20px;
      font-weight: bold;
      @include breakpoint(max-width 1200px) {
        font-size: 30px;
        line-height: 30px;
      }
      @include breakpoint(max-width 500px) {
        font-size: 24px;
        line-height: 24px;
      }
    }
    &-how-it-works-main-title {
      font-size: 22px;
      line-height: 34px;
      @include breakpoint(max-width 1200px) {
        font-size: 20px;
        line-height: 32px;
      }
      @include breakpoint(max-width 500px) {
        font-size: 16px;
        line-height: 28px;
      }
      
    }
    &-promo-whats-in-it-for-you {
      background: $lightBlue;
      margin-top: 370px;
      @include breakpoint(max-width 1400px) {
        margin-top: 190px;
      }
      @include breakpoint(max-width 850px) {
        margin-top: 75px;
      }
      .content {
        display: flex;
        align-items: center;
        max-width: 1360px;
        margin: 0 auto;
        @include breakpoint(max-width 700px) {
          flex-direction: column-reverse;
          margin-top: 0;
        }
      }       
      .field-group-html-element {
        width: 50%;
        position: relative;
        margin-top: -180px;
        @include breakpoint(max-width 700px) {
          margin-top: -70px;
          width: 100%;
        }
      }    
      .group-whats-for-you-text-group {
        max-width: 535px;
        width: 100%;
        margin-right: auto;
        margin-top: 30px;

        @include breakpoint(max-width 1580px) {
          padding-left: 20px;
        }
        @include breakpoint(max-width 700px) {
          max-width: 100%;
          padding-right: 20px;
        }

        h2 {
          font-size: 36px;
          line-height: 55px;
          font-weight: bold;
          margin-bottom: 20px;
          font-family: $mont;
          @include breakpoint(max-width 960px) {
            font-size: 32px;
            line-height: 51px;
          }
          @include breakpoint(max-width 500px) {
            font-size: 30px;
            line-height: 35px;
          }
        }
        p {
          font-size: 22px;
          line-height: 34px;
          @include breakpoint(max-width 960px) {
            font-size: 20px;
            line-height: 32px;
          }
          @include breakpoint(max-width 500px) {
            font-size: 16px;
            line-height: 28px;
          }
          
          &:last-of-type {
            max-width: 400px;
            img {
              display: inline-block;
              vertical-align: middle;
            }
          }
          
        }
      }
    }
    &-whats-for-you-floating-img {
      position: absolute;
      top: 140px;
      left: 0px;
      opacity: 0;
      transition: all 3s;
      &.anim {
        top: 40px;
        left: -40px;
        opacity: 1;
        @include breakpoint(max-width 1100px) {
          left: -80px;
        }
        @include breakpoint(max-width 1024px) {
          // left: -50px;
          width: 200px;
        }
        @include breakpoint(max-width 850px) {
          // left: -50px;
          // width: 200px;
          top: 10px;
          width: 160px;
        }
        @include breakpoint(max-width 700px) {
          left: 50%;
          width: 160px;
          transform: translateX(-160%);
        }
        @include breakpoint(max-width 370px) {
          width: 130px;
        }
      }
      @include breakpoint(max-width 700px) {
        left: 50%;
        width: 160px;
        transform: translateX(-140%);
      }
      @include breakpoint(max-width 370px) {
        width: 130px;
      }
    }
    &-whats-for-you-main-image {
      // display: none;
      width: 812px;
      height: 812px;
      background: #E4ECF0;
      border-radius: 50%;
      position: relative;
      overflow: hidden;
      position: relative;

      @include breakpoint(max-width 1650px) {
        width: 612px;
        height: 612px;
        margin: 0 auto;
      }
      @include breakpoint(max-width 1260px) {
        width: 512px;
        height: 512px;        
      }
      @include breakpoint(max-width 1024px) {
        width: 412px;
        height: 412px;        
      }
      @include breakpoint(max-width 850px) {
        width: 320px;
        height: 320px;        
      }


      img {
        position: absolute;
        right: -30px;
        top: 45%;
        transform: translateY(-50%);
      }
    }
  }
  .field--name-body {
    max-width: 1090px;
    margin: 150px auto 1px auto;
    font-size: 30px;
    line-height: 46px;
    font-family: $mont;
    text-align: center;
    @include breakpoint(max-width 1260px) {
      padding: 0 20px;
    }
    @include breakpoint(max-width 1024px) {
      margin-top: 100px;
      font-size: 25px;
      line-height: 41px;      
    }
    @include breakpoint(max-width 480px) {
      margin-top: 60px;
      font-size: 20px;
      line-height: 34px;      
    }
  }

  // how it works
  .group-how-it-works-text,
  .group-how-it-works-images {
    width: 50%;
    position: relative;
    @include breakpoint(max-width 1024px) {
      width: 450px;
    }
    @include breakpoint(max-width 450px) {
      width: 100%;
      padding: 0 20px;
    }
  }
  
  
  

  .node--full {
    .field-collection-view {
      margin-bottom: 0;
    }
  }
}

.promo-page {
  .research {
    &-wrapper {
      max-width: 1080px;
      width: 100%;
      margin: 0 auto;

    }
  }
  #research-newsletter {  
    background: #EDF5F8;
    padding-top: 115px;
    padding-bottom: 115px;
    .yellow {
      color: #7D2B6D;
    }
    @include breakpoint(max-width 1200px) {
      padding-top: 90px;
      padding-bottom: 90px;
    }
    h2 {
      font-size: 68px;
      text-align: center;
      letter-spacing: -1.36px;
      line-height: 78px;
      font-weight: bold;    
      @include breakpoint(max-width 1100px) {
        font-size: 52px;
        line-height: 62px;
        padding: 0 20px;
      }
      @include breakpoint(max-width 800px) {
        font-size: 48px;
        line-height: 58px;        
      }
      @include breakpoint(max-width 650px) {
        font-size: 40px;
        line-height: 50px;        
      }
      @include breakpoint(max-width 550px) {
        font-size: 35px;
        line-height: 45px;        
      }
      @include breakpoint(max-width 450px) {
        font-size: 30px;
        line-height: 40px;        
      }
    }
    .form-type-checkbox {
      max-width: 385px;
      margin: 0 auto;
      margin-top: 25px;
      padding-left: 0;
      align-items: center;
      display: flex;
      flex-direction: row-reverse;
      justify-content: center;
      overflow: hidden;
    }
    .terms-text {
      color: #4F4F4F;
      font-weight: bold;
      @include breakpoint(max-width 425px) {
        margin-left: 0;
      }
    }
    a {
      // color: #59A5E6;
      color: #145085;
    }
    .checkmark {
      border-radius: 8px;
      width: 32px;
      height: 32px;
      position: relative;
      margin-right: auto;
      @include breakpoint(max-width 700px) {
        margin-right: 20px;
      }
      &:after {
        left: 13px;
        top: 10px;
      }
    }
  }
  
  #promo-mailchimp {
    display: flex;
    align-items: center;
    margin-top: 68px;
    justify-content: center;
    @include breakpoint(max-width 920px) {
      flex-direction: column;
      margin-top: 40px;
    }
    #mergeTable {
      display: flex;
      align-items: center;
      width: 100%;  

      .field-group {
        position: relative;
      }
      @include breakpoint(max-width 1180px) {
        width: auto;
      }
      @include breakpoint(max-width 920px) {
        flex-direction: column;
        width: 320px;
        .field-group {
          margin-bottom: 10px;
        }
      }       
    }
    .mergeRow {    
      max-width: 400px;
      width: 100%;

      &-email {
        @include breakpoint(max-width 920px) {
          margin-top: 20px;
        }
      }

      select,
      input { 
        height: 58px;
        width: 100%; 
        border-radius: 36px;
        border: none;
        padding: 0 28px;
        font-size: 16px;
        font-family: $mont;
        @include breakpoint(max-width 920px) {
          height: 50px;
        }
      }
      &-email,
      &-text {
        margin-right: 15px;
        @include breakpoint(max-width 920px) {
          margin-right: 0;
        }
      }    
    }
    .formEmailButton {
      margin-top: 33px;
      border: none;
      @include breakpoint(max-width 920px) {
        width: 320px;
        height: 50px;
        line-height: 1px;
      }
    }    
    input[disabled=''][type=submit] {
      background:#ddd;
      &:hover {
        cursor: default;
        color: #100a0f;
      }
    }
  }
  
  
  footer {
    
    display: flex;
    align-items: center;
    max-width: 1360px;
    width: 100%;
    margin: 0 auto;
    padding: 60px 0;
    @include breakpoint(max-width 1140px) {
      padding: 60px 20px;      
    }
    @include breakpoint(max-width 1200px) {
      flex-direction: column;
      padding: 20px;
    }

    .illustrations,
    .copyright {
      @include breakpoint(max-width 1200px) {
        margin-bottom: 20px;
        margin-left: 0;
        text-align: center;
      }
    }
    .policy-links {
      @include breakpoint(max-width 1200px) {
        margin-left: 0;        
      }
      @include breakpoint(max-width 400px) {
        display: flex;
        flex-direction: column;
        text-align: center;
        span {
          margin-bottom: 20px;
        }
      }
    }

    .bold {
      font-weight: bold;
    }
    a {
      color: #000;
    }
    * {
      font-size: 14px;
      font-family: $mont;
    }
    .design-by {
      display: flex;
      align-items: center;
      margin-left: auto;
      @include breakpoint(max-width 1200px) {
        margin: 20px auto;
      }
      span {
        line-height: 1;
        display: inline-block;
      }
      img {
        height: 23px;
        margin-left: 3px;
      }
    }
  }
  
  
  
  .slider-wrapper {
    border-radius: 50px;
    width: 470px;
    // width: 65%;
    height: 100px;
    display: flex;
    align-items: center;
    z-index: 4;
    justify-content: center;
    box-sizing: border-box;  
    margin: 0 auto;
    background: white;
    // position: relative;
    position: absolute;
    bottom: 100px;

    padding: 15px 30px;
    // padding-top: 0;
    left: 50%;
    transform: translateX(-50%);
  
    .wrapper {
      position: relative;     
      display: flex;
      flex-direction: column;
      width: 266px;

    }
    .rate {
      font-size: 16px;
      position: absolute;
      top: -22px;
    }
    

    @include breakpoint(max-width 850px) {
      height: 80px;
      .rate {
        font-size: 14px;
      }
    } 
    @include breakpoint(max-width 700px) {
      // height: 70px;
      // padding: 5px 20px;
      bottom: 50px;
      .wrapper {
        top: 0;
        padding: 0 5px;
        
      }
      
    } 
    @include breakpoint(max-width 360px) {
      .wrapper {
        max-width: 200px;
      }
    }
    
    @include breakpoint(max-width 550px) {
      width: 400px;
      bottom: 20px;
    } 
    @include breakpoint(max-width 450px) {
      width: calc(100% - 40px);
      padding: 14px 20px 10px 20px;
    }
    @include breakpoint(max-width 360px) {
      // height: 50px;
      width: calc(100% - 20px);
    } 

    .image-wrapper {
      width: 56px;
      height: 56px;
      display: flex;
      @include breakpoint(max-width 700px) {
        width: 46px;
        height: 46px;
      }
      @include breakpoint(max-width 550px) {
        width: 36px;
        height: 36px;
      } 
      @include breakpoint(max-width 400px) {
        width: 31px;
        height: 31px;
      }
      &.first {
        margin-right: auto;
      }
      &.last {
        margin-left: auto;
      }
      // img {
      //   width: 35px;
      //   margin: auto;
      //   height: 35px;
      // }
    }
  }
  // .rangeslider__fill {
  //   width: 100%;
  //   height: 7px;
  //   background: transparent linear-gradient(270deg, #00ADFA 0%,     #00ADFA 50%, #ED3EAA 100%);
  // }
  // #slider-js {
  //   width: 100%;
  //   height: 7px;
  //   background: transparent linear-gradient(270deg, #00ADFA 0%,     #00ADFA 50%, #ED3EAA 100%);
  //   position: relative;
  //   border-radius: 3px;
  //   cursor: pointer;
  // }
  // #slider-handle {
  //   position: absolute;
  //   left: 0px;
  //   height: 30px;
  //   width: 30px;
  //   box-shadow: 0px 2px 10px rgba(48, 56, 77, 0.5);
  //   border-radius: 50%;
  //   background-color: white;
  //   top: -12px;
  //   transition: 0.2s;
  // }
  
  
  input[type=range] {
    -webkit-appearance: none;
    margin: 10px 0;
    width: 100%;
    max-width: 265px;
    @include breakpoint(max-width 550px) {
      // max-width: 165px;
      width: 100%;
    }
    @include breakpoint(max-width 360px) {
      width: 196px;
    }
  }
  input[type=range]:focus {
    outline: none;
  }
  input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: transparent linear-gradient(270deg, #00ADFA 0%,     #00ADFA 50%, #ED3EAA 100%);
    border-radius: 25px;
    // border: 0px solid #000101;
    @include breakpoint(max-width 550px) {
      height: 4px;
    }
  }
  input[type=range]::-webkit-slider-thumb {
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    // border: 1px solid #000000;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: white;
    border: 1px solid #707070;
    background: white;
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -5.6px;
    @include breakpoint(max-width 550px) {
      margin-top: -7.6px;
    }
  }
  input[type=range]:focus::-webkit-slider-runnable-track {
    background: transparent linear-gradient(270deg, #00ADFA 0%,     #00ADFA 50%, #ED3EAA 100%);
  }
  input[type=range]::-moz-range-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    background: transparent linear-gradient(270deg, #00ADFA 0%,     #00ADFA 50%, #ED3EAA 100%);
    border-radius: 25px;
    // border: 0px solid #000101;
    @include breakpoint(max-width 550px) {
      height: 4px;
    }
  }
  input[type=range]::-moz-range-thumb {
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    // border: 0px solid #000000;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: white;
    border: 1px solid #707070;
    cursor: pointer;
  }
  input[type=range]::-ms-track {
    width: 100%;
    height: 8px;
    cursor: pointer;
    animate: 0.2s;
    background: transparent;
    border-color: transparent;
    border-width: 39px 0;
    color: transparent;
    @include breakpoint(max-width 550px) {
      height: 4px;
    }
  }
  input[type=range]::-ms-fill-lower {
    background:  transparent linear-gradient(270deg, #00ADFA 0%,     #00ADFA 50%, #ED3EAA 100%);
    // border: 0px solid #000101;
    border-radius: 50px;
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-fill-upper {
    background:  transparent linear-gradient(270deg, #00ADFA 0%,     #00ADFA 50%, #ED3EAA 100%);
    // border: 0px solid #000101;
    border-radius: 50px;
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
  }
  input[type=range]::-ms-thumb {
    // box-shadow: 0px 0px 0px #000000, 0px 0px 0px #0d0d0d;
    // border: 0px solid #000000;
    height: 22px;
    width: 22px;
    border-radius: 50%;
    background: white;
    border: 1px solid #707070;
    border-radius: 8px;
    background:white;
    cursor: pointer;
  }
  input[type=range]:focus::-ms-fill-lower {
    background: transparent linear-gradient(270deg, #00ADFA 0%,     #00ADFA 50%, #ED3EAA 100%);
  }
  input[type=range]:focus::-ms-fill-upper {
    background: transparent linear-gradient(270deg, #00ADFA 0%,     #00ADFA 50%, #ED3EAA 100%);
  }
  
}





@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.field--name-field-promo-floating-image-left {
  -webkit-animation: rotating 8s linear infinite;
  -moz-animation: rotating 8s linear infinite;
  -ms-animation: rotating 8s linear infinite;
  -o-animation: rotating 8s linear infinite;
  animation: rotating 8s linear infinite;
}