// Google Fonts, Montserrat font
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&display=swap");
//** Variables

// Fonts
$mont: "Montserrat", "Arial", sans-serif;

// Colours
$bg: #e7eded;
$primary-blue: #0c4f8e;
$uxp-yellow: #ffc31e;
$gray-border: #adc0d1;
$gray-form: #939393;
$green: #52ed93;
$light-blue: #1f80db;
$drop-shadow: 0px 3px 20px #00000019;

// Containers
$main-container: 1640px;
$main-container-responsive: 98.2%;
$pd-res: 0 16px;
$susy: (
  columns: susy-repeat(12),
  gutters: 32px,
  spread: "narrow",
  container-spread: "narrow",
);

@mixin gradient {
  background: linear-gradient(219deg, $light-blue, $green);
}
@mixin gradient-2 {
  background: linear-gradient(226deg, $light-blue, $green);
}

// *** Responsiveness ***
// Media Queries
// Variables
$bp-medium-screen: 1025px;
// $bp-large-screen: 1680px;
$bp-large-screen: 1640px;
$bp-cards-large: 1500px;
$bp-13: 1358px;
$bp-large-medium: 1200px;
$bp-medium: 1080px;
$bp-small-medium: 1024px;
$bp-big-tablet: 850px;
$bp-tablet: 768px;
$bp-small: 600px;
$bp-5: 575px;
$bp-mobile: 415px;
$bp-mobile-sm: 359px;

.field--name-field-caption {
  @media screen and (max-width: $bp-small) {
    display: none;
  }
}

// Contact Form Modal
.pgwModal {
  .pm-body {
    .pm-content {
      display: block !important;
    }
  }
}

.node-type-accessibility {

  * {
    &:focus {
      outline-style: solid !important;
      outline-color: #7D2B6D !important;
      outline-width: 2px !important;
    }
  }

  #edit-submit:disabled {
    background: #8e8e8e;
    // cursor: not-allowed;
    &:hover {
      @include breakpoint(min-width 1080px) {
        cursor: url('../images/hov.png'), auto !important;
      }      
    }
    @include breakpoint(1024px) {
      cursor: default;
    }
  }
  .error {
    border: 1px solid red !important;
  }

  .group-our-team {
    position: relative;
    z-index: 0;
  }
  // **Navbar
  .l-header-wrapper {
    background: transparent;
    box-shadow: $drop-shadow;
    // background: #ffffffe7;
    background: rgba(255, 255, 255, 0.7);
    backdrop-filter: blur(10px);
  }
  .l-header {
    height: 100px;
    display: flex;
    align-items: center;
    max-width: $main-container;
    margin: 0 auto;
    z-index: 999;
    position: relative;
    @media screen and (max-width: $bp-large-screen) {
      max-width: $main-container-responsive;
      padding: $pd-res;
      margin: 0 auto;
    }
    @media screen and (max-width: $bp-small) {
      height: 80px;
    }
    @media screen and (max-width: $bp-mobile) {
      height: 60px;
    }
    .l-branding {
      @media screen and (max-width: $bp-medium) {
        min-width: 140px;
      }
      @media screen and (max-width: $bp-small) {
        min-width: 130px;
      }
    }
    .l-region--header {
      position: relative;
      display: flex;
      justify-content: flex-end;
      ul {
        display: flex;
        @media screen and (max-width: 1552px) {
          margin-right: -18px;
        }
        @media screen and (max-width: $bp-medium) {
          margin-right: 0;
        }
        > li {
          display: flex;
          align-items: center;
          margin-right: 54px;
          @media screen and (max-width: 1366px) {
            margin-right: 20px;
          }
          a {
            font-size: 16px;
            @media screen and (max-width: 1250px) {
              margin-right: 25px;
            }
          }
          @media screen and (max-width: 980px) {
            visibility: hidden;
          }
          &:last-child {
            margin-right: 0;
          }

          &.contact-nav {
            a {
              color: white;
              background: $primary-blue;
              width: 344px;
              height: 59px;
              font-size: 16px;
              border-radius: 8px;
              line-height: 59px;
              text-align: center;
              cursor: pointer;
              @media screen and (max-width: 1366px) {
                width: 310px;
                height: 50px;
                line-height: 50px;
              }
              @media screen and (max-width: $bp-medium) {
                visibility: visible;
                margin-right: -80px;
              }
              @media screen and (max-width: $bp-small) {
                margin-right: -89px;
                font-size: 12px;
                width: 265px;
                height: 48px;
                line-height: 49px;
              }
              @media screen and (max-width: 480px) {
                margin-right: -98px;
                font-size: 11px;
                line-height: 48px;
                width: 228px;
                height: 45px;
              }
              @media screen and (max-width: $bp-mobile) {
                width: 182px;
                height: 35px;
                font-size: 9px;
                line-height: 36px;
              }
              @media screen and (max-width: $bp-mobile-sm) {
                width: 120px;
                height: auto;
                font-size: 9px;
                line-height: 14px;
                padding: 8px 10px;
              }
            }
          }

          a {
            text-decoration: none;
            color: black;
            font-weight: bold;
            // @include font_size(16);
            margin: 0 auto;
          }
        }
      }
    }
  }

  .l-main {
    max-width: 100%;
    // overflow-x: hidden;
    @include breakpoint(1600px) {
      max-width: 100%;
      overflow-x: hidden;
      margin: 0;
    }
  }

  .node--accessibility {
    .field-collection-view {
      margin: 0;
    }
    @media screen and (max-width: $bp-medium) {
      .node__content {
        width: 100%;
        margin: 0 auto;
      }
    }

    h1,
    h2,
    h3,
    h4,
    p,
    a {
      font-family: $mont;
    }

    p {
      // @include font_size(18);
      font-size: 18px;
      font-weight: normal;
      @media screen and (max-width: $bp-mobile) {
        font-size: 14px;
      }
    }

    h1,
    h2 {
      // @include font_size(46);
      font-size: 46px;
      font-weight: bold;
      line-height: 60px;
      @media screen and (max-width: 1820px) {
        font-size: 44px;
        line-height: 58px;
      }
      @media screen and (max-width: 1720px) {
        font-size: 42px;
        line-height: 56px;
      }
      @media screen and (max-width: $bp-large-screen) {
        font-size: 40px;
        line-height: 52px;
      }
      @media screen and (max-width: $bp-cards-large) {
        font-size: 36px;
        line-height: 50px;
      }
      @media screen and (max-width: $bp-13) {
        font-size: 34px;
        line-height: 48px;
      }
      @media screen and (max-width: $bp-large-medium) {
        font-size: 32px;
        line-height: 46px;
      }
      @media screen and (max-width: $bp-medium) {
        font-size: 30px;
        line-height: 44px;
      }

      @media screen and (max-width: $bp-tablet) {
        font-size: 28px;
        line-height: 42px;
      }
      @media screen and (max-width: $bp-small) {
        font-size: 26px;
        line-height: 40px;
      }
      @media screen and (max-width: $bp-mobile) {
        font-size: 22px;
        line-height: 30px;
      }
      @media screen and (max-width: 360px) {
        font-size: 20px;
        line-height: 28px;
      }
    }

    h3 {
      // @include font_size(18);
      font-size: 18px;
      font-weight: bold;
      @media screen and (max-width: $bp-mobile) {
        font-size: 16px;
      }
    }
    h4 {
      // @include font_size(16);
      font-size: 16px;
      font-weight: bold;
    }

    img {
      display: block;
    }

    .blue-text {
      color: $primary-blue;
    }

    .field-collection-container {
      &.clearfix {
        margin-bottom: 0;
      }
    }
    scroll-behavior: smooth;

    // **Hero Section
    .group-hero-section {
      // padding-bottom: 100px;
      background-color: $bg;
      position: relative;
      &::before {
        content: "";
        position: absolute;
        background: url("../images/sprites/big-shape.png") no-repeat;
        top: 24px;
        right: 0;
        background-size: cover;
        width: 100%;
        height: 910px;
        background-position: center;
        @media screen and (max-width: 2652px) {
          width: 98%;
          top: 0;
        }
        @media screen and (max-width: 1720px) {
          width: 100%;
          top: -100px;
        }
        @media screen and (max-width: $bp-medium) {
          right: -79px;
          height: 602px;
          top: -20px;
        }
        @media screen and (max-width: 880px) {
          top: 100px;
          right: 0;
          height: 910px;
        }
        @media screen and (max-width: $bp-small) {
          top: -33px;
        }
        @media screen and (max-width: $bp-mobile) {
          background: url("../images/sprites/small-shape.png") center no-repeat;
          top: -186px;
        }
        @media screen and (max-width: $bp-mobile) {
          background: url("../images/sprites/small-shape.png") center no-repeat;
          top: -130px;
          background-size: cover;
        }
      }

      .group-container-hero {
        max-width: $main-container;
        margin: 0 auto;
        position: relative;
        @media screen and (max-width: $bp-large-screen) {
          max-width: $main-container-responsive;
          padding: $pd-res;
          margin: 0 auto;
        }

        &::before {
          content: "";
          position: absolute;
          width: 232px;
          height: 132px;
          background: transparent;
          top: -5px;
          border: 2px solid white;
          left: 364px;
          border-bottom-left-radius: 162px;
          border-bottom-right-radius: 162px;
          border-top: none;
          @media screen and (max-width: $bp-medium) {
            display: none;
          }
        }
        &::after {
          content: "";
          position: absolute;
          width: 23px;
          height: 23px;
          background: $uxp-yellow;
          top: 54px;
          left: 364px;
          border-radius: 50%;
          @media screen and (max-width: $bp-medium) {
            display: none;
          }
        }

        .field--name-field-main-title {
          width: 40%;
          padding: 15% 0;
          @media screen and (max-width: 1780px) {
            width: 45%;
          }
          @media screen and (max-width: $bp-medium) {
            width: 50%;
          }
          @media screen and (max-width: 880px) {
            width: 85%;
            margin: 0 auto;
            // margin-bottom: 260px;
            text-align: center;
            padding: 100px 0 45px;
          }
          @media screen and (max-width: $bp-small) {
            width: 100%;
            padding: 50px 0 40px;
          }
        }
      }

      .group-text-caption-group {
        max-width: $main-container;
        margin: 0 auto;
        position: relative;
        z-index: 4;
        padding-bottom: 194px;
        @media screen and (max-width: 1775px) {
          // max-width: $main-container-responsive;
          // margin: 0 auto;
          margin-left: 20px;
        }
        @media screen and (max-width: $bp-medium) {
          padding-bottom: 125px;
        }
        @media screen and (max-width: $bp-small) {
          padding-bottom: 100px;
        }
        @media screen and (max-width: $bp-mobile) {
          padding-bottom: 85px;
        }

        > .group-white-container {
          position: relative;
          height: 400px;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background: #ffffff;
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
          padding-left: 8%;
          @media screen and (max-width: 880px) {
            height: auto;
            background: transparent;
            padding: 0;
            align-items: center;
          }
          &::before {
            content: "";
            position: absolute;
            width: 124px;
            height: 124px;
            border: 2px solid #ffc31e;
            bottom: 0;
            top: -15%;
            left: -4%;
            border-radius: 50%;
            z-index: -1;
            @media screen and (max-width: 1775px) {
              display: none;
            }
          }

          &:after {
            content: "";
            position: absolute;
            top: 0;
            right: calc((-100vw + 1640px) / 2);
            width: calc((100vw - 1640px) / 2);
            height: 400px;
            background: #ffffff;
            @media screen and (min-width: 1680px) and (max-width: 1775px) {
              width: calc((100vw - 1450px) / 2);
              margin-right: -67px;
              z-index: -1;
            }
            @media screen and (max-width: 880px) {
              display: none;
            }
          }
          .group-text-container {
            @media screen and (max-width: 880px) {
              order: 2;
              background: white;
              padding: 20px 40px;
              border-top-left-radius: 16px;
              border-bottom-left-radius: 16px;
              /* position: relative; */
              top: -100px;
              z-index: 5;
              margin-top: -100px;
              height: 280px;
              display: flex;
              flex-direction: column;
              justify-content: center;
            }
            @media screen and (max-width: $bp-mobile) {
              padding: 30px 35px 20px;
              height: 259px;
            }
            @media screen and (max-width: $bp-mobile-sm) {
              // height: 315px;
            }

            .field--name-body {
              width: 45%;
              @media screen and (max-width: 1270px) {
                width: 40%;
              }
              @media screen and (max-width: 880px) {
                width: 100%;
                position: relative;
              }
              > .field__items {
                @media screen and (max-width: $bp-large-screen) {
                  padding-left: 5px;
                }
                @media screen and (max-width: 880px) {
                  padding-left: 40px;
                }
              }
              p {
                // @include font_size(32);
                font-size: 32px;
                font-weight: lighter;
                padding-bottom: 30px;
                line-height: 42px;
                @media screen and (max-width: 1260px) {
                  font-size: 25px;
                  line-height: 32px;
                }
                @media screen and (max-width: $bp-tablet) {
                  font-size: 22px;
                }
                @media screen and (max-width: $bp-mobile) {
                  font-size: 20px;
                  line-height: 28px;
                }
                @media screen and (max-width: $bp-mobile-sm) {
                  font-size: 17px;
                }
              }
              &::before {
                content: "";
                position: absolute;
                width: 25px;
                height: 20px;
                // background: url("../images/sprites/quote.png") no-repeat center;
                // top: 25%;
                // left: 5%;
                @include sprite($sprite-quote);
                top: 83px;
                left: 5%;
                @media screen and (max-width: $bp-medium) {
                  left: 4%;
                }
                @media screen and (max-width: 880px) {
                  top: 10px;
                  left: 0;
                }
              }
            }
            .field--name-field-caption {
              @media screen and (max-width: $bp-large-screen) {
                padding-left: 5px;
              }
              @media screen and (max-width: 880px) {
                padding-left: 40px;
                margin-top: -15px;
                display: block;
              }
              p {
                // @include font_size(16);
                font-size: 16px;
                line-height: 22px;
                @media screen and (max-width: 880px) {
                  font-size: 14px;
                }
                @media screen and (max-width: $bp-mobile) {
                  font-size: 12px;
                }
              }
            }
          }
          .field--name-field-image {
            position: absolute;
            bottom: 0;
            right: 0;
            @media screen and (max-width: $bp-large-screen) {
              width: 50%;
            }
            @media screen and (max-width: 1220px) {
              width: 55%;
            }
            @media screen and (max-width: $bp-medium) {
            }
            @media screen and (max-width: 940px) {
              right: 0;
            }
            @media screen and (max-width: 880px) {
              position: static;
              order: 1;
              width: 60%;
            }
            @media screen and (max-width: $bp-tablet) {
              width: 65%;
            }
            @media screen and (max-width: $bp-small) {
              width: 75%;
            }
            @media screen and (max-width: $bp-5) {
              width: 75%;
            }
            @media screen and (max-width: 470px) {
              width: 90%;
            }

            @media screen and (max-width: $bp-mobile) {
              width: 95%;
            }
            @media screen and (max-width: 360px) {
              width: 100%;
            }
            img {
              display: block;
              width: 100%;
              // @media screen and (max-width: $bp-13) {
              //   width: 90%;
              // }
              // @media screen and (max-width: 1270px) {
              //   width: 80%;
              // }
              // @media screen and (max-width: $bp-medium) {
              //   width: 70%;
              //   margin: 0 auto;
              // }
            }
          }
        }
      }
    }

    // **Disabilities Section

    .field-collection-item-field-disabilities {
      background-color: $bg;
      padding-bottom: 490px;
      @media screen and (max-width: $bp-medium) {
        padding-bottom: 430px;
      }
      @media screen and (max-width: $bp-tablet) {
        padding-bottom: 360px;
      }
      @media screen and (max-width: $bp-small) {
        padding-bottom: 300px;
      }
      @media screen and (max-width: $bp-mobile) {
        padding-bottom: 215px;
      }
      @media screen and (max-width: $bp-mobile-sm) {
        padding-bottom: 190px;
      }

      .group-disabilities-container {
        max-width: $main-container;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: $bp-large-screen) {
          max-width: $main-container-responsive;
          padding: $pd-res;
          margin: 0 auto;
        }
        @media screen and (max-width: $bp-medium) {
          flex-direction: column;
          align-items: center;
        }
        .group-left-div {
          position: relative;
          left: calc((-100vw + 1640px) / 2);
          margin-right: 4vw;
          // margin-left: -10%;
          margin-left: -9.4%;
          width: 53vw;

          @media screen and (max-width: $bp-large-screen) {
            left: 0;
            // margin-left: -17.4%;
            margin-left: -11.3%;
            width: 58vw;
          }
          @media screen and (max-width: $bp-medium) {
            margin-left: -31.4%;
            width: 95vw;
          }
          @media screen and (max-width: $bp-tablet) {
            margin-left: -28.4%;
            width: 100vw;
          }
          @media screen and (max-width: $bp-small) {
            margin-left: -29.4%;
            width: 100vw;
          }
          @media screen and (max-width: $bp-mobile) {
            margin-left: -30%;
            width: 100vw;
            // margin-bottom: 10px;
          }
          .field--name-field-image-2 {
            z-index: 40;
            position: absolute;
            right: 0;
            top: 0;
            width: 21vw;
            @media screen and (max-width: $bp-medium) {
              width: 32vw;
            }
            @media screen and (max-width: $bp-mobile) {
              width: 40vw;
            }
          }

          .field--name-field-image {
            z-index: 30;
            position: relative;
            margin-top: 6%;
            &:before {
              content: "";
              position: absolute;
              background: url(../images/sprites/arrow_01.svg) no-repeat center;
              width: 80px;
              height: 94px;
              right: 70%;
              bottom: -1.2%;
              @media screen and (max-width: 1820px) {
                bottom: -1.5%;
              }
              @media screen and (max-width: $bp-cards-large) {
                bottom: -2.5%;
              }
              @media screen and (max-width: 1300px) {
                bottom: -3.5%;
              }
              @media screen and (max-width: $bp-large-medium) {
                bottom: -4.5%;
              }
              @media screen and (max-width: $bp-medium) {
                display: none;
              }
            }
          }
          .field--name-field-caption {
            width: 260px;
            position: absolute;
            margin-top: 18px;
            margin-left: 29%;
            @media screen and (max-width: $bp-medium) {
              display: none;
            }
            .field__item {
              p {
                // @include font_size(16);
                font-size: 16px;
              }
            }
          }
        }

        .group-right-div {
          width: 43vw;
          margin-top: 15%;
          margin-left: -10%;
          @media screen and (max-width: $bp-medium) {
            margin: 0 auto;
            width: 100%;
          }

          .field--name-field-main-title {
            padding-bottom: 30px;
            @media screen and (max-width: $bp-medium) {
              padding-top: 20px;
              width: 100%;
              padding-bottom: 20px;
            }
          }
          .field--name-field-body {
            padding-bottom: 60px;
            p {
              // @include font_size(24);
              font-size: 24px;
              line-height: 36px;
              max-width: 85%;
              // padding-bottom: 60px;
              @media screen and (max-width: $bp-13) {
                max-width: 100%;
                font-size: 20px;
              }
              @media screen and (max-width: $bp-medium) {
                max-width: 95%;
                font-size: 18px;
              }
              @media screen and (max-width: $bp-small) {
                max-width: 100%;
                font-size: 16px;
                line-height: 28px;
              }
            }
          }
        }
        .field--name-field-cards-group-title {
          padding-bottom: 20px;
        }
        .field--name-field-collection-cards {
          > .field__items {
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            // @media screen and (max-width: $bp-small) {
              // flex-direction: column;
              // flex-wrap: nowrap;
              // align-items: center;
            // }
            @media screen and (max-width: $bp-medium) {
              flex-wrap: wrap;
              // justify-content: center;
            }

            > .field__item {
              width: 49%;
              width: 32%;
              margin-right: 2%;
              margin-bottom: 1.5%;

              @media screen and (max-width: $bp-medium) {
                width: 48%;
                margin-bottom: 1.5%;
                margin-right: 1.5%;
              }
              @media screen and (max-width: $bp-tablet) {
                margin-right: 1.5%;
              }
              @media screen and (max-width: $bp-small) {
                // width: 100%;
                // margin-bottom: 10px;
                // margin-right: 0;
              }

              .content {
                display: flex;
                align-items: center;
                padding: 16px 15px;
                background: white;
                border-radius: 12px;
                height: 102px;
                @media screen and (max-width: 1792px) {
                  padding: 18px 15px;
                  height: 108px;
                }
                @media screen and (max-width: 1640px) {
                  height: 130px;
                }
                @media screen and (max-width: 1250px) {
                  padding: 10px;
                  height: 165px;
                }
                @media screen and (max-width: $bp-medium) {
                  padding: 16px 15px;
                  height: 135px;
                }
                @media screen and (max-width: $bp-small) {
                  padding: 16px 15px;
                  height: auto;
                }
              }

              &:nth-child(3n) {
                margin-right: 0;
                @include breakpoint(1080px) {
                  margin-right: 1.5%;
                }
              }
              &:nth-child(2n) {
                @include breakpoint(1080px) {
                  margin-right: 1.5% ;
                }
              }
            }
            .group-icon-title {
              min-width: 63px;
              @include breakpoint(1600px) {
                min-width: auto;
              }
              @include breakpoint(1080px) {
                min-width: 63px;
              }
              @include breakpoint(450px) {
                min-width: 20px;
                max-width: 40px;
                img {
                  max-width: 60%;
                }
              }
            }
            .field--name-field-icon {
              margin-right: 15px;
              flex-shrink: 0;
              @include breakpoint(1400px) {
                margin-right: 7px;
              }
              @include breakpoint(450px) {
                margin-right: 0;
              }
              
            }
            .group-text-group {
              width: 80%;
              .field--name-field-main-title {
                padding-bottom: 0;
                padding-top: 0;
                h3 {
                  @include breakpoint(1600px) {
                    font-size: 14px;
                  }
                }
              }
              .field--name-field-cards-body {
                // @include font_size(18);
                // padding-bottom: 0;
                // line-height: 24px;
                display: none;
              }
            }
          }
        }
      }
    }

    // **Services Section
    .field-collection-item-field-services {
      .group-services-container {
        max-width: $main-container;
        margin: 0 auto;
        padding-bottom: 160px;
        
        @media screen and (max-width: $bp-large-screen) {
          max-width: $main-container-responsive;
          margin: 0 auto;
          padding: $pd-res;
          padding-bottom: 175px;
        }
        @media screen and (max-width: $bp-13) {
          padding-bottom: 145px;
        }
        @media screen and (max-width: $bp-medium) {
          padding-bottom: 200px;
        }
        @media screen and (max-width: $bp-tablet) {
          padding-bottom: 180px;
        }
        @media screen and (max-width: $bp-small) {
          padding-bottom: 160px;
        }
        @media screen and (max-width: $bp-mobile) {
          padding-bottom: 120px;
        }

        .group-top-container {
          display: flex;
          justify-content: space-between;
          @media screen and (max-width: $bp-medium) {
            flex-direction: column-reverse;
            align-items: center;
            padding-top: 190px;
          }
          .group-left-div {
            width: 43vw;
            margin-top: 13%;
            @media screen and (max-width: $bp-medium) {
              margin: 0 auto;
              width: 100%;
            }

            .field--name-field-main-title {
              // padding-top: 40%;
              padding-bottom: 30px;
              width: 80%;
              @media screen and (min-width: $bp-medium) and (max-width: $bp-large-medium) {
                width: 100%;
              }
              @media screen and (max-width: $bp-medium) {
                padding-top: 20px;
                width: 100%;
                padding-bottom: 20px;
              }
              @media screen and (max-width: $bp-small) {
                // padding-top: 10px;
              }
            }
            .field--name-field-body {
              p {
                // @include font_size(24);
                font-size: 24px;
                line-height: 36px;
                max-width: 85%;
                padding-bottom: 60px;
                @media screen and (max-width: $bp-13) {
                  max-width: 100%;
                  font-size: 20px;
                }
                @media screen and (max-width: $bp-medium) {
                  max-width: 95%;
                  font-size: 18px;
                  padding-bottom: 68px;
                }

                @media screen and (max-width: $bp-small) {
                  max-width: 100%;
                  font-size: 16px;
                  line-height: 28px;
                  padding-bottom: 40px;
                }
              }
            }
          }
          .group-right-div {
            position: relative;
            right: calc((-100vw + 1640px) / 2);
            width: 68vw;
            // margin-left: -4vw;
            margin-left: 0;
            // margin-right: -10%;
            margin-right: -23%;
            // margin-top: -19.5%;
            margin-top: -320px;
            @media screen and (max-width: $bp-large-screen) {
              right: 0;
              width: 58vw;
              margin-left: 0;
              margin-right: -12%;
            }

            @media screen and (max-width: $bp-medium) {
              position: static;
              width: 95vw;
              margin-right: -51%;
              margin-top: -503px;
            }
            // @media screen and (max-width: $bp-tablet) {
            //   position: static;
            //   width: 81vw;
            //   margin-right: -49%;
            // }
            @media screen and (max-width: $bp-tablet) {
              width: 100vw;
              margin-right: -37%;
              margin-top: -443px;
            }
            @media screen and (max-width: $bp-small) {
              width: 100vw;
              margin-right: -33%;
              margin-top: -390px;
            }
            @media screen and (max-width: $bp-mobile) {
              width: 100vw;
              margin-right: -37%;
              margin-top: -329px;
            }
            .field--name-field-caption {
              width: 260px;
              position: absolute;
              left: -230px;
              top: 185px;
              z-index: 6;
              // @media screen and (max-width: 1778px) {
              //   top: 200px;
              // }
              @media screen and (max-width: $bp-cards-large) {
                left: -255px;
              }
              @media screen and (max-width: $bp-large-medium) {
                left: -265px;
              }
              @media screen and (max-width: $bp-medium) {
                display: none;
              }
              .field__items {
                p {
                  text-align: right;
                  // @include font_size(16);
                  font-size: 16px;
                }
              }
            }

            .field--name-field-image {
              &:before {
                content: "";
                position: absolute;
                width: 94px;
                height: 49px;
                background: url(../images/sprites/arrow_02.svg) no-repeat center;
                top: 15%;
                left: 2.8%;
                @media screen and (max-width: $bp-cards-large) {
                  left: 1.8%;
                }
                @media screen and (max-width: $bp-13) {
                  left: -0.2%;
                }
                @media screen and (max-width: 1300px) {
                  left: -0.8%;
                }
                @media screen and (max-width: 1238px) {
                  left: -2.8%;
                }
                @media screen and (max-width: 1174px) {
                  left: -3.8%;
                }
                @media screen and (max-width: $bp-medium) {
                  display: none;
                }
              }
            }
          }
        }
        .field--name-field-collection-cards {
          margin-top: 8%;
          @media screen and (max-width: $bp-tablet) {
            margin-top: 40px;
          }
          @media screen and (max-width: $bp-small) {
            margin-top: 0;
          }
          > .field__items {
            display: flex;
            justify-content: flex-end;
            flex-wrap: wrap;

            @media screen and (max-width: $bp-medium) {
              justify-content: center;
            }
            @media screen and (max-width: $bp-small) {
              flex-wrap: nowrap;
              flex-direction: column;
            }
            > .field__item {
              width: 24.25%;
              position: relative;
              margin-right: 1%;
              border: 1px solid $bg;
              border-radius: 12px;
              padding: 5% 2% 2%;
              @media screen and (max-width: $bp-cards-large) {
                width: 31.33%;
                margin-bottom: 8%;
                margin-left: 1%;
              }
              @media screen and (max-width: $bp-medium) {
                width: 42%;
                margin: 0 15px 115px;
              }
              @media screen and (max-width: $bp-tablet) {
                width: 70%;
                padding: 5% 2%;
              }
              @media screen and (max-width: $bp-small) {
                width: 100%;
                border: none;
                margin-bottom: 20px;
                padding: 0;
                margin-left: 0;
              }
              @media screen and (min-width: $bp-cards-large) {
                &:nth-child(3n + 4) {
                  margin-right: 0;
                }

                &:nth-child(-n + 4) {
                  margin-bottom: 7%;
                }
              }
              &::before {
                content: "";
                position: absolute;
                height: 100px;
                width: 42%;
                background: white;
                top: -62px;
                z-index: 10;
                left: 0;
                right: 0;
                margin: 0 auto;
                @media screen and (max-width: $bp-tablet) {
                  width: 36%;
                }
                @media screen and (max-width: $bp-small) {
                  display: none;
                }
              }
              .content {
                @media screen and (max-width: $bp-small) {
                  display: flex;
                  align-items: center;
                }
              }
              .group-icon-title {
                @media screen and (max-width: $bp-small) {
                  display: flex;
                  justify-content: flex-start;
                  align-items: center;
                }
                .field--name-field-icon {
                  width: 100px;
                  height: 100px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  position: absolute;
                  top: -50px;
                  left: 0;
                  right: 0;
                  margin: 0 auto;
                  @include gradient;
                  border-radius: 50%;
                  z-index: 12;
                  @media screen and (max-width: $bp-small) {
                    margin: 0 12px 0 0;
                    width: 69px;
                    height: 69px;
                    position: static;
                  }
                  .field__items {
                    background: #ffffff;
                    border-radius: 50%;
                    width: 96px;
                    height: 96px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    @media screen and (max-width: $bp-small) {
                      width: 65px;
                      height: 65px;
                      img {
                        margin: 0 auto;
                        width: 67%;
                      }
                    }
                  }
                }
              }
              .group-text-group {
                .field--name-field-main-title {
                  padding-bottom: 4%;
                  text-align: center;
                  @media screen and (max-width: $bp-large-screen) {
                    padding: 20px 5px 20px;
                  }
                  @media screen and (max-width: $bp-medium) {
                    padding: 25px 5px 20px;
                  }
                  @media screen and (max-width: 880px) {
                    padding: 30px 5px 20px;
                  }
                  @media screen and (max-width: $bp-tablet) {
                    padding: 40px 5px 20px;
                  }
                  @media screen and (max-width: $bp-small) {
                    padding: 0;
                    margin-left: 5px;
                    h3 {
                      text-align: left;
                    }
                  }
                }
                .field--name-field-cards-body {
                  line-height: 27px;
                  text-align: center;
                  @media screen and (max-width: $bp-small) {
                    display: none;
                  }
                }
              }
            }
          }
        }
      }
    }

    // **Accessibility Certifications Section
    .field-collection-item-field-accessibility-certs {
      background-color: $bg;
      // padding-bottom: 10%;
      padding-bottom: 250px;
      @media screen and (max-width: 1220px) {
        padding-bottom: 170px;
      }
      @media screen and (max-width: $bp-medium) {
        padding-bottom: 150px;
      }
      @media screen and (max-width: $bp-small) {
        padding-bottom: 80px;
      }
      .group-certs-container {
        max-width: $main-container;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        @media screen and (max-width: $bp-large-screen) {
          max-width: $main-container-responsive;
          padding: $pd-res;
          margin: 0 auto;
        }
        @media screen and (max-width: $bp-medium) {
          flex-direction: column;
          align-items: center;
        }
        .group-left-div {
          position: relative;
          left: calc((-100vw + 1640px) / 2);
          margin-right: 4vw;
          margin-left: -5.3%;
          width: 53vw;
          @media screen and (max-width: $bp-large-screen) {
            left: 0;
            margin-left: -8.5%;
            width: 58vw;
          }
          // @media screen and (max-width: $bp-13) {
          //   left: 0;
          //   margin-left: -12%;
          //   width: 55vw;
          // }
          @media screen and (max-width: $bp-medium) {
            // margin-bottom: 20px;
            margin-left: -26%;
            width: 95vw;
          }
          @media screen and (max-width: $bp-tablet) {
            margin-left: -30.5%;
            width: 100vw;
          }
          @media screen and (max-width: $bp-small) {
            margin-left: -31.5%;
            width: 100vw;
          }
          @media screen and (max-width: $bp-mobile) {
            // margin-bottom: 12px;
            margin-left: -33%;
            width: 100vw;
          }
          // @media screen and (max-width: $bp-large-screen) {
          // margin-left: -10%;
          // }
          //  @media screen and (max-width: 1680px){
          //    margin-left: -12%;
          //  }
          .field--name-field-image {
            margin-top: -26%;
            position: relative;
            &:before {
              content: "";
              position: absolute;
              background: url(../images/sprites/arrow_01.svg) no-repeat center;
              width: 80px;
              height: 94px;
              right: 64%;
              // bottom: -7.2%;
              bottom: -66px;
              @media screen and (max-width: $bp-medium) {
                display: none;
              }
            }
          }
          .field--name-field-caption {
            width: 260px;
            position: absolute;
            margin-top: 63px;
            margin-left: 35%;
            @media screen and (max-width: $bp-medium) {
              display: none;
            }
            .field__item {
              p {
                // @include font_size(16);
                font-size: 16px;
              }
            }
          }
        }
        .group-right-div {
          width: 43vw;
          margin-top: 9.5%;
          margin-left: -10%;
          @media screen and (max-width: $bp-medium) {
            margin: 0 auto;
            width: 100%;
          }
          .field--name-field-main-title {
            padding-bottom: 30px;
            @media screen and (max-width: $bp-medium) {
              padding-top: 20px;
              width: 100%;
              padding-bottom: 20px;
            }
          }
          .field--name-field-body {
            p {
              // @include font_size(24);
              font-size: 24px;
              line-height: 36px;
              max-width: 85%;
              padding-bottom: 60px;
              @media screen and (max-width: $bp-13) {
                max-width: 100%;
                font-size: 20px;
              }
              @media screen and (max-width: $bp-medium) {
                max-width: 95%;
                font-size: 18px;
              }
              @media screen and (max-width: $bp-small) {
                max-width: 100%;
                font-size: 16px;
                line-height: 28px;
              }
            }
          }

          .field--name-field-cards-group-title {
            padding-bottom: 20px;
            @media screen and (max-width: $bp-mobile) {
              padding-bottom: 10px;
            }
          }
          .field--name-field-collection-cards {
            > .field__items {
              display: flex;
              flex-wrap: wrap;
              justify-content: flex-start;

              @media screen and (max-width: $bp-tablet) {
                flex-wrap: wrap;
                justify-content: flex-start;
              }
              @media screen and (max-width: $bp-small) {
                // flex-direction: column;
                // flex-wrap: nowrap;
                // align-items: center;
                // justify-content: center;
              }
              > .field__item {
                width: 49%;
                // width: 32%;
                margin-right: 2%;
                margin-bottom: 1.5%;
                @media screen and (max-width: $bp-tablet) {
                  // margin-bottom: 10px;
                  width: 49%;
                  margin-right: 2%;
                }
                @media screen and (max-width: $bp-small) {
                  width: 100%;
                  margin-bottom: 10px;
                  margin-right: 0;
                }

                .content {
                  display: flex;
                  align-items: center;
                  padding: 16px 15px;
                  background: white;
                  border-radius: 12px;
                  height: 102px;
                  @media screen and (max-width: 1792px) {
                    padding: 18px 15px;
                    height: 108px;
                  }
                  @media screen and (max-width: 1640px) {
                    height: 130px;
                  }
                  @media screen and (max-width: 1250px) {
                    padding: 10px;
                    height: 165px;
                  }
                  @media screen and (max-width: $bp-medium) {
                    padding: 16px 15px;
                    height: 135px;
                  }
                  @media screen and (max-width: $bp-small) {
                    padding: 16px 15px;
                    height: auto;
                  }
                }

                &:nth-child(2n) {
                  margin-right: 0;
                }
              }

              .group-icon-title {
                min-width: 63px;
              }
              .field--name-field-icon {
                margin-right: 15px;
                flex-shrink: 0;
                @include breakpoint(1400px) {
                  margin-right: 7px;
                }
              }
              .group-text-group {
                width: 80%;
                .field--name-field-main-title {
                  padding-bottom: 0;
                  padding-top: 0;
                  h3 {
                    @include breakpoint(1600px) {
                      font-size: 14px;
                    }
                  }
                }
                .field--name-field-cards-body {
                  // @include font_size(18);
                  font-size: 18px;
                  padding-bottom: 0;
                  line-height: 24px;
                }
              }
            }
          }
        }
      }
    }
    // **Our Team Section
    .field-collection-item-field-our-team {
      background-color: $bg;
      padding-bottom: 160px;
      padding-bottom: 30vw; // remove this when testimonials block appears again.
      padding-bottom: 390px;
      position: relative;
      z-index: 1;

      @include breakpoint(1500px) {
        padding-bottom: 350px;
      }

      @include breakpoint(1080px) {
        padding-bottom: 60vw;
        padding-bottom: 540px;
      }
      @include breakpoint(768px) {
        padding-bottom: 380px;
      }
      @include breakpoint(680px) {
        padding-bottom: 330px;
      }
      @include breakpoint(600px) {
        padding-bottom: 310px;
      }
      @include breakpoint(450px) {
        padding-bottom: 60vw;
      }
      // @media screen and (max-width: $bp-small) {
      //   padding-bottom: 80px;
      // }
      .group-our-team {
        margin-bottom: 100px;
        margin-top: -100px;
      }
      .group-team-container {
        max-width: $main-container;
        margin: 0 auto;
        background: #ffffff;
        border-radius: 16px 0 16px 0;
        position: relative;
        @media screen and (max-width: $bp-large-screen) {
          max-width: $main-container-responsive;
          margin: 0 20px;
        }
        &::before {
          content: "";
          position: absolute;
          width: 124px;
          height: 124px;
          border: 2px solid #ffc31e;
          border-radius: 50%;
          top: -59px;
          left: 96%;
          z-index: -1;
          @media screen and (max-width: $bp-large-screen) {
            width: 100px;
            height: 100px;
            left: 96.5%;
            top: -54px;
          }
          @media screen and (max-width: 1780px) {
            display: none;
          }
        }

        br {
          @include breakpoint(420px) {
            display: none;
          }
        }

        .field--name-field-main-title {
          width: 70%;
          padding: 5% 0 5% 9.1%;
          @media screen and (max-width: 936px) {
            width: 90%;
            padding: 8% 0 5% 9.1%;
          }
          @media screen and (max-width: $bp-mobile) {
            width: 252px;
            margin-right: 0;
            padding-bottom: 42px;
            padding-top: 40px;
          }
        }
        .field--name-field-team-members {
          > .field__items {
            display: flex;
            // justify-content: center;
            flex-wrap: wrap;
            padding-bottom: 5%;
            @media screen and (max-width: $bp-medium) {
              flex-wrap: wrap;
            }
            > .field__item {
              // width: 49%;
              width: span(4 of 12);
              padding: 30px 20px;
              // border-right: 1px solid rgba(0, 0, 0, 0.11);
              position: relative;
              @media screen and (max-width: $bp-medium) {
                margin-bottom: 15px;
                width: 50%;
                border: 0;
                // &:nth-child(2n) {
                //   border-right: none;
                // }
              }
              @include breakpoint(400px) {
               
                width: 50%;
                border: 0;
                margin-bottom: 5px;
                padding: 20px 20px;
              }

              &:last-child {
                border-right: none;
              }
              .field--name-field-linkedin {
                > .field__items {
                  > .field__item {
                    position: relative;
                    z-index: 1;
                    a {
                      display: block;
                      width: 42px;
                      height: 42px;
                      text-indent: -9999px;
                      color: transparent;
                      background: url(../images/sprites/linkedin-icon.png)
                        no-repeat;
                      background-position: center;
                      border-radius: 50%;
                      position: absolute;
                      border-radius: 50%;
                      margin-left: 60%;
                      margin-top: 3%;
                      @media screen and (max-width: $bp-cards-large) {
                        margin-left: 62%;
                      }
                      @media screen and (max-width: $bp-13) {
                        margin-left: 63%;
                      }
                      @media screen and (max-width: 1250px) {
                        margin-left: 64%;
                      }
                      @media screen and (max-width: 1150px) {
                        margin-left: 65%;
                      }
                      @media screen and (max-width: 1090px) {
                        margin-left: 67%;
                      }
                      @media screen and (max-width: $bp-medium) {
                        margin-left: 60.5%;
                      }
                      @media screen and (max-width: 880px) {
                        margin-left: 62%;
                      }
                      @media screen and (max-width: $bp-tablet) {
                        margin-left: 64.5%;
                      }
                      // @media screen and (max-width: $bp-small) {
                      //   right: 40px;
                      //   top: 10px;
                      // }
                      &:after {
                        content: "";
                        width: 42px;
                        height: 42px;
                        position: absolute;
                        background: $light-blue;
                        // left: -0.5px;
                        // top: 1.5px;
                        border-radius: 50%;
                        z-index: -1;
                        margin: 0 auto;
                      }
                    }
                  }
                }
              }
              .field--name-field-image {
                padding-bottom: 4%;
                img {
                  border-radius: 100%;
                  background: linear-gradient(226deg, $light-blue, $green);
                  padding: 2px;
                  margin: 0 auto;
                  @media screen and (max-width: $bp-mobile) {
                    width: 152px;
                  }
                }
              }

              .field--name-field-member-name {
                text-align: center;
                padding: 1% 0;
                word-break: break-word;
                h3 {
                  // @include font_size(20);
                  font-size: 20px;
                  @media screen and (max-width: $bp-mobile) {
                    font-size: 14px;
                  }
                }
              }

              .field--name-field-body {
                text-align: center;
                p {
                  @media screen and (max-width: $bp-mobile) {
                    // font-size: 10px;
                    font-size: 12px;
                    line-height: 16px;
                  }
                }
              }
            }
          }

          .group-image-group {
            width: 160px;
            margin: 0 auto;
            position: relative;
            padding-bottom: 10px;

            @include breakpoint(400px) {
              width: 110px;
            }

            .field--name-field-linkedin {
              position: absolute;
              right: 40px;
              top: 0;
              a {
                overflow: hidden;
                &:after {
                  left: 0;
                  top: 0;
                  z-index: -1;
                }
                
              }
            }
            .field--name-field-secondary-title {
              background: white;
              border: 1px solid #B2C7D9;
              border-radius: 20px;
              text-align: center;
              font-size: 12px;
              padding: 5px 0;
              font-weight: bold;
              color: #0C4F8E;
              width: 100px;
              position: absolute;
              bottom: 10px;
              left: 50%;
              transform: translateX(-50%);
              background: white;
              @include breakpoint(400px) {
                font-size: 10px;
              }
            }
          }

        }
      }
    }
    // **Testimonials Section
    .field-collection-item-field-testimonials {
      background-color: $bg;
      padding-bottom: 420px;
      @media screen and (max-width: $bp-medium) {
        padding-bottom: 562px;
      }
      @media screen and (max-width: $bp-tablet) {
        padding-bottom: 440px;
      }
      @media screen and (max-width: $bp-small) {
        padding-bottom: 390px;
      }
      @media screen and (max-width: $bp-mobile) {
        // padding-bottom: 342px;
        padding-bottom: 380px;
      }
      .group-testimonials-container {
        max-width: $main-container;
        margin: 0 auto;
        position: relative;
        @media screen and (max-width: $bp-large-screen) {
          max-width: $main-container-responsive;
          padding: $pd-res;
        }
        .field--name-field-main-title {
          padding-bottom: 40px;
          width: 700px;
          position: relative;
          @media screen and (max-width: $bp-small-medium) {
            width: 65%;
            padding-left: 10px;
          }
          @media screen and (max-width: $bp-mobile) {
            width: 216px;
            padding-left: 10px;
          }
          @media screen and (max-width: $bp-mobile-sm) {
            width: 166px;
            padding-left: 10px;
          }
        }
      }
      .slider-nav-arrows {
        position: absolute;
        top: 20px;
        right: 0;
        display: flex;
        align-items: center;
        overflow: hidden;
        // right: 78px;
        margin: 0 20px;
        @media screen and (max-width: $bp-small-medium) {
          right: 10px;
        }
        @media screen and (max-width: 809px) {
          top: 12px;
        }
        @media screen and (max-width: $bp-small) {
          top: 30px;
        }
        @media screen and (max-width: 560px) {
          top: 32px;
        }

        @media screen and (max-width: 443px) {
          top: 23px;
        }
        @media screen and (max-width: $bp-mobile) {
          // right: 5px;
          top: 28px;
          margin: 0 10px;
        }
        .slider-nav-circle {
          width: 16px;
          height: 16px;
          background: #ffc31e;
          border-radius: 50%;
          display: block;
          margin: 0 20px;
          @media screen and (max-width: $bp-mobile) {
            width: 11px;
            height: 11px;
            margin: 0 15px;
          }
        }
        .slick-prev {
          display: block;
          width: 40px;
          height: 20px;
          top: -20%;
          right: 12%;
          background-color: transparent;
          // background: url(../images/sprites/prev-arrow.png);
          @include sprite($sprite-prev-arrow);
          color: transparent;
          text-indent: -9999px;
          border: 0;
          cursor: pointer;
          // &:focus {
          //   outline: none !important;
          // }
          &:disabled {
            opacity: 0.2;
            cursor: default;
          }
          @media screen and (max-width: $bp-mobile) {
            // background: url(../images/sprites/prev-arrow-small.png) no-repeat;
            @include sprite($sprite-prev-arrow-small);
            // width: 28px;
            // height: 14px;
          }
        }
        .slick-next {
          display: block;
          width: 40px;
          height: 20px;
          top: -20%;
          right: 6%;
          background-color: transparent;
          // background: url(../images/sprites/next-arrow.png);
          @include sprite($sprite-next-arrow);
          color: transparent;
          text-indent: -9999px;
          border: 0;
          cursor: pointer;
          // &:focus {
          //   outline: none !important;
          // }
          &:disabled {
            opacity: 0.2;
            cursor: default;
          }
          @media screen and (max-width: $bp-mobile) {
            // background: url(../images/sprites/next-arrow-small.png) no-repeat;
            @include sprite($sprite-next-arrow-small);
            // width: 28px;
            // height: 14px;
          }
        }
      }
      .slick-slide {
        padding: 0 8px;
      }
      .slick-track {
        height: 380px;
        @media screen and (max-width: $bp-mobile-sm) {
          height: 415px;
        }
      }

      .group-testimonial-cards-items {
        border: 1px solid $gray-border;
        border-radius: 12px;
        padding: 4.5% 5.6% 0 11%;
        height: 268px;
        position: relative; //remove if u keep the other way of making the slides
        @media screen and (max-width: 522px) {
          height: 325px;
          padding: 7% 5.6% 0 11%;
        }
        @media screen and (max-width: 480px) {
          height: 350px;
        }
        @media screen and (max-width: $bp-mobile) {
          height: 345px;
        }
        @media screen and (max-width: $bp-mobile-sm) {
          height: 385px;
        }
        .field--name-field-body {
          position: relative;
          padding-left: 5px;
          @media screen and (max-width: $bp-small) {
            padding-left: 5px;
            font-size: 14px;
            line-height: 24px;
          }

          &:before {
            content: "";
            position: absolute;
            // background: url(../images/sprites/quote.png) no-repeat center;
            // width: 25px;
            // height: 20px;
            @include sprite($sprite-quote);
            // left: 5.5%;
            left: -7.5%;
            top: 11%;
            @media screen and (max-width: $bp-small) {
              background: url(../images/sprites/quote-small.png) no-repeat
                center;
              width: 17px;
              height: 13px;
              top: 12px;
            }
          }
        }
      }

      .group-bottom-container {
        // display: flex;
        // align-items: center;
        // position: absolute;
        // padding: 0;
        // background-color: $bg;
        // z-index: 4;
        // bottom: 70px;
        display: flex;
        align-items: center;
        position: absolute;
        padding: 0 46px;
        margin-left: 31px;
        background-color: #e7eded;
        z-index: 4;
        bottom: -40px;
        width: 80%;
        left: -70px;
        right: 0px;
        margin: 0 auto;
        @media screen and (max-width: 550px) {
          bottom: -30px;
          padding: 0 12px;
          left: 0;
        }
        // @media screen and (max-width: 522px) {
        //   // bottom: 21px;
        //   bottom: -30px;
        //   padding: 0 20px;
        //   margin-left: 31px;
        // }
        @media screen and (max-width: $bp-mobile) {
          width: 88%;
          bottom: -27px;
          padding: 0 12px;
          left: 8px;
        }
        @media screen and (max-width: $bp-mobile-sm) {
          width: 90%;
        }
        .field--name-field-image {
          flex-shrink: 0; //remove if u keep the other way
          .field__items {
            .field__item {
              @include gradient-2;
              border-radius: 100%;
              padding: 1px;
              @media screen and (max-width: $bp-mobile) {
                padding: 2px;
              }
              img {
                border-radius: 100%;
                @media screen and (max-width: $bp-small) {
                  width: 65px;
                  height: 65px;
                }
                @media screen and (max-width: $bp-mobile) {
                  width: 50px;
                  height: 50px;
                }
              }
            }
          }
        }
        .group-test-text-group {
          flex-direction: column;
          padding-left: 20px;
          max-width: 355px;
          @media screen and (max-width: $bp-mobile) {
            padding-left: 12px;
            max-width: 195px;
          }

          .field--name-field-main-title {
            padding: 0;
            @media screen and (max-width: $bp-small) {
              width: 100%;
              h3 {
                font-size: 14px;
              }
            }
          }
          .field--name-field-secondary-body {
            // @include font_size(18);
            font-size: 18px;
            padding-bottom: 0;
            line-height: 26px;

            @media screen and (max-width: $bp-small) {
              width: 95%;
              p {
                font-size: 14px;
                line-height: 18px;
              }
            }
            @media screen and (max-width: $bp-mobile) {
              width: 88%;
              p {
                font-size: 12px;
              }
            }
          }
        }
        // &::after {
        //   content: "";
        //   width: 450px;
        //   height: 84px;
        //   position: absolute;
        //   background: $bg;
        //   top: 0px;
        //   left: -9%;
        //   z-index: -1;
        //   @media screen and (max-width: $bp-small) {
        //     width: 350px;
        //   }

        //   @media screen and (max-width: $bp-mobile) {
        //     width: 134px;
        //   }
        // }
      }
    }

    // **Webform Section
    .group-webform-container {
      max-width: $main-container;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      border-radius: 12px;
      // transform: translateY(-27%);
      // background-color: #fff;
      background: none;
      padding-top: 4%;
      position: relative;
      z-index: 1;
      top: -18vw;
      height: 800px;
      @include breakpoint(min-width 1920px) {
        top: -340px;
      }
      @include breakpoint(min-width 2560px) {
        top: -360px;
      }
      @include breakpoint(min-width 3000px) {
        top: -380px;
      }
      @include breakpoint(min-width 3600px) {
        top: -410px;
      }

      @include breakpoint(1640px) {
        top: -15vw;
        height: 650px;
      }
      @include breakpoint(1500px) {
        height: 570px;
      }
      @include breakpoint(1420px) {
        height: 620px;
      }
      @include breakpoint(1080px) {
        height: auto;
      }

      @media screen and (max-width: $bp-large-screen) {
        max-width: $main-container-responsive;
        padding: $pd-res;
        margin: 0 auto;
      }
      @media screen and (max-width: $bp-medium) {
        border-radius: 0;
        max-width: 100%;
        flex-direction: column-reverse;
        margin-bottom: -110px;
      }
      @media screen and (max-width: $bp-small) {
        margin-bottom: -75px;
      }
      @media screen and (max-width: $bp-mobile) {
        margin-bottom: -50px;
      }
      @include breakpoint(1400px) {
        top: -17vw;
      }
      .group-title-form {
        margin-left: 8%;
        // margin-top: -120px;
        @media screen and (max-width: $bp-medium) {
          max-width: $main-container-responsive;
          padding: $pd-res;
          margin-left: 0;
        }
        .field--name-field-form-title {
          
          // width: 80%;
          width: 674px;
          padding-bottom: 6%;
          padding-top: 5%;
          padding-bottom: 100px;
          @media screen and (max-width: $bp-cards-large) {
            width: 80%;
          }
          @media screen and (max-width: $bp-medium) {
            width: 100%;
          }

          @media screen and (max-width: 720px) {
            width: 95%;
          }
          @media screen and (max-width: $bp-medium) {
            padding-top: 40px;
          }

          @media screen and (max-width: $bp-mobile) {
            width: 100%;
            margin: 0;
            padding-bottom: 6%;
            h2 {
              font-size: 22px;
              line-height: 30px;
            }
          }
          @media screen and (max-width: 360px) {
            width: 85%;
          }

          .circle-arrow {
            position: relative;
            &::after {
              content: "";
              position: absolute;
              height: 55px;
              width: 55px;
              background: url(../images/sprites/arrow_03.svg) no-repeat;
              right: -46%;
              top: 38%;
              z-index: 10;
              @media screen and (max-width: $bp-13) {
                width: 50px;
                height: 50px;
                right: -50%;
                top: 34%;
              }
              @media screen and (max-width: $bp-medium) {
                height: 45px;
                width: 45px;
                right: -53%;
              }
              @media screen and (max-width: $bp-small) {
                height: 40px;
                width: 40px;
                right: -52%;
              }
              @media screen and (max-width: $bp-mobile) {
                height: 33px;
                width: 33px;
                right: -56%;
              }
            }
          }
        }
        .webform-client-form {
          // width: 65%;
          width: 535px;
          @media screen and (max-width: $bp-cards-large) {
            width: 80%;
          }
          @media screen and (max-width: $bp-medium) {
            margin: 0 auto;
            width: 70%;
          }
          @media screen and (max-width: $bp-tablet) {
            width: 90%;
          }

          @media screen and (max-width: $bp-small) {
            width: 95%;
          }
          @media screen and (max-width: $bp-mobile) {
            // width: 93.6%;
            width: 100%;
          }
          .form_terms_wrapper {
            display: flex;
            align-items: center;
            flex-direction: row-reverse;
            justify-content: flex-end;

            // display: inline-flex;
            // align-items: center;
            // flex-direction: row-reverse;
            > label {
              width: 480px;
              padding-left: 3%;
              font-weight: normal !important;
              padding-top: 3% !important;
              > a {
                color: $primary-blue;
              }
              @media screen and (max-width: $bp-large-screen) {
                padding-left: 2%;
              }
              @media screen and (max-width: $bp-mobile) {
                // width: 180px;
              }
            }
          }
          .form-item {
            label {
              font-family: $mont;
              text-transform: none;
              font-weight: bold;
              font-size: 14px;
              padding-bottom: 10px;
              @media screen and (max-width: $bp-small) {
                padding-bottom: 7px;
              }
              @media screen and (max-width: $bp-mobile) {
                margin-top: 10px;
                font-size: 12px;
              }
            }
            input {
              // &:focus {
              //   outline: none !important;
              //   border: 2px solid $primary-blue;
              // }
            }
            .form-text,
            .form-textarea {
              border: 1px solid $gray-form;
              border-radius: 12px;
              background-color: #ffffff;
              height: 60px;
              @media screen and (max-width: $bp-small) {
                height: 48px;
              }
            }
            .form-textarea {
              min-height: 90px;
              // &:focus {
              //   outline: none !important;
              //   border: 2px solid $primary-blue;
              // }
              @media screen and (max-width: $bp-small) {
                min-height: 96px;
              }
            }
            // .form-required {
            //   visibility: hidden;
            // }

            .form-type-checkbox {
              display: inline-block;
              input[type="checkbox"] {
                height: 31px;
                width: 31px;
                border-radius: 8px;
                border: 1px solid $primary-blue;
                margin: 0;
                cursor: pointer;
                opacity: 1;
                background-color: transparent;
                -webkit-appearance: none;
                -moz-appearance: none;
                -o-appearance: none;
                &:checked {
                  background: url(../images/sprites/check-icon.png) no-repeat
                    center;
                }
                // &:focus {
                //   outline: none !important;
                //   border: 2px solid $primary-blue;
                // }
              }
            }
          }
          .form-actions {
            position: relative;

            // &:hover {
            //   .hidden {
            //     display: inline-block;
            //     // position: absolute;
            //     // width: 120px;
            //     background-color: #555;
            //     color: #fff;
            //     text-align: center;
            //     border-radius: 6px;
            //     padding: 5px 10px;
            //     position: absolute;
            //     z-index: 1;
            //     // bottom: 125%;
            //     // left: 50%;
            //     // margin-left: -60px;
            //     // opacity: 0;
            //     // transition: opacity 0.3s;
            //     font-size: 12px;
            //   }
            // }

            > input {
              height: 60px;
              background-color: $primary-blue;
              color: #ffffff;
              border-radius: 8px;
              // @include font_size(16);
              font-size: 16px;
              @media screen and (max-width: $bp-small) {
                height: 48px;
                font-size: 12px;
              }
            }
          }
        }
      }
      .field--name-field-webform-image {
        position: relative;
        right: calc((-100vw + 1640px) / 2);
        width: 54vw;
        margin-right: -4vw;
        margin-left: 0;
        // margin-top: -11%;
        @media screen and (max-width: 1640px) {
          right: 0;
        }
        @media screen and (max-width: $bp-medium) {
          position: static;
          // max-width: $main-container-responsive;
          // padding: $pd-res;
          width: 95vw;
          margin-left: 26.5%;
          margin-top: -230px;
        }
        @media screen and (max-width: $bp-tablet) {
          width: 100vw;
          margin-left: 19.5%;
          margin-top: -132px;
        }
        @media screen and (max-width: $bp-small) {
          width: 100vw;
          margin-left: 20.5%;
          margin-top: -115px;
        }
        @media screen and (max-width: $bp-mobile) {
          // margin-left: 20.5%;
          margin-left: 13.5%;
          width: 100vw;
          margin-top: -75px;
          margin-bottom: 5px;
        }
      }
    }
    // **Footer

    .field--name-field-footer {
      max-width: $main-container;
      margin: 0 auto;
      margin-top: -6%;
      @media screen and (max-width: $bp-large-screen) {
        max-width: $main-container-responsive;
        padding: $pd-res;
        margin: 0 auto;
      }
      @include breakpoint(850px) {
        margin-top: 50px;
      }
      .field-collection-item-field-footer {
        > .content {
          display: flex;
          justify-content: space-between;
          text-decoration: none;
          height: 122px;
          // @include font_size(14);
          font-size: 14px;
          @media screen and (max-width: $bp-medium) {
            // margin-top: -126px;
          }
          @media screen and (max-width: $bp-big-tablet) {
            flex-direction: column;
            // margin-top: -130px;
          }

          .group-footer-left {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 138px;
            @media screen and (max-width: $bp-large-screen) {
              margin-left: 0;
            }
            @media screen and (max-width: $bp-big-tablet) {
              flex-direction: column;
              margin: 0 auto;
            }
            .field--name-field-copyright {
              margin-right: 20px;
              font-weight: bold;
              @media screen and (max-width: $bp-big-tablet) {
                margin: 0 0 20px 0;
              }
              .field__items {
                .field__item {
                  @media screen and (max-width: $bp-medium) {
                    font-size: 14px;
                  }
                }
              }
            }
            .field--name-field-footer-links {
              .field__items {
                display: flex;
                @media screen and (max-width: $bp-big-tablet) {
                  flex-direction: column;
                }
                > .field__item {
                  margin-right: 20px;
                  font-weight: normal;
                  &:last-child {
                    margin-right: 0;
                  }
                  @media screen and (max-width: $bp-big-tablet) {
                    margin: 0 0 20px 0;
                    text-align: center;
                  }

                  > a {
                    text-decoration: none;
                    color: #000000;
                    &:hover,
                    &:visited,
                    &:link,
                    &:active {
                      text-decoration: none;
                    }
                    @media screen and (max-width: $bp-medium) {
                      font-size: 14px;
                    }
                  }
                }
              }
            }
          }
          .group-footer-right {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-right: 138px;
            @media screen and (max-width: $bp-large-screen) {
              margin-right: 0;
            }
            @media screen and (max-width: $bp-big-tablet) {
              margin: 30px auto;
              padding-bottom: 21px;
            }
            .field--name-field-footer-text {
              margin-right: 8px;
              font-weight: bold;
              .field__item {
                @media screen and (max-width: $bp-medium) {
                  font-size: 14px;
                }
                @media screen and (max-width: $bp-mobile) {
                  font-size: 10px;
                }
              }
            }
          }
        }
      }
    }
  }
}

