//Colors//
//$grey_c: #000000;
$hover: #000000;

//Font Sizes//
$tiny: 14px;
$small: 16px;


#sliding-popup {
  .popup-content {
    display: block;
    @include clearfix;
    padding: 20px 0 16px;
    max-width: 87%;

    #popup-text {
      margin: 0;
      max-width: 57%;
      margin-right: 3%;
      font-family: $libre;

      h2 {
        @include breakpoint(400px) {
          font-size: 14px;
        }
      }
      p {
        font-size: $tiny;
        font-weight: 400;
        margin-bottom: 0;
        font-family: $mont;
        @include breakpoint(400px) {
          font-size: 12px;
        }
      }


    }

    #popup-buttons {
      margin: 7px 0 0;
      button{
        border-radius: 0;
        border: none;
        background: $white;
        color: $hover !important;
        padding: 10px 30px;
        display: inline-block;
        font-family: $mont;
        font-size: $tiny;
        font-weight: 400;
        margin-top: 0;
        box-shadow: none;
        text-shadow: none;
        transition: all 0.7s;

        &:hover {
          background: $hover;
          color: $white !important;
        }
      }

      .agree-button {
        margin-right: 10px;
      }
    }
  }
}

.eu-cookie-compliance-more-button {
  font-size: $small;
}


//////////////////////
//                  //
//    RESPONSIVE    //
//                  //
//////////////////////

@include breakpoint(800px) {

  #sliding-popup {
    .popup-content {
      padding: 13px 0;

      #popup-buttons {
        float: none;
        max-width: 100%;
        margin-top: 15px;

        button {
          padding: 7px 20px;
        }
      }

      #popup-text {
        float: none;
        max-width: 100%;
      }
    }
  }

}